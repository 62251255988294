import React from 'react'
import config from '~/js/config'
import { getMarkSignHost } from '../../../../utils/host'

export default class MobileNav extends React.Component {
    render() {
        return (
            <div id="nav-mobile">
                <ul>
                    <li><a className="nav__link" href="#">
                        Solutions
                        <svg className="dropdown-arrow" width="10" height="6" viewBox="0 0 10 6" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path strokeWidth="2px" d="M1 1L5 5L9 1" stroke="white" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                    </a>
                        <div className="dropdown">
                            <a className="dropdown__item" href="/aml-screening">
                                <h6>AML Screening</h6>
                            </a>
                            <a className="dropdown__item" href="/electronic-signature">
                                <h6>Electronic Signatures</h6>
                            </a>
                            <a className="dropdown__item" href="/identity-verification">
                                <h6>Identity Verification</h6>
                            </a>
                            <a className="dropdown__item" href="/document-verification">
                                <h6>Electronic Signatures</h6>
                            </a>
                        </div>
                    </li>
                    <li><a className="nav__link" href="#">
                        Use Cases
                        <svg className="dropdown-arrow" width="10" height="6" viewBox="0 0 10 6" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path strokeWidth="2px" d="M1 1L5 5L9 1" stroke="white" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                    </a>
                        <div className="dropdown">
                            <a className="dropdown__item" href="/fintech">
                                <h6>Fintech</h6>
                            </a>
                            <a className="dropdown__item" href="/gaming">
                                <h6>Gaming</h6>
                            </a>
                            <a className="dropdown__item" href="/trading">
                                <h6>Trading</h6>
                            </a>
                            <a className="dropdown__item" href="/money-institutions">
                                <h6>Money Institutions</h6>
                            </a>
                            <a className="dropdown__item" href="/service-providers">
                                <h6>Payment Service Providers</h6>
                            </a>
                        </div>
                    </li>
                    {/*<li><a className="nav__link" href="#">*/}
                    {/*    Pricing*/}
                    {/*    <svg className="dropdown-arrow" width="10" height="6" viewBox="0 0 10 6" fill="none"*/}
                    {/*         xmlns="http://www.w3.org/2000/svg">*/}
                    {/*        <path strokeWidth="2px" d="M1 1L5 5L9 1" stroke="white" strokeLinecap="round"*/}
                    {/*              strokeLinejoin="round"/>*/}
                    {/*    </svg>*/}
                    {/*</a>*/}
                    {/*    <div className="dropdown">*/}
                    {/*        <a className="dropdown__item" href="/pricing">*/}
                    {/*            <h6>Pricing</h6>*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    <li>
                        <a className="nav__link" href="#">Development
                            <svg className="dropdown-arrow" width="10" height="6" viewBox="0 0 10 6" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path strokeWidth="2px" d="M1 1L5 5L9 1" stroke="white" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </a>
                        <div className="dropdown">
                            <a className="dropdown__item" href="/support">
                                <h6>Support</h6>
                            </a>
                            <a className="dropdown__item" target="_blank" href={config.get('MARKID_DOCUMENTATION_URL')}>
                                <h6>Documentation</h6>
                            </a>
                        </div>
                    </li>
                    <li><a className="nav__link" href="#">
                        Contacts
                        <svg className="dropdown-arrow" width="10" height="6" viewBox="0 0 10 6" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path strokeWidth="2px" d="M1 1L5 5L9 1" stroke="white" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                    </a>
                        <div className="dropdown">
                            <a className="dropdown__item" href="/contacts">
                                <h6>Contact Us</h6>
                            </a>
                        </div>
                    </li>
                </ul>
                <div className="ta-center"><a className="btn btn--primary btn--primary--bigger" href={`https://${getMarkSignHost()}`}>eSignature</a>
                </div>
            </div>
        )
    }
}
