import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationLT from '~/public/locales/lt/translation.json';
import translationEN from '~/public/locales/en/translation.json';
import { isDev } from '~/js/config';
import Cookies from 'js-cookie'
// translations resources
const resources = {
  lt: {
    translation: translationLT
  },
  en: {
    translation: translationEN
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    react: {
      useSuspense: false
    },
    lng: Cookies.get('lng') ? Cookies.get('lng') : 'lt',
    debug: isDev(),
    keySeparator: '.',

    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;