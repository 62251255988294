// @flow

import config from '../config'

export const getMarkSignHost = (): string => {
  const currentUrl = window.location.origin
  const tld = currentUrl.split('.').pop()
  const url = `${config.get('MARKSIGN_HOST')}`

  if (tld === 'eu') {
    return url.substring(0, url.lastIndexOf('.')) + '.eu'
  } else if (tld === 'pl') {
    return url.substring(0, url.lastIndexOf('.')) + '.pl'
  }

  return url
}
