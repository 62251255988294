import React from 'react'
import { useCookieConsents } from '@enzsft/react-cookie-consents'

export const CookieBanner = () => {
    const cookieConsents = useCookieConsents();

    if (cookieConsents.get().length > 0) {
        return null;
    }

    return (
        <div className="active js-opacity js-accept-cookies" id="cookie-notice">
            <h5>This website uses cookies to ensure you get the best experience on our website. <br />
                Complete list of cookies along with our cookie policy can be found <a href="/privacy">here</a>
            </h5>
            <div className="cookie-notice__actions">
                <button className="btn btn--primary js-accept-cookies" onClick={() => cookieConsents.add("cookie-notice")}>
                    Accept cookies
                </button>
            </div>
        </div>
    )
}