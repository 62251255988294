import React, { Component } from 'react'
import GraphicElementGreen from '~/img/hero/graphic-element-green.svg'
import ComputerMobile from '~/img/illustration/computer-mobile.svg'
import Companies from '~/js/components/Web/LandingComponents/Companies'
import { Link } from 'react-scroll'

export default class Pricing extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <main>
                <section className="intro--color intro--color--white" id="intro">
                    <div className="intro__el">
                        <img src={ GraphicElementGreen } alt="" />
                    </div>
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col lg-8-12 md-10-12 text__center">
                                <div className="intro__content ">
                                    <h2>eSignatures pricing</h2>
                                    <img src={ ComputerMobile } alt="Computer and Mobile" />
                                </div>
                                <Link className="btn btn--primary" to="pricing" smooth={true}>Learn More</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="pricing" >
                    <div className="grid text-block">
                        <div className="pricing-table hide-tablet">
                            <div className="pricing-table__col pricing-table__col--label">
                                <div className="pricing-table__row pricing-table__row--empty"/>
                                <div className="pricing-table__row">
                                    <label>Signatures per month</label>
                                    <label>Documents capacity</label>
                                    <label>Quantity of accounts</label>
                                </div>
                                <div className="pricing-table__row pricing-table__row--colspan">
                                    <label><strong>Functions</strong></label>
                                </div>
                                <div className="pricing-table__row">
                                    <label>Visual stamp of e-signature</label>
                                    <label>Sign reminders</label>
                                    <label>Automated solutions</label>
                                    <label>Address book and contacts directories</label>
                                    <label>Documents control</label>
                                    <label>User roles control</label>
                                    <label>Accounts information control</label>
                                    <label>Bank payments</label>
                                </div>
                            </div>
                            <div className="pricing-table__col">
                                <div className="pricing-table__row pricing-table__row--head">
                                    <p>Monthly plan</p><span className="price"><sup>€</sup>25</span>
                                    <button className="btn btn--primary">Buy</button>
                                </div>
                                <div className="pricing-table__row">
                                    <label>UNLIMITED</label>
                                    <label>UNLIMITED</label>
                                    <label>UNLIMITED</label>
                                </div>
                                <div className="pricing-table__row pricing-table__row--colspan"/>
                                <div className="pricing-table__row">
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                </div>
                            </div>
                            <div className="pricing-table__col">
                                <div className="pricing__best-value">
                                    <div className="best-value__lines"/>
                                    <span>Best value</span>
                                </div>
                                <div className="pricing-table__row pricing-table__row--head">
                                    <p>Quarterly plan</p><span className="price"><sup>€</sup>60</span>
                                    <button className="btn btn--primary">Buy</button>
                                </div>
                                <div className="pricing-table__row">
                                    <label>UNLIMITED</label>
                                    <label>UNLIMITED</label>
                                    <label>UNLIMITED</label>
                                </div>
                                <div className="pricing-table__row pricing-table__row--colspan"/>
                                <div className="pricing-table__row">
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                </div>
                            </div>
                            <div className="pricing-table__col">
                                <div className="pricing-table__row pricing-table__row--head">
                                    <p>Annual plan</p><span className="price"><sup>€</sup>200</span>
                                    <button className="btn btn--primary">Buy</button>
                                </div>
                                <div className="pricing-table__row">
                                    <label>UNLIMITED</label>
                                    <label>UNLIMITED</label>
                                    <label>UNLIMITED</label>
                                </div>
                                <div className="pricing-table__row pricing-table__row--colspan"/>
                                <div className="pricing-table__row">
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                    <i className="icon-single icon-single--checked"/>
                                </div>
                            </div>
                        </div>
                        <div className="pricing__mobile show-tablet">
                            <div className="pricing__mobile__item">
                                <div className="pricing__mobile__heading">
                                    <p>Monthly plan</p><span className="price"><sup>€</sup>25</span>
                                </div>
                                <ul className="pricing__mobile__possibilities">
                                    <li>
                                        <label>Signatures per month</label>
                                        <p>UNLIMITED</p>
                                    </li>
                                    <li>
                                        <label>Documents capacity</label>
                                        <p>UNLIMITED</p>
                                    </li>
                                    <li>
                                        <label>Quantity of accounts</label>
                                        <p>UNLIMITED</p>
                                    </li>
                                </ul>
                                <div className="pricing__mobile--checks"><span>Functions</span>
                                    <ul>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Visual stamp of e-signature</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Sign reminders</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Automated solutions</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Address book and contacts directories</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Documents control</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>User roles control</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Accounts information control</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Bank payments</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="pricing__mobile--control">
                                    <button className="btn btn--primary">Buy</button>
                                </div>
                            </div>
                            <div className="pricing__mobile__item">
                                <div className="pricing__best-value">
                                    <div className="best-value__lines"/>
                                    <span>Best value</span>
                                </div>
                                <div className="pricing__mobile__heading">
                                    <p>Quarterly plan</p><span className="price"><sup>€</sup>60</span>
                                </div>
                                <ul className="pricing__mobile__possibilities">
                                    <li>
                                        <label>Signatures per month</label>
                                        <p>UNLIMITED</p>
                                    </li>
                                    <li>
                                        <label>Documents capacity</label>
                                        <p>UNLIMITED</p>
                                    </li>
                                    <li>
                                        <label>Quantity of accounts</label>
                                        <p>UNLIMITED</p>
                                    </li>
                                </ul>
                                <div className="pricing__mobile--checks"><span>Functions</span>
                                    <ul>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Visual stamp of e-signature</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Sign reminders</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Automated solutions</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Address book and contacts directories</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Documents control</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>User roles control</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Accounts information control</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Bank payments</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="pricing__mobile--control">
                                    <button className="btn btn--primary">Buy</button>
                                </div>
                            </div>
                            <div className="pricing__mobile__item">
                                <div className="pricing__mobile__heading">
                                    <p>Annual plan</p><span className="price"><sup>€</sup>200</span>
                                </div>
                                <ul className="pricing__mobile__possibilities">
                                    <li>
                                        <label>Signatures per month</label>
                                        <p>UNLIMITED</p>
                                    </li>
                                    <li>
                                        <label>Documents capacity</label>
                                        <p>UNLIMITED</p>
                                    </li>
                                    <li>
                                        <label>Quantity of accounts</label>
                                        <p>UNLIMITED</p>
                                    </li>
                                </ul>
                                <div className="pricing__mobile--checks"><span>Functions</span>
                                    <ul>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Visual stamp of e-signature</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Sign reminders</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Automated solutions</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Address book and contacts directories</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Documents control</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>User roles control</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Accounts information control</p>
                                        </li>
                                        <li><i className="icon-single icon-single--checked"/>
                                            <p>Bank payments</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="pricing__mobile--control">
                                    <button className="btn btn--primary">Buy</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Companies />
            </main>
        )
    }
}