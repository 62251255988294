import React, { Component } from 'react'
import ReactDOMServer, {renderToStaticMarkup} from 'react-dom/server'
import Navigation from '~/js/components/Web/LandingComponents/Navigation'
import { handleTopHeaderStyles } from '~/js/utils/IndexFunctions'
import MobileNav from '~/js/components/Web/LandingComponents/MobileNav'
import ContactForm from '../../LandingComponents/ContactForm'
import {
    SalesManagerTexts,
    BusinessManagerTexts,
    HeadOfProductTexts,
    PhpEngineer,
    JuniorSalesRep,
    MarketingProjectManager
} from '~/js/utils/jobDescriptionTexts'

export default class CareerSingle extends Component {
    constructor(props) {
        super(props)

        this.header = React.createRef()
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.handleTopHeaderStyles()
    }

    handleTopHeaderStyles() {
        if (!this.header.current) {
            return
        }

        handleTopHeaderStyles(this.header.current)
    }

    render() {
        let career_texts

        switch (this.props.career) {
            case 'sales-manager':
                career_texts = SalesManagerTexts
                break
            case 'business-manager':
                career_texts = BusinessManagerTexts
                break
            case 'head-of-product':
                career_texts = HeadOfProductTexts
                break
            case 'php-engineer':
                career_texts = PhpEngineer
                break
            case 'junior-sales-rep':
                career_texts = JuniorSalesRep
                break
            case 'marketing-project-manager':
                career_texts = MarketingProjectManager
                break
        }

        const jobDescriptionItems = Object.keys(career_texts.job_description).map(key => {
            return <li key={key}>{career_texts.job_description[key]}</li>
        })

        const jobRequirementsItems = Object.keys(career_texts.job_requirements).map(key => {
            return <li key={key}>{career_texts.job_requirements[key]}</li>
        })

        const shortInfo = Object.keys(career_texts.short_info).map(key => {
            return <span key={key} dangerouslySetInnerHTML={{ __html: career_texts.short_info[key] }} />
        })

        const whatWeOffer = Object.keys(career_texts.what_we_offer).map(key => {
            return <li key={key}>{career_texts.what_we_offer[key]}</li>
        })

        return <div id="page-container">
            <MobileNav />
            <header className="dark" ref={ this.header }>
                <Navigation />
            </header>
            <main>
                <section id="career-single">
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col lg-8-12 md-10-12">
                                <article>
                                    <a className="link-back" href="/career-achieve">
                                        <div className="link-back__arrow"/>
                                        <span>View All Open Positions</span>
                                    </a>
                                    <h1>{ career_texts.title }</h1>
                                    <h2>{ career_texts.salary }</h2>
                                    <p>{ shortInfo }</p>
                                    <h2>What You Will Do</h2>
                                    <ul className="list-check">
                                        { jobDescriptionItems }
                                    </ul>
                                    <h2>{ career_texts.requirements }</h2>
                                    <ul className="list-check">
                                        { jobRequirementsItems }
                                    </ul>
                                    <h2>What We Offer</h2>
                                    <ul className="list-check">
                                        { whatWeOffer }
                                    </ul>
                                    <p style={{ fontSize: '16px' }}>
                                        <span>
                                            If you are interested in this offer, please send your resume to
                                            {
                                                this.props.career === 'marketing-project-manager' ?
                                                  <a href="mailto:marketing@markid.lt"> marketing@markid.lt.</a>
                                                  : <a href="mailto:marius.stonkus@markid.lt"> marius.stonkus@markid.lt.</a>
                                            }
                                        </span>
                                        <span>
                                            If we’ll see there might be a match - we’ll let you know! Confidentiality is guaranteed.
                                        </span>
                                    </p>

                                </article>
                            </div>
                            <div className="col lg-6-12">
                                <ContactForm messageOne={'Apply now'} messageTwo={'Let\'s build the digital future together'} career={true}/>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    }
}
