import React, { Component } from 'react'
import MapImage from '~/img/illustration/map.svg'
import Documents from '~/img/illustration/documents.svg'
import Passport from '~/img/illustration/passport.svg'
import Companies from '~/js/components/Web/LandingComponents/Companies'
import Map from '~/img/illustration/contacts-map.svg'
import {Link} from 'react-scroll'

export default class Trading extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <main>
                <section className="intro--color intro--color--blue" id="intro">
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col lg-8-12 md-10-12">
                                <div className="intro__content ta-center">
                                    <h3 className="section-title section-title--sm">Trading</h3>
                                    <h1 className="ta-center">Compliance as a service solution tailored to the trading industry</h1>
                                    <p className="ta-center">
                                        Onboard more trade-ready customers, prevent fraud and provide for regulatory
                                        compliance in 220+ countries and territories.
                                    </p>
                                </div>
                            </div>
                            <div className="col lg-10-12">
                                <div className="ta-center">
                                    <Link className="btn btn--primary btn--primary--white" to="about" smooth={true}>Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="text-block text-block--lg" id="about">
                    <div className="grid">
                        <div className="row">
                            <div className="col">
                                <div className="text-block__list">
                                    <div className="text-block__list__item">
                                        <img src={ MapImage } alt="Map" />
                                        <div className="text">
                                            <h2>Get more trade-ready leads</h2>
                                            <p>
                                                Reduce KYC/AML & fraud check to 60 seconds and a couple of clicks, and grow deposits and allow the sales team to focus only on motivated leads.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-block__list__item">
                                        <img src={ Documents } alt="Documents" />
                                        <div className="text">
                                            <h2>Full-service compliance</h2>
                                            <p>
                                                Keep your money and reputation safe with a wide variety of checks:
                                                from identity checks, international sanctions and watchlist analysis to in-depth PEP
                                                and adverse media screening. Compliant with CySEC, FCA, FATF.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-block__list__item">
                                        <img src={ Passport } alt="Passport" />
                                        <div className="text">
                                            <h2>IDs</h2>
                                            <p>
                                                Mark ID supports 6500+ types of documents from 220+ countries and territories
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-block__list__item">
                                        <img src={ Map } alt="Map" />
                                        <div className="text">
                                            <h2>Flexible Integration</h2>
                                            <p>
                                                Integrate via mobileSDK, our iFrame widget or redirect API, with over 99.9% uptime
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blue-box" id="scroll-to">
                    <div className="grid">
                        <div className="blue-box__bg">
                            <div className="blue-box__list">
                                <div className="blue-box__list__item ta-center"><span className="title">24/7</span>
                                    <p className="description">Automated checks</p>
                                </div>
                                <div className="blue-box__list__item ta-center"><span className="title">6500+</span>
                                    <p className="description">Document types from 220+ countries</p>
                                </div>
                                <div className="blue-box__list__item ta-center"><span className="title">300s</span>
                                    <p className="description">Average manual KYC expert re-evaluation time</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Companies />
            </main>
        )
    }
}
