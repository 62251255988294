import React from 'react'
import Modal from '../Modal'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

class ModalHubspotForm extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { type } = this.props

    return (
      <Modal {...this.props}>
        <div
          className="modal-hubspot-form"
          data-pd-webforms={`/hubspot/show-form/${
            type === 'hero' && (1) ||
            type === 'aml' && (2) ||
            type === 'ident' && (3) ||
            type === 'checkout' && (4)
          }`}
        >
          <iframe
            className="modal-hubspot-form__iframe"
            src={`/hubspot/show-form/${
              type === 'hero' && (1) ||
              type === 'aml' && (2) ||
              type === 'ident' && (3) ||
              type === 'checkout' && (4)
            }`}
            scrolling="no"
            seamless="seamless"
          />
        </div>
      </Modal>
    )
  }
}

export default withTranslation()(ModalHubspotForm)

ModalHubspotForm.propTypes = {
  type: PropTypes.string,
}
