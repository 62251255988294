import React, { Component } from 'react'
import GraphicElementGreen from '~/img/hero/graphic-element-green.svg'
import ContactForm from '~/js/components/Web/LandingComponents/ContactForm'

export default class Support extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <main>
                <section className="intro--dark support--intro" id="intro">
                    <div className="intro__el">
                        <img src={ GraphicElementGreen } alt="" />
                    </div>
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col lg-8-12 md-10-12">
                                <div className="intro__content">
                                    <h1 className="ta-center">Support</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="support">
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col lg-6-12 md-8-12">
                                <ContactForm messageOne={'How can we help you?'} messageTwo={'Contact us if you need further assistance'}/>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}
