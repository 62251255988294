import React, { Component } from 'react'
import GreenGraphicElement from '~/img/hero/graphic-element-green.svg'
import Map from '~/img/illustration/id-card.svg'
import Passport from '~/img/illustration/passport.svg'
import NumberOne from '~/img/numbers/1.svg'
import Companies from '~/js/components/Web/LandingComponents/Companies'
import { Link } from 'react-scroll'

export default class DocumentVerification extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const showMoreLinks = document.querySelectorAll('.show-more')
        showMoreLinks.forEach(link => {
            link.addEventListener('click', () => {
                const itemColumnsDescription = link.previousElementSibling
                if (itemColumnsDescription.style.maxHeight) {
                    itemColumnsDescription.style.maxHeight = null
                    link.innerHTML = 'Read more'
                } else {
                    itemColumnsDescription.style.maxHeight = itemColumnsDescription.scrollHeight + "px"
                    link.innerHTML = 'Read less'
                }
            })
        })
        const itemColumnsDescriptions = document.querySelectorAll('.item__columns__description')
        itemColumnsDescriptions.forEach(description => {
            if (description.clientHeight < 120) {
                const showMoreLink = description.nextElementSibling
                showMoreLink.style.display = 'none'
            }
        })
    }

    render() {
        return (
            <main>
                <section className="intro--white" id="intro">
                    <div className="intro__el">
                        <img src={ GreenGraphicElement } alt="" />
                    </div>
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col lg-8-12 md-10-12 text__center">
                                <div className="intro__content platforms__text">
                                    <h1>Simplified identification/Document verification</h1>
                                    <p className="ta-center">
                                        Capture, analyze, and authenticate a variety of  ID documents from all over the world.
                                        Our simplified identification performs optical character recognition (OCR) of the images
                                        to automatically decipher the text.
                                    </p>
                                    <img src={ Passport } alt="Passport" />
                                </div>
                                <Link className="btn btn--primary" to="about" smooth={true}>Learn More</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="text-block" id="about">
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col">
                                <h2 className="section-title section-title--sm ta-center">How does our identity verification work?</h2>
                            </div>
                            <div className="col lg-10-12">
                                <p className="ta-center">
                                    When your customers create an account, our simplified verification helps
                                    you automate and streamline onboarding. Customers simply capture data and
                                    images from identity documents such as passports, identity cards, and resident
                                    permits with a mobile device or webcam. We decipher the text automatically
                                    by using advanced ID recognition intelligence and perform optical character
                                    recognition (OCR) of the images.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="text-block">
                    <div className="grid">
                        <div className="text-block__list">
                            <div className="text-block__list__item">
                                <img src={ Map } alt="Map" />
                                <div className="text">
                                    <h2>What benefits does our simplified verification have?
                                        <img className="number" src={ NumberOne } alt="Number One" />
                                    </h2><br/>
                                    <div className="list list--square list--square--green">
                                        <ul>
                                            <li>
                                                Our AI-driven solution enables you to verify your
                                                customers’ ID quickly, securely and in real-time
                                            </li>
                                            <li>
                                                Our simplified verification works across any industry
                                                and can be integrated easily into any environment – we offer
                                                total global coverage
                                            </li>
                                            <li>
                                                Due to the simplicity of our document verification solution,
                                                you will be able to onboard more customers and drive higher
                                                revenues for your organization or business
                                            </li>
                                            <li>
                                                Simplified verification reduces operational costs because manual
                                                processes are replaced by automated data extraction and streamline
                                                the customer onboarding process
                                            </li>
                                            <li>
                                                Our data extraction occurs in just a few seconds, enabling clients
                                                to extract information from documents very efficiently
                                            </li>
                                            <li>
                                                Our simplified verification helps detect digital manipulations of ID images,
                                                photos and content, thus reducing the possibility of confirming fake,
                                                forged, or counterfeit IDs and suffering from fraud attacks
                                            </li>
                                            <li>
                                                By automating manual processes, your company will dramatically reduce the time
                                                and operating costs associated with manual ID verification
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Companies />
            </main>
        )
    }
}
