import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import config from '~/js/config'

export function init(project) {
  if (config.get('ENVIRONMENT') === 'local') { return }

  let dsn

  if (project === 'marksign-web-app') {
    dsn = 'https://84a293d786674712817a6c6dd6683fd5@o351020.ingest.sentry.io/6602700'
  } else if (project === 'mark-id-front') {
    dsn = 'https://0bacaebddb8a4b0990bcc1e9074eebd8@o351020.ingest.sentry.io/5318917'
  } else {
    throw new Error('Invalid project argument provided')
  }

  Sentry.init({
    dsn: dsn,
    integrations: [new BrowserTracing()],
    environment: config.get('ENVIRONMENT'),
    tracesSampleRate: 1.0,
  })
}