export const SalesManagerTexts = {
    'title': 'Sales Manager - Job offer',
    'salary': '2000 - 2500 EUR (gross)',
    'requirements': 'What We Expect',
    'short_info': {
        'short_info_one': 'Mark ID – global universal identity verification platform powered by machine learning, ' +
          'as well as identity verification and e-signature gateway for the Baltic States and beyond. ' +
          'We help companies to fight fraud, reduce costs and meet KYC, GDPR and AML compliance and ' +
          'regulation requirements as well as enable digital channels'
    },
    'job_description': {
        'job_description_item_one': 'Searching for new clients and initiating active sales of company’s services for B2B sector',
        'job_description_item_two': 'Clarifying client needs',
        'job_description_item_three': 'Developing sales strategy',
        'job_description_item_four': 'Attending exhibitions and representing the company worldwide',
        'job_description_item_five': 'Identifying opportunities in various markets\n'
    },
    'job_requirements': {
        'job_requirements_item_one': 'At least 3 years of successful experience in B2B IT solutions sales to financial institutions',
        'job_requirements_item_two': 'Knowledge of sales and business environment',
        'job_requirements_item_three': 'A partnership approach to building business relationships',
        'job_requirements_item_four': 'Excellent at communicating in spoken and written English',
        'job_requirements_item_five': 'Goal orientation',
        'job_requirements_item_six': 'Negotiation and planning skills',
        'job_requirements_item_seven': 'Sense of ownership, communication skills, responsibility and strong internal motivation'
    },
    'what_we_offer': {
        'what_we_offer_item_one': 'Work with products that matter. To business and to people. Because they make a business more transparent, legit, and with less complicated processes. And it makes life easier when instead of being busy with the paperwork you can spend this time as you wish',
        'what_we_offer_item_two': 'Opportunity to contribute to business development and be rewarded respectively',
        'what_we_offer_item_three': 'A team of motivated colleagues which keeps a great vibe even during hard times',
        'what_we_offer_item_four': 'Remuneration which reflects your skill-set',
    }
}

export const BusinessManagerTexts = {
    'title': 'Business Development Manager - Job offer',
    'salary': '2000 - 2500 EUR (gross)',
    'requirements': 'What We Expect',
    'short_info': {
        'short_info_one': 'Mark ID – global universal identity verification platform powered by machine learning, ' +
          'as well as identity verification and e-signature gateway for the Baltic States and beyond. ' +
          'We help companies to fight fraud, reduce costs and meet KYC, GDPR and AML compliance and ' +
          'regulation requirements as well as enable digital channels'
    },
    'job_description': {
        'job_description_item_one': 'Searching for new business opportunities in both local and international markets',
        'job_description_item_two': 'Market research and competitive environment analysis',
        'job_description_item_three': 'Developing and executing sales strategy',
        'job_description_item_four': 'Building and promoting strong, long-lasting customer relationships by partnering with them and understanding their needs',
        'job_description_item_five': 'Improving sales processes and upselling new services'
    },
    'job_requirements': {
        'job_requirements_item_one': 'At least 3 years of successful experience working with digital solutions sales for B2B sector',
        'job_requirements_item_two': 'Interest in technology and digital solutions understanding',
        'job_requirements_item_three': 'Ability to implement business opportunities',
        'job_requirements_item_four': 'Analytical and market research skills',
        'job_requirements_item_five': 'Excellent at communicating in spoken and written English',
        'job_requirements_item_six': 'Sense of ownership, communication skills, responsibility and strong internal motivation'
    },
    'what_we_offer': {
        'what_we_offer_item_one': 'Work with products that matter. To business and to people. Because they make a business more transparent, legit, and with less complicated processes. And it makes life easier when instead of being busy with the paperwork you can spend this time as you wish',
        'what_we_offer_item_two': 'Opportunity to contribute to business development and be rewarded respectively',
        'what_we_offer_item_three': 'A team of motivated colleagues which keeps a great vibe even during hard times',
        'what_we_offer_item_four': 'Remuneration which reflects your skill-set',
    }
}

export const HeadOfProductTexts = {
    'title': 'Head of Product - Job offer',
    'salary': '3000 - 5000 EUR (gross)',
    'requirements': 'What We Expect',
    'short_info': {
        'short_info_one': 'For the ones, who need to identify their customers in a fast, secure and convenient way. ' +
          'And for those, who want to sign documents with legally binding signatures securely. We help ' +
          'companies to fight fraud, reduce costs and meet KYC, GDPR, and AML compliance and regulation ' +
          'requirements as well as enable digital channels & processes.',
        'short_info_two': 'MARK ID is a one-stop-shop solution for Identification (including Face ID), KYC & Signing ' +
          'documents with Qualified electronic signatures',
        'short_info_three': ' At this time we are looking for a team member who could be a very important element in our ' +
          'success formula, contribute to the company\'s further growth and help to scale both products ' +
          'above & beyond the borders of the Baltics',
        'short_info_four': 'As a HEAD OF PRODUCT, you will act as an entrepreneur and will be responsible for the ' +
          'process from idea-generating to implementation into real-life business cases',
    },
    'job_description': {
        'job_description_item_one': 'Understanding of the company strategy and make sure roadmap is aligned with it',
        'job_description_item_two': 'Gathering feature requests, scheduling releases, coordinating sprints, managing, and prioritizing the product backlog',
        'job_description_item_three': 'Evaluating product progress at each iteration',
        'job_description_item_four': 'Ensuring that the Product Backlog is transparent, visible, and understood',
        'job_description_item_five': 'Being the direct point of contact towards the rest of the company',
        'job_description_item_six': 'Keep abreast with Agile/Scrum best practices and new trends',
        'job_description_item_seven': 'Technologies we are working with: Symfony 4, PHP, MySQL, React'
    },
    'job_requirements': {
        'job_requirements_item_one': 'Experience in working with complex systems and 3rd party integrations',
        'job_requirements_item_two': 'A data-driven approach to decision making',
        'job_requirements_item_three': 'Balance of technical knowledge and business acumen',
        'job_requirements_item_four': 'Excellent communication skills with the ability to engage and inspire partners and stakeholders to drive collaboration and alignment',
        'job_requirements_item_five': 'At least 3 years of experience in Product Management and/or Product Development'
    },
    'what_we_offer': {
        'what_we_offer_item_one': 'Work with products that matter. To business and to people. Because they make a business more transparent, legit, and with less complicated processes. And it makes life easier when instead of being busy with the paperwork you can spend this time as you wish',
        'what_we_offer_item_two': 'Opportunity to contribute to business development and be rewarded respectively',
        'what_we_offer_item_three': 'A team of motivated colleagues which keeps a great vibe even during hard times',
        'what_we_offer_item_four': 'Remuneration which reflects your skill-set',
    }
}

export const PhpEngineer = {
    'title': 'PHP Engineer - Job offer',
    'salary': 'From 2000 EUR (gross)',
    'requirements': 'What we wish you would have',
    'short_info': {
        'short_info_one': 'For the ones, who need to identify their customers in a fast, secure and convenient way. And for ' +
          'those, who want to sign e-documents with legally binding e-signatures securely. We help ' +
          'companies to fight fraud, reduce costs and meet KYC, GDPR, and AML compliance and ' +
          'regulation requirements as well as enable digital channels & processes.',
        'short_info_two': '<strong>MARK ID</strong> is a one-stop-shop solution for Identification (including Face ID), KYC & Signing ' +
          'documents with Qualified electronic signatures. At this time we are looking for talent in the ' +
          'essential team for us – DEV team.',
        'short_info_three': 'As a PHP ENGINEER, you would be working on scaling our products above & beyond the borders ' +
          'of the Baltics, shape the way how thousands of people are using our products to make their life ' +
          'easier and last but definitely not least - participate in challenges with team members to win the ' +
          '“Stronghold Crusader” battles.',
    },
    'job_description': {
        'job_description_item_one': 'Work in a multi-functional, self-organising team based on Agile principles',
        'job_description_item_two': 'Participate in the planning, design & implementation of Products together with DEV ' +
          'team & Product owner to develop functional, appealing and customers-beloved ' +
          'products',
        'job_description_item_three': 'Troubleshoot application and code issues',
        'job_description_item_four': 'Share your experience and best practices, give and receive code reviews'
    },
    'job_requirements': {
        'job_requirements_item_one': '1+ years of experience working in a team of developers on complex, sophisticated ' +
          'projects',
        'job_requirements_item_two': '2-3+ years of work experience as a developer',
        'job_requirements_item_three': 'Good knowledge of PHP programming language. Knowing others on top – would be an ' +
          'advantage',
        'job_requirements_item_four': 'Experience working with Symfony framework, MySQL, Docker (would be an additional ' +
          'advantage as those are some of the technologies that we use daily)',
        'job_requirements_item_five': 'Basic front-end development experience (React, Webpack, SCSS/SASS)',
        'job_requirements_item_six': 'Good problem-solving skills',
        'job_requirements_item_seven': 'Excellent collaboration, communication skills',
        'job_requirements_item_eight': 'Ability to win against Artūras in “Stronghold Crusader“ (seriously, it seems impossible)'
    },
    'what_we_offer': {
        'what_we_offer_item_one': 'Work with products that matter. To business and people. Because they make a business ' +
          'more transparent & legit and life easier when instead of being busy with the paperwork ' +
          'you can spend this time as you wish',
        'what_we_offer_item_two': 'A professional and supportive team that is keen on sharing their knowledge and spend ' +
          'time together',
        'what_we_offer_item_three': 'Opportunity to grow professionally',
        'what_we_offer_item_four': 'Office snacks & other perks',
        'what_we_offer_item_five': 'Remuneration which reflects your skill-set',
        'what_we_offer_item_six': 'Ability work from home'
    }
}

export const JuniorSalesRep = {
    'title': 'Junior Sales Rep - Job offer',
    'salary': 'Salary 700-1100 EUR (gross)',
    'requirements': 'What we wish you would have',
    'short_info': {
        'short_info_one': 'For the ones, who need to identify their customers in a fast, secure and convenient way. And for ' +
            'those, who want to sign e-documents with legally binding e-signatures securely. We help ' +
            'companies to fight fraud, reduce costs and meet KYC, GDPR, and AML compliance and ' +
            'regulation requirements as well as enable digital channels & processes.',
        'short_info_two': '<strong>MARK ID</strong> is a one-stop-shop solution for Identification (including Face ID), KYC & Signing ' +
            'documents with Qualified electronic signatures. At this time we are looking for an energetic ' +
            'personality to join our Sales team.',
        'short_info_three': 'As a JUNIOR SALE REP, you would be a key contact person for certain clients and their hero, ' +
            'helping to find the best solution to overcome appearing challenges. Also we see you working ' +
            'closely with your manager in order to grow further and become even more important part of a ' +
            'team.',
    },
    'job_description': {
        'job_description_item_one': 'Communicate with existing clients and be their helping hand in solving issues',
        'job_description_item_two': 'Onboard new ones and provide best-in-class experience of our services',
        'job_description_item_three': 'Try out a salesman pants and learn how to pitch potential prospects or do some up-sell ' +
          'for the current ones',
        'job_description_item_four': 'Do some analytics and help the team to find underlying opportunities',
        'job_description_item_five': 'Assist your manager and grow your knowledge'
    },
    'job_requirements': {
        'job_requirements_item_one': 'The right attitude towards your job and motivation to grow',
        'job_requirements_item_two': 'Excellent communication skills and ability to do that effectively',
        'job_requirements_item_three': 'Knowledge of MS Office tools',
        'job_requirements_item_four': 'Great level of English (in written and oral)',
        'job_requirements_item_five': 'Skills to plan your work and meet agreed dead-lines',
        'job_requirements_item_six': 'Experience in sales, especially in B2B, would be an advantage',
    },
    'what_we_offer': {
        'what_we_offer_item_one': 'Work with products that matter. To business and people. Because they make a business ' +
          'more transparent & legit and life easier when instead of being busy with the paperwork ' +
          'you can spend this time as you wish',
        'what_we_offer_item_two': 'A professional and supportive team that is keen on sharing their knowledge and spend ' +
          'time together',
        'what_we_offer_item_three': 'Opportunity to grow professionally',
        'what_we_offer_item_four': 'Office snacks & other perks',
        'what_we_offer_item_five': 'Ability work from home'
    }
}

export const MarketingProjectManager = {
    'title': 'Marketing Project Manager - Job offer',
    'salary': 'Salary 800-1300 EUR (gross)',
    'requirements': 'What we wish you would have',
    'short_info': {
        'short_info_one': 'For the ones, who need to identify their customers in a fast, secure and convenient way. ' +
          'And for those, who want to sign e-documents with legally binding e-signatures securely. We help companies to ' +
          'fight fraud, reduce costs and meet KYC, GDPR, and AML compliance and regulation requirements as well as ' +
          'enable digital channels & processes.',
        'short_info_two': '<strong>MARK ID</strong> is a one-stop-shop solution for Identification (including Face ID), ' +
          'KYC & Signing documents with Qualified electronic signatures. Currently, we are looking for a creative and ' +
          'result-oriented personality to join our Marketing team.',
        'short_info_three': 'As a MARKETING PROJECT MANAGER, you will be responsible for Mark ID and Mark Sign brand ' +
          'awareness and ambitious marketing projects. Also, we see you working closely with the Marketing team lead ' +
          'to grow further and become an even more important part of a team. ',
    },
    'job_description': {
        'job_description_item_one': 'Create and manage social media accounts, our websites ',
        'job_description_item_two': 'Evaluate the performance of various marketing channels, thinking of new fresh ways ' +
          'to communicate Mark ID and Mark Sign solutions ',
        'job_description_item_three': 'Plan and oversee newsletter communication',
        'job_description_item_four': 'Execute brand awareness and customer acquisition campaigns ',
        'job_description_item_five': 'Manage marketing automation projects',
        'job_description_item_six': 'Analyse market, its competitors and search for new opportunities',
        'job_description_item_seven': 'Work with team members, external agencies, freelancers to complete marketing ' +
          'requests and ensure the best customer experience'
    },
    'job_requirements': {
        'job_requirements_item_one': 'Higher education in marketing (advantage)',
        'job_requirements_item_two': '1-2+ years of experience in marketing, especially working with digital marketing projects',
        'job_requirements_item_three': 'Understand the core principles and best practices of social media and SEO',
        'job_requirements_item_four': 'Experience with Google Analytics, Facebook Insights, and similar digital tools',
        'job_requirements_item_five': 'Excellent communication skills and ability to do that effectively in both ' +
          'Lithuanian and English languages',
        'job_requirements_item_six': 'The right attitude towards your job and motivation to grow',
        'job_requirements_item_seven': 'Skills to plan your work and meet agreed deadlines',
    },
    'what_we_offer': {
        'what_we_offer_item_one': 'Work with products that matter. To business and people. Because they make a business ' +
          'more transparent & legit and life easier when instead of being busy with the paperwork you can spend this time as you wish',
        'what_we_offer_item_two': 'A professional and supportive team that is keen on sharing their knowledge and spending time together',
        'what_we_offer_item_three': 'Opportunity to grow professionally',
        'what_we_offer_item_four': 'Office snacks, tasty coffee & other perks',
        'what_we_offer_item_five': 'Ability work from home'
    }
}
