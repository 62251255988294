import React, { Component } from 'react'
import ContactForm from '~/js/components/Web/LandingComponents/ContactForm'

export default class Contacts extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <main>
        <section id="contacts">
          <div className="grid">
            <div className="row">
              <div className="col xl-4-12 xl-push-left-1-12 xl-push-right-1-12 sm-5-12">
                <h1 className="section-title section-title--sm">Let&apos;s talk</h1>
                <h2>Got Questions?</h2>
                <p>We’ve got answers. Drop us a line. We’d love to talk.</p>
                <div className="contacts__item">
                  <p>Address:</p>Žygio g. 97A, Vilnius, Lithuania
                </div>
                <div className="contacts__item">
                  <p>Email:</p><a href="mailto:info@markid.lt">info@markid.lt</a>
                </div>
                <div className="contacts__item">
                  <a href="https://lt.linkedin.com/company/markid" target="_blank" rel="noreferrer">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0 1.06667V14.9333C0 15.52 0.48 16 1.06667 16H14.9333C15.52 16 16 15.52 16 14.9333V1.06667C16 0.48 15.52 0 14.9333 0H1.06667C0.48 0 0 0.48 0 1.06667ZM4.56 13.3333H2.66667V5.97333H4.56V13.3333ZM3.62667 4.50667C3.09333 4.50667 2.66667 4.10667 2.66667 3.6C2.66667 3.09333 3.09333 2.69333 3.62667 2.69333C4.16 2.69333 4.58667 3.09333 4.58667 3.6C4.56 4.10667 4.13333 4.50667 3.62667 4.50667ZM13.3333 13.3333H11.44V9.65333C11.44 8.88 11.4133 8.37333 11.3333 8.13333C11.2533 7.89333 11.12 7.73333 10.96 7.6C10.7733 7.46667 10.56 7.41333 10.32 7.41333C10 7.41333 9.70667 7.49333 9.46667 7.68C9.2 7.86667 9.04 8.10667 8.93333 8.4C8.82667 8.69333 8.8 9.25333 8.8 10.0533V13.3333H6.50667V5.97333H8.4V7.06667C9.04 6.24 9.86667 5.81333 10.8533 5.81333C11.28 5.81333 11.68 5.89333 12.0533 6.05333C12.4 6.21333 12.6933 6.4 12.88 6.64C13.0667 6.88 13.2 7.14667 13.2533 7.46667C13.3333 7.76 13.36 8.21333 13.36 8.77333V13.3333H13.3333Z"
                        fill="#0e76a8"/>
                    </svg>
                  </a>
                </div>
              </div>
              <div className="col xl-6-12 sm-7-12">
                <ContactForm messageOne={'Are you ready to get started?'} messageTwo={'We make it easy.'}/>
              </div>
            </div>
          </div>
        </section>
      </main>
    )
  }
}