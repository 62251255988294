export const NAMESPACE_USER = 'user'
export const NAMESPACE_WORKSPACE = 'workspace'

/* eslint-disable no-multi-spaces */
export const ROUTE_NAMES = Object.freeze({
  HOME_PAGE:                       'homePage',
  CONTACTS:                        'contacts',
  AML_SCREENING:                   'amlScreening',
  PRIVACY_POLICY:                  'privacy',
  PRIVACY_POLICY_LT:               'privacyLt',
  ABOUT:                           'about',
  FINTECH:                         'fintech',
  GAMING:                          'gaming',
  TRADING:                         'trading',
  MONEY_INSTITUTIONS:              'moneyInstitutions',
  SERVICE_PROVIDERS:               'serviceProviders',
  PRICING:                         'pricing',
  CAREER_ACHIEVE:                  'careerAchieve',
  CAREER_SINGLE:                   'careerSingle',
  NOT_FOUND:                       'notFound',
  SUPPORT:                         'support',
  ELECTRONIC_SIGNATURE:            'electronicSignature',
  IDENTITY_VERIFICATION:           'identityVerification',
  DOCUMENT_VERIFICATION:           'documentVerification',
  SOFTWARE_DIAGNOSTIC:             'softwareDiagnostic',
})

export const ROUTE_PATHS = new Map([
  [ROUTE_NAMES.HOME_PAGE,                       '/'],
  [ROUTE_NAMES.CONTACTS,                        '/contacts'],
  [ROUTE_NAMES.AML_SCREENING,                   '/aml-screening'],
  [ROUTE_NAMES.PRIVACY_POLICY,                  '/privacy'],
  [ROUTE_NAMES.PRIVACY_POLICY_LT,               '/privatumo-politika'],
  [ROUTE_NAMES.ABOUT,                           '/about'],
  [ROUTE_NAMES.FINTECH,                         '/fintech'],
  [ROUTE_NAMES.GAMING,                          '/gaming'],
  [ROUTE_NAMES.TRADING,                         '/trading'],
  [ROUTE_NAMES.MONEY_INSTITUTIONS,              '/money-institutions'],
  [ROUTE_NAMES.SERVICE_PROVIDERS,               '/service-providers'],
  [ROUTE_NAMES.PRICING,                         '/pricing'],
  [ROUTE_NAMES.CAREER_ACHIEVE,                  '/career-achieve'],
  [ROUTE_NAMES.CAREER_SINGLE,                   '/career-single'],
  [ROUTE_NAMES.NOT_FOUND,                       '/not-found'],
  [ROUTE_NAMES.SUPPORT,                         '/support'],
  [ROUTE_NAMES.ELECTRONIC_SIGNATURE,            '/electronic-signature'],
  [ROUTE_NAMES.IDENTITY_VERIFICATION,           '/identity-verification'],
  [ROUTE_NAMES.DOCUMENT_VERIFICATION,           '/document-verification'],
  [ROUTE_NAMES.SOFTWARE_DIAGNOSTIC,             '/software/diagnostic'],
])
/* eslint-enable no-multi-spaces */
