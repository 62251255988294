import React from 'react'
import WhiteLogo from '~/img/markid-logo-white.svg'
import LinkedInIcon from '~/img/icons/linkedin.svg'
import FacebookIcon from '~/img/icons/facebook.svg'
import iso from '~/img/footer/iso.svg'
import iso14001 from '~/img/footer/iso-14001.svg'
import '~/sass/5_layout/_2-footer.scss'

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="bg">
        <div className="grid">
          <div className="row">
            <div className="col">
              <div className="footer__top">
                <h3>Ready to get started?</h3>
                <p>Get in touch with us</p>
                <a className="btn btn--primary" href="/contacts">Contact us</a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col md-2-12 order">
              <div className="footer__bottom-left">
                <a href="/"><img src={WhiteLogo} alt="Logo" /></a>
                <div className="footer__bottom-left__social">
                  <a href="https://lt.linkedin.com/company/markid" target="_blank" rel="noreferrer">
                    <img src={LinkedInIcon} alt="LinkedIn Icon" className="icon_linkedIn" />
                  </a>
                  <a href="https://www.facebook.com/Mark-Sign-103903928537110" target="_blank" rel="noreferrer">
                    <img src={FacebookIcon} alt="Facebook Icon" className="icon_facebook" />
                  </a>
                </div>
                <p>&copy; {new Date().getFullYear()} Mark ID</p>
              </div>
            </div>
            <div className="col md-10-12">
              <div className="row">
                <div className="col md-12-12">
                  <nav id="footer-nav">
                    <ul>
                      <li className="bold"><h5>Platforms</h5></li>
                      <li><a href="/aml-screening">AML screening</a></li>
                      <li><a href="/electronic-signature">Electronic Signatures</a></li>
                      <li><a href="/identity-verification">Identity Verification</a></li>
                      <li><a href="/document-verification">Document Verification</a></li>
                    </ul>
                    <ul>
                      <li className="bold"><h5>Company</h5></li>
                      <li><a href="/about">About us</a></li>
                      <li><a href="/contacts">Contact Us</a></li>
                      <li><a href="/career-achieve">Careers</a></li>
                    </ul>
                    <ul>
                      <li className="bold"><h5>Development</h5></li>
                      <li><a href="/support">Support</a></li>
                    </ul>
                    <ul>
                      <li className="bold"><h5>Resources</h5></li>
                      <li><a href="/privacy">Privacy Policy</a></li>
                      <li><a href="/privatumo-politika">Privatumo Politika</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="row">
                <div className="col md-12-12">
                    <a href="/pdf/markid-iso-27001-certificate-2023-en.pdf" className="footer-iso-logo1">
                      <img className="footer_iso_logo" src={iso} alt="iso 27001" />
                    </a>
                    <a href="/pdf/markid-iso-14001-certificate-2023-en.pdf" className="footer-iso-logo2">
                      <img src={iso14001} alt="iso 14001" />
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
