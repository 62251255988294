import React from 'react'
import config from '~/js/config'
import SignatureIcon from '~/img/header/signature.svg'
import IdIcon from '~/img/header/id.svg'
import ShieldIcon from '~/img/header/shield.svg'
import PersonSearchIcon from '~/img/header/person-search.svg'
import FintechIcon from '~/img/illustration/fintech.svg'
import GamingIcon from '~/img/illustration/gaming.svg'
import TradingIcon from '~/img/illustration/trading.svg'
import ServiceProvidersIcon from '~/img/illustration/service-providers.svg'
import MoneyInstitutionsIcon from '~/img/illustration/money-institutions.svg'
import { getMarkSignHost } from '../../../../utils/host'

export default class Navigation extends React.Component {
    constructor(props) {
        super(props)

        this.header = React.createRef()
    }

    componentDidMount() {
        this.handleMobileNavigation()
    }

    handleMobileNavigation() {
        const mobileMenu = document.querySelector('.mobile-menu')
        const mobileNav = document.querySelector('#nav-mobile')
        const header = document.querySelector('header')
        const body = document.querySelector('body')

        mobileMenu.addEventListener('click', () => {
            header.classList.toggle('mobile')
            mobileMenu.classList.toggle('active')
            mobileNav.classList.toggle('active')
            const bodyPosition = Math.abs(body.getBoundingClientRect().top)
            body.classList.toggle('disabled')
            if (mobileNav.classList.contains('active')) {
                body.style.top = `-${bodyPosition}px`
            } else {
                window.scrollTo(0, bodyPosition)
            }
        })

        const navLinks = document.querySelectorAll('#nav-mobile .nav__link');
        navLinks.forEach(link => {
            link.addEventListener('click', () => {
                const dropdown = link.nextElementSibling
                if (dropdown.style.maxHeight) {
                    dropdown.style.maxHeight = null
                    link.classList.remove('open')
                } else {
                    dropdown.style.maxHeight = dropdown.scrollHeight + "px"
                    link.classList.add('open')
                }
            })
        })
    }

    render() {
        return (
            <div className="grid">
                <div className="row">
                    <div className="col">
                        <div className="header__top">
                            <nav id="nav-secondary">
                                <ul>
                                    <li>
                                        <a href="/about">Company</a>
                                    </li>
                                    <li>
                                        <a href="/contacts">Contact Us</a>
                                    </li>
                                    <li><a className="btn btn--primary" href={`https://${getMarkSignHost()}`}>eSignature</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="header__bottom">
                            <a className="logo" href="/">
                                <svg width="132" height="40" viewBox="0 0 106 32" fill="#183462" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0)">
                                        <rect x="86.23" y="9.36" className="st0" width="3.57" height="14.22"/>
                                        <path className="st0" d="M97.3,20.76c2.81,0,4.7-1.73,4.7-4.25v-0.04c0-2.52-1.89-4.29-4.7-4.29h-2.32v8.57H97.3L97.3,20.76z
		 M91.22,9.36h6.08c4.9,0,8.28,3.07,8.28,7.07v0.04c0,4-3.38,7.11-8.28,7.11h-6.08V9.36z"/>
                                        <path className="st0" d="M41.86,17.67l-2.28-4.58l-2.28,4.58H41.86L41.86,17.67z M37.89,9.36h3.48l7.36,14.22h-3.95l-1.57-3.17h-7.26
		l-1.57,3.17h-3.85L37.89,9.36z"/>
                                        <path className="st0" d="M57.54,16.27c0.94,0,1.66-0.18,2.16-0.55c0.5-0.37,0.75-0.85,0.75-1.46v-0.04c0-0.68-0.26-1.19-0.79-1.52
		c-0.53-0.34-1.26-0.51-2.2-0.51h-3.78v4.08H57.54L57.54,16.27z M49.88,9.36h7.9c2.19,0,3.87,0.48,5.04,1.44
		c0.99,0.81,1.48,1.9,1.48,3.25v0.04c0,1.15-0.34,2.09-1.03,2.81c-0.68,0.72-1.58,1.42-2.68,1.76l4.22,4.91h-4.45l-3.7-4.39h-0.05
		h-2.94v4.39h-3.8V9.36z"/>
                                        <polygon className="st0" points="66.86,9.36 70.66,9.36 70.66,15.58 77.68,9.36 82.27,9.36 75.23,15.4 82.59,23.58 78.02,23.58
		72.66,17.53 70.66,19.24 70.66,23.58 66.86,23.58"/>
                                        <path className="st0"
                                              d="M9.58,10.23H1.2c-0.71,1.8-1.1,3.76-1.1,5.81c0,6.48,3.9,12.05,9.48,14.5V10.23z"/>
                                        <path className="st0" d="M11.27,0.9v7.64H15l4.79,6.3h0l3.45-4.53l1.34-1.76h5.31c-2.65-4.92-7.83-8.28-13.8-8.34l0,0h-0.31
		C14.22,0.22,12.7,0.46,11.27,0.9L11.27,0.9z M11.27,23.58h3.26v-9.51l5.12,6.31h0.27l5.12-6.31v9.51h4.81l0,0
		c-2.68,4.94-7.91,8.29-13.93,8.29c-1.62,0-3.19-0.25-4.67-0.7V23.58z"/>
                                        <path className="st0" d="M9.58,8.54v-7c-3.24,1.42-5.92,3.9-7.59,7H9.58z"/>
                                    </g>
                                </svg>
                            </a>
                            <nav id="nav-primary">
                                <ul>
                                    <li>
                                        <a href="#">Solutions
                                            <svg className="dropdown-arrow" width="10" height="6" viewBox="0 0 10 6"
                                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeWidth="2px" d="M1 1L5 5L9 1" stroke="white"
                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </a>
                                        <div className="dropdown">
                                            <a className="dropdown__item" href="/aml-screening">
                                                <img src={ PersonSearchIcon } alt="Person search icon" />
                                                <div className="dropdown__item__text">
                                                    <h6>AML Screening</h6>
                                                </div>
                                            </a>
                                            <a className="dropdown__item" href="/electronic-signature">
                                                <img src={ SignatureIcon } alt="Signature Icon" />
                                                <div className="dropdown__item__text">
                                                    <h6>Electronic Signatures</h6>
                                                </div>
                                            </a>
                                            <a className="dropdown__item" href="/identity-verification">
                                                <img src={ ShieldIcon } alt="Sanction Icon" />
                                                <div className="dropdown__item__text">
                                                    <h6>Identity Verification</h6>
                                                </div>
                                            </a>
                                            <a className="dropdown__item" href="/document-verification">
                                                <img src={ IdIcon } alt="Sanction Icon" />
                                                <div className="dropdown__item__text">
                                                    <h6>Document Verification</h6>
                                                </div>
                                            </a>
                                        </div>
                                    </li>
                                    <li><a href="#">
                                        Use Cases
                                        <svg className="dropdown-arrow" width="10" height="6" viewBox="0 0 10 6"
                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeWidth="2px" d="M1 1L5 5L9 1" stroke="white"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </a>
                                        <div className="dropdown">
                                            <a className="dropdown__item" href="/fintech">
                                                <img src={ FintechIcon } alt="Fintech Icon" />
                                                <div className="dropdown__item__text">
                                                    <h6>Fintech</h6>
                                                </div>
                                            </a>
                                            <a className="dropdown__item" href="/gaming">
                                                <img src={ GamingIcon } alt="Gaming Icon" />
                                                <div className="dropdown__item__text">
                                                    <h6>Gaming</h6>
                                                </div>
                                            </a>
                                            <a className="dropdown__item" href="/trading">
                                                <img src={ TradingIcon } alt="Trading Icon" />
                                                <div className="dropdown__item__text">
                                                    <h6>Trading</h6>
                                                </div>
                                            </a>
                                            <a className="dropdown__item" href="/money-institutions">
                                                <img src={ MoneyInstitutionsIcon } alt="Money Institutions Icon" />
                                                <div className="dropdown__item__text">
                                                    <h6>Money Institutions</h6>
                                                </div>
                                            </a>
                                            <a className="dropdown__item" href="/service-providers">
                                                <img src={ ServiceProvidersIcon } alt="Service Providers Icon" />
                                                <div className="dropdown__item__text">
                                                    <h6>Payment Service Providers</h6>
                                                </div>
                                            </a>
                                        </div>
                                    </li>
                                    {/*<li><a href="#">*/}
                                    {/*    Pricing*/}
                                    {/*    <svg className="dropdown-arrow" width="10" height="6" viewBox="0 0 10 6"*/}
                                    {/*         fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*        <path strokeWidth="2px" d="M1 1L5 5L9 1" stroke="white"*/}
                                    {/*              strokeLinecap="round" strokeLinejoin="round"/>*/}
                                    {/*    </svg>*/}
                                    {/*</a>*/}
                                    {/*    <div className="dropdown">*/}
                                    {/*        <a className="dropdown__item" href="/pricing">*/}
                                    {/*            <img src={ SignatureIcon } alt="Signature Icon" />*/}
                                    {/*            <div className="dropdown__item__text">*/}
                                    {/*                <h6>Electronic Signatures</h6>*/}
                                    {/*            </div>*/}
                                    {/*        </a>*/}
                                    {/*    </div>*/}
                                    {/*</li>*/}
                                    <li><a href="#">
                                        Development
                                        <svg className="dropdown-arrow" width="10" height="6" viewBox="0 0 10 6"
                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeWidth="2px" d="M1 1L5 5L9 1" stroke="white"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </a>
                                        <div className="dropdown">
                                            <a className="dropdown__item" href="/support">
                                                <img src={ IdIcon } alt="Id Icon" />
                                                <div className="dropdown__item__text">
                                                    <h6>Support</h6>
                                                </div>
                                            </a>
                                            <a className="dropdown__item" target="_blank" href={config.get('MARKID_DOCUMENTATION_URL')}>
                                                <img src={ShieldIcon} alt="Id Icon" />
                                                <div className="dropdown__item__text">
                                                    <h6>Documentation</h6>
                                                </div>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                            <div className="mobile-menu">
                                <div className="menu__icon">
                                    <div className="menu__icon__line"/>
                                    <div className="menu__icon__line"/>
                                    <div className="menu__icon__line"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
