import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class CareerAchieve extends Component{
    render() {
        return (
            <main>
                <section className="intro--no-bg" id="intro">
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col lg-9-12">
                                <div className="intro__content ta-center">
                                    <h1>Let's Build The Digital Future Together</h1>
                                    <p>And have fun on the way!</p><a className="btn btn--primary" href="#">Learn
                                    More</a>
                                </div>
                            </div>
                            <div className="col">
                                <div className="intro__collage">
                                    <div className="intro__collage__group">
                                        <div className="intro__collage__wrapper">
                                            <div className="img"/>
                                        </div>
                                        <div className="intro__collage__wrapper">
                                            <div className="img"/>
                                        </div>
                                    </div>
                                    <div className="intro__collage__group">
                                        <div className="intro__collage__wrapper">
                                            <div className="img"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="cards" id="scroll-to">
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col">
                                <h2 className="ta-center careers-heading">See All Our Open Positions</h2>
                            </div>
                            <div className="col">
                                <Link className="cards__item" to="/career/marketing-project-manager">
                                    <div className="cards__item__text">
                                        <h5>Marketing Project Manager</h5>
                                        <p>Vilnius, Lithuania</p>
                                    </div>
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M2.20718 11.5L0.792969 10.0858L5.08586 5.79289L0.792968 1.5L2.20718 0.0857868L7.91429 5.79289L2.20718 11.5Z"
                                              fill="#57586E"/>
                                    </svg>
                                </Link>
                            </div>
                            <div className="col">
                                <Link className="cards__item" to="/career/junior-sales-rep">
                                    <div className="cards__item__text">
                                        <h5>Junior Sales Rep</h5>
                                        <p>Vilnius, Lithuania</p>
                                    </div>
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M2.20718 11.5L0.792969 10.0858L5.08586 5.79289L0.792968 1.5L2.20718 0.0857868L7.91429 5.79289L2.20718 11.5Z"
                                              fill="#57586E"/>
                                    </svg>
                                </Link>
                            </div>
                            <div className="col">
                                <Link className="cards__item" to="/career/php-engineer">
                                    <div className="cards__item__text">
                                        <h5>PHP Engineer</h5>
                                        <p>Vilnius, Lithuania</p>
                                    </div>
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M2.20718 11.5L0.792969 10.0858L5.08586 5.79289L0.792968 1.5L2.20718 0.0857868L7.91429 5.79289L2.20718 11.5Z"
                                              fill="#57586E"/>
                                    </svg>
                                </Link>
                            </div>
                            <div className="col">
                                <Link className="cards__item" to="/career/head-of-product">
                                    <div className="cards__item__text">
                                        <h5>Head of Product</h5>
                                        <p>Vilnius, Lithuania</p>
                                    </div>
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M2.20718 11.5L0.792969 10.0858L5.08586 5.79289L0.792968 1.5L2.20718 0.0857868L7.91429 5.79289L2.20718 11.5Z"
                                              fill="#57586E"/>
                                    </svg>
                                </Link>
                            </div>
                            <div className="col">
                                <Link className="cards__item" to="/career/sales-manager">
                                    <div className="cards__item__text">
                                        <h5>Sales Manager</h5>
                                        <p>Vilnius, Lithuania</p>
                                    </div>
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M2.20718 11.5L0.792969 10.0858L5.08586 5.79289L0.792968 1.5L2.20718 0.0857868L7.91429 5.79289L2.20718 11.5Z"
                                              fill="#57586E"/>
                                    </svg>
                                </Link>
                            </div>
                            <div className="col">
                                <Link className="cards__item" to="/career/business-manager">
                                    <div className="cards__item__text">
                                        <h5>Business Development Manager</h5>
                                        <p>Vilnius, Lithuania</p>
                                    </div>
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M2.20718 11.5L0.792969 10.0858L5.08586 5.79289L0.792968 1.5L2.20718 0.0857868L7.91429 5.79289L2.20718 11.5Z"
                                              fill="#57586E"/>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<section className="blue-box">*/}
                {/*    <div className="grid">*/}
                {/*        <div className="blue-box__bg blue-box__content-flex">*/}
                {/*            <div className="blue-box__title">*/}
                {/*                <h3>Get notified when a new position opens</h3>*/}
                {/*            </div>*/}
                {/*            <div className="form form--main">*/}
                {/*                <p>Enter email to subscribe to our newsletter</p>*/}
                {/*                <form className="form__content">*/}
                {/*                    <input type="email" name="email" id="email" placeholder="Email" />*/}
                {/*                    <label htmlFor="accept">Agree with&nbsp;*/}
                {/*                        <a href="#">privacy policy</a>*/}
                {/*                    </label>*/}
                {/*                    <input type="checkbox" id="accept" name="accept" />*/}
                {/*                    <a className="btn btn--primary ta-center" href="#">Submit</a>*/}
                {/*                </form>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
            </main>
        )
    }
}
