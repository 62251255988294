import React, { Component } from 'react'
import Industries from '~/js/components/Web/LandingComponents/Industries'
import Companies from '~/js/components/Web/LandingComponents/Companies'
import GraphicElementGreenIcon from '~/img/hero/graphic-element-green.svg'
import Signature from '~/img/illustration/signature_620x413.png'
import MapIcon from '~/img/illustration/passport.svg'
import PeopleIcon from '~/img/illustration/people.svg'
import ModalHubspot from '../../../../components/ModalHubspotForm/ModalHubspotForm'
import { getMarkSignHost } from '../../../../utils/host'

export default class HomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalHubspotHero: {
        active: false
      },
      modalHubspotIdent: {
        active: false
      },
      modalHubspotAml: {
        active: false
      }
    }
  }

  handleModalHubspotHero = () => {
    this.setState({
      modalHubspotHero: {
        active: true
      }
    })
  }

  handleModalHubspotIdent = () => {
    this.setState({
      modalHubspotIdent: {
        active: true
      }
    })
  }

  handleModalHubspotAml = () => {
    this.setState({
      modalHubspotAml: {
        active: true
      }
    })
  }

  render() {
    return (
      <main>
        <section
          className="intro--dark"
          id="intro">
          <div className="intro__video">
            <video autoPlay muted playsInline id="video">
              <source src="/video/video.mp4" type="video/mp4" />
              <source src="/video/video.mp4" type="video/ogg" />
                                Your browser does not support HTML video.
            </video>
          </div>
          <div className="intro__el">
            <img src={ GraphicElementGreenIcon } alt="Cover Image" />
          </div>
          <div className="grid">
            <div className="row">
              <div className="col">
                <div className="intro__content">
                  <h1 className="ta-center">Universal Identification/KYC and <br/>eSignature gateway</h1>
                  <p className="ta-center">All type of identifications throughout 1 API</p>
                  <button className="btn btn--primary" onClick={ this.handleModalHubspotHero }>Request demo</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="text-block" id="about">
          <div className="grid">
            <div className="row">
              <div className="col">
                <h3 className="section-title section-title--lg ta-center">What we offer</h3>
              </div>
              <div className="col">
                <div className="text-block__list">
                  <div className="text-block__list__item">
                    <img src={ Signature } className="img-signature" alt="Signature" />
                    <div className="text">
                      <h2>Qualified Electronic Signatures</h2>
                      <p>Easily and securely sign any document you need.
                                                Enable signatures with electronic identities (eIDs) and be sure of the individual signing your document.
                      </p>
                      <div className="list list--square list--square--green margin-bottom-32">
                        <ul>
                          <li>An eSignature is just as <strong>legally binding</strong> as a physical signature in <strong>EU</strong></li>
                          <li>Sign documents by using qualified eSignature via your <strong>Smart-ID</strong> or <strong>Mobile-ID</strong></li>
                          <li>eIDAS, GDPR compliant </li>
                          <li><strong>White-label</strong> solution</li>
                        </ul>
                      </div>
                      <a className="btn btn--primary margin-right-32" href="/electronic-signature">Discover more</a >
                      <a className="btn btn--secondary" href={`https://${getMarkSignHost()}`}>Go to marksign.lt</a>
                    </div>
                  </div>
                  <div className="text-block__list__item">
                    <img src={ MapIcon } alt="Map" />
                    <div className="text">
                      <h2>Identity Verification</h2>
                      <p>Machine-based identity verification transforming a customer paper-based world to a cross-border digital world in a few short seconds.
                      </p>
                      <div className="list list--square list--square--green margin-bottom-32 margin-bottom-32">
                        <ul>
                          <li><strong>Facial Liveness</strong> detection</li>
                          <li><strong>Simplified</strong> Identification</li>
                          <li>Automatic or hybrid (re-evaluated by a human KYC expert) identifications</li>
                          <li>Identification via <strong>Smart-ID</strong> or <strong>Mobile-ID</strong> - Baltics</li>
                          <li><strong>SMS</strong> Authentication</li>
                        </ul>
                      </div>
                      <button className="btn btn--primary margin-right-32" onClick={ this.handleModalHubspotIdent }>Request demo</button>
                      <a className="btn btn--secondary" href="/identity-verification">Discover more</a>
                    </div>
                  </div>
                  <div className="text-block__list__item">
                    <img src={ PeopleIcon } alt="People" />
                    <div className="text">
                      <h2>Anti-money laundering (AML) services</h2>
                      <p>Meet regulatory obligations and provide AML screening for all customers.
                                                Trust services entrusted by the world‘s best.
                      </p>
                      <div className="list list--square list--square--green margin-bottom-32">
                        <ul>
                          <li>Politically Exposed Persons (<strong>PEPS</strong>)</li>
                          <li>Global <strong>Sanctions & Watchlists</strong></li>
                          <li><strong>Adverse/Negative</strong> Media Screening</li>
                        </ul>
                      </div>
                      <button className="btn btn--primary margin-right-32" onClick={ this.handleModalHubspotAml }>Request demo</button>
                      <a className="btn btn--secondary" href="/aml-screening">Discover more</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ModalHubspot
          type='hero'
          active={this.state.modalHubspotHero.active}
          onCloseClick={() => this.setState({ modalHubspotHero: { active: false } })}/>
        <ModalHubspot
          type='ident'
          active={this.state.modalHubspotIdent.active}
          onCloseClick={() => this.setState({ modalHubspotIdent: { active: false } })}/>
        <ModalHubspot
          type='aml'
          active={this.state.modalHubspotAml.active}
          onCloseClick={() => this.setState({ modalHubspotAml: { active: false } })}/>
        <Industries />
        <Companies />
      </main>
    )
  }
}
