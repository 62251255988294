import React from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {A11y, Navigation, Pagination, Scrollbar} from 'swiper'

export default class TextBlock extends React.Component {
    render() {
        SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])
        return (
            <section id="industries">
                <div className="grid">
                    <div className="row">
                        <div className="col">
                            <h3 className="ta-center">Industries</h3>
                            <p className="ta-center">
                                Unlock the full potential of your users' experience with tailor-made solutions
                            </p>
                        </div>
                        <div className="col">
                            <div className="swiper-container industries-swiper">
                                <Swiper
                                    spaceBetween={18}
                                    navigation
                                    pagination={{ el: '.swiper-pagination', clickable: true }}
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1,
                                            spaceBetween: 20
                                        },
                                        670: {
                                            slidesPerView: 2,
                                            spaceBetween: 20
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 20
                                        }
                                    }}
                                >
                                    <SwiperSlide>
                                        <a className="industries__item" href="/gaming" style={{ backgroundColor: '#4DD970' }}>
                                            <div className="industries__item__img industries__item__img__gaming"/>
                                            <div className="industries__item__text">
                                                <div className="text">
                                                    <h4 className="title">Gaming</h4>
                                                    <p className="description">
                                                        More than a half of new gaming sign-ups are young players.
                                                        So, if you trust reference agencies, you are missing out!
                                                        Credit records of youth are often short or non-existant.
                                                        Onboard 40% more customers with accurate document verification.
                                                    </p>
                                                </div>
                                                <span className="link">Learn more</span>
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <a className="industries__item" href="/fintech" style={{backgroundColor: '#F2796D'}}>
                                            <div className="industries__item__img industries__item__img__fintech"/>
                                            <div className="industries__item__text">
                                                <div className="text">
                                                    <h4 className="title">FinTechs</h4>
                                                    <p className="description">
                                                        When you use Mark ID, onboarding for individuals,
                                                        AML, crypto compliance and more — the checks run automatically,
                                                        with reports driven right to your dashboard
                                                    </p>
                                                </div>
                                                <span className="link">Learn more</span>
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <a className="industries__item" href="/trading" style={{ backgroundColor: '#1B3E6F' }}>
                                            <div className="industries__item__img industries__item__img__trading"/>
                                            <div className="industries__item__text">
                                                <div className="text">
                                                    <h4 className="title">Trading</h4>
                                                    <p className="description">
                                                        Onboard more trade-ready customers, prevent fraud, and
                                                        provide for regulatory compliance in 220+ countries and territories.
                                                    </p>
                                                </div>
                                                <span className="link">Learn more</span>
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <a className="industries__item" href="/money-institutions" style={{ backgroundColor: '#3EB4DC' }}>
                                            <div className="industries__item__img industries__item__img__electronic_money"/>
                                            <div className="industries__item__text">
                                                <div className="text">
                                                    <h4 className="title">Electronic money institutions</h4>
                                                    <p className="description">
                                                        When onboarding new customers, use Simplified Identification first to speed up
                                                        the process and reduce the cost of screening new customers. We helped our customers
                                                        cut compliance costs up to 5x. Discover how it will work for you!
                                                    </p>
                                                </div>
                                                <span className="link">Learn more</span>
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <a className="industries__item" href="/service-providers" style={{ backgroundColor: '#8E8CD8'}}>
                                            <div className="industries__item__img industries__item__img__industries" />
                                            <div className="industries__item__text">
                                                <div className="text">
                                                    <h4 className="title">Payment service providers</h4>
                                                    <p className="description">
                                                        Expand your global business. Mark ID will rule out fake identities and take
                                                        care of compliance: the first impression of your company will always be the brightest.
                                                    </p>
                                                </div>
                                                <span className="link">Learn more</span>
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                </Swiper>
                                <div className="swiper-pagination"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}