import React, {Component} from 'react'
import Navigation from '~/js/components/Web/LandingComponents/Navigation'
import {handleTopHeaderStyles} from '~/js/utils/IndexFunctions'
import MobileNav from '~/js/components/Web/LandingComponents/MobileNav'
import GraphicElementGreen from '~/img/hero/graphic-element-green.svg'

export default class NotFound extends Component {
  constructor(props) {
    super(props)

    this.header = React.createRef()
  }

  componentDidMount() {
    this.handleTopHeaderStyles()
  }

  handleTopHeaderStyles() {
    handleTopHeaderStyles(this.header.current)
  }

  render() {
    return <div id="page-container">
      <MobileNav/>
      <header className="dark" ref={this.header}>
        <Navigation/>
      </header>
      <main>
        <section id="page-not-found">
          <div className="intro__el">
            <img src={GraphicElementGreen} alt=""/>
          </div>
          <div className="grid">
            <div className="row row--hor-center ta-center">
              <div className="col lg-6-12">
                <h1>404</h1>
                <p>Sorry, the page could not be found.</p>
                <a className="btn btn--primary" href="/">Back to homepage</a>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  }
}
