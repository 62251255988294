export const handleTopHeaderStyles = header => {
    if (!header) {
        return
    }

    window.addEventListener('load', () => {
        if (window.pageYOffset >= 100) {
            header.classList.add('active');
            if (header.classList.contains('light')) {
                header.classList.remove('light');
            }
        } else {
            header.classList.remove('active');
            if (!header.classList.contains('dark')) {
                header.classList.add('light');
            }
        }
    })
    window.addEventListener('scroll', () => {
        if (window.pageYOffset >= 100) {
            header.classList.add('active');
            if (header.classList.contains('light')) {
                header.classList.remove('light');
            }
        } else {
            header.classList.remove('active');
            if (!header.classList.contains('dark')) {
                header.classList.add('light');
            }
        }
    })
}