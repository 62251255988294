import React, { Component } from 'react'
import GreenGraphicElement from '~/img/hero/graphic-element-green.svg'
import Companies from '~/js/components/Web/LandingComponents/Companies'
import MapIcon from '~/img/illustration/map.svg'
import { Link } from 'react-scroll'

export default class IdentityVerification extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const showMoreLinks = document.querySelectorAll('.show-more')
        showMoreLinks.forEach(link => {
            link.addEventListener('click', () => {
                const itemColumnsDescription = link.previousElementSibling
                if (itemColumnsDescription.style.maxHeight) {
                    itemColumnsDescription.style.maxHeight = null
                    link.innerHTML = 'Read more'
                } else {
                    itemColumnsDescription.style.maxHeight = itemColumnsDescription.scrollHeight + "px"
                    link.innerHTML = 'Read less'
                }
            })
        })
        const itemColumnsDescriptions = document.querySelectorAll('.item__columns__description');
        itemColumnsDescriptions.forEach(description => {
            if (description.clientHeight < 120) {
                const showMoreLink = description.nextElementSibling
                showMoreLink.style.display = 'none'
            }
        })
    }

    render() {
        return (
            <main>
                <section className="intro--white" id="intro">
                    <div className="intro__el">
                        <img src={ GreenGraphicElement } alt="" />
                    </div>
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col lg-8-12 md-10-12 text__center">
                                <div className="intro__content platforms__text">
                                    <h1>Identity Verification</h1>
                                    <p className="ta-center">
                                        We are the first  to offer a hybrid solution to identity verification:
                                        every automated face identification is re-evaluated by a human KYC expert,
                                        which is covered by professional liability insurance guaranteed by Lloyd’s Insurance Group.
                                    </p>
                                    <img src={ MapIcon } alt="Identity Verification" />
                                </div>
                                <Link className="btn btn--primary" to="about" smooth={true}>Learn More</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="text-block" id="about">
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col">
                                <h2 className="section-title section-title--sm ta-center">How does our identity verification work?</h2>
                            </div>
                            <div className="col lg-10-12">
                                <p className="ta-center">
                                    We are the first in the market to offer a hybrid solution for identity
                                    verifications: automated face identification + human KYC expert’s review +
                                    professional liability insurance by Lloyd’s. All your customer needs are
                                    Internet access, a smart device (computer with a webcam, smartphone, tablet)
                                    and their valid ID document. First, the customer takes a picture of his/her
                                    face (either one simple photo or by turning his/her head right and left –
                                    liveness detection) and then a picture of his /her identity document
                                    (ID, passport, residence permit). All of this is completed in 60 seconds and
                                    the customer receives an initial verification, after which a human KYC
                                    expert reviews the photos and within a few moments provides final
                                    confirmation of the person’s identity. Our hybrid verification solution
                                    combines the best of machines and humans.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="list list--check list--check--circle">
                    <div className="grid">
                        <div className="row">
                            <div className="col">
                                <h2 className="ta-center">Benefits of our identity verification:</h2>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <p>
                                    We provide a state-of-the-art streamlined experience by offering an instant, simple, and fully digital identity verification that meets requirements for KYC and AML
                                </p>
                            </li>
                            <li>
                                <p>
                                    We dramatically reduce user friction and verification times as our identity verification is clear and quick
                                </p>
                            </li>
                            <li>
                                <p>
                                    We guarantee that the identity document is real and authentic by checking it against known document templates to ensure that all security checks (e.g. font types, watermarks, holograms etc.) are executed
                                </p>
                            </li>
                            <li>
                                <p>
                                    We perform two types of identity verification – one simple photo of a person’s face or a live check to verify that the person in a selfie is a real human being and can be compared to the photo in the ID provided
                                </p>
                            </li>
                            <li>
                                <p>
                                    We calculate a score based on how similar the two faces in the selfie and in the document are
                                </p>
                            </li>
                            <li>
                                <p>
                                    Our selfie isn’t just a user-friendly way to identity verification – our live detection also effectively protects against multiple ranges of potential fraud attacks
                                </p>
                            </li>
                            <li>
                                <p>
                                    We bring trust to the relationships you build through digital channels by offering real time, KYC and AML-compliant identity verification processes
                                </p>
                            </li>
                            <li>
                                <p>
                                    We maximize conversion rates by offering customer enrolment through digital channels, thus significantly improving user experience
                                </p>
                            </li>
                            <li>
                                <p>
                                    We increase companies’ ROI and reduce onboarding costs by 40% by eliminating manual interaction and the need to organize physical meetings
                                </p>
                            </li>
                            <li>
                                <p>
                                    Our face and document identification are used not only for identity verification but also for preventing fraud, mitigating risk, verifying age and other uses
                                </p>
                            </li>
                            <li>
                                <p>
                                    Our system provides a standardized set of fields that can be applied to all countries and territories
                                </p>
                            </li>
                        </ul>
                    </div>
                </section>
                <Companies />
            </main>
        )
    }
}
