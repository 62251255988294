import React, { Component } from 'react'
import ComputerMobile from '~/img/illustration/computer-mobile.svg'
import MapImage from '~/img/illustration/map.svg'
import People from '~/img/illustration/people.svg'
import Passport from '~/img/illustration/passport.svg'
import Companies from '~/js/components/Web/LandingComponents/Companies'
import { Link } from 'react-scroll'

export default class ServiceProviders extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <main>
                <section className="intro--color intro--color--purple" id="intro">
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col lg-8-12 md-10-12">
                                <div className="intro__content ta-center">
                                    <h3 className="section-title section-title--sm">Service providers</h3>
                                    <h1 className="ta-center">A modern approach to verification</h1>
                                    <p className="ta-center">
                                        Expand your global business. Mark ID will rule out fake identities and take
                                        care of compliance: the first impression of your company will always be the brightest.
                                    </p>
                                </div>
                            </div>
                            <div className="col lg-10-12">
                                <div className="ta-center">
                                    <Link className="btn btn--primary btn--primary--white" to="about" smooth={true}>Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="text-block text-block--lg" id="about">
                    <div className="grid">
                        <div className="row">
                            <div className="col">
                                <div className="text-block__list">
                                    <div className="text-block__list__item">
                                        <img src={ People } alt="People" />
                                        <div className="text">
                                            <h2>AML screening</h2>
                                            <p className="list-title">
                                                When compliance meets conversion
                                            </p>
                                            <div className="list list--icon">
                                                <ul>
                                                    <li>
                                                        Protect yourself from compliance  overhead and downside
                                                    </li>
                                                    <li>
                                                        Full toolkit: age, KYC, AML, fraud protection, and more
                                                    </li>
                                                    <li>
                                                        Drive higher conversions
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-block__list__item">
                                        <img src={ MapImage } alt="Map" />
                                        <div className="text">
                                            <h2>Bring your verifications under control</h2>
                                            <p>
                                                Forget about long discussions with your current vendor support.
                                                Re-check suspicious cases manually using our powerful AI-based toolkit.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-block__list__item">
                                        <img src={ Passport } alt="Passport" />
                                        <div className="text">
                                            <h2>IDs</h2>
                                            <p>
                                                Mark ID supports 6500+ types of documents from 220+ countries and territories
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-block__list__item">
                                        <img src={ ComputerMobile } alt="Computer and Mobile" />
                                        <div className="text">
                                            <h2>Flexible Integration</h2>
                                            <p>
                                                Integrate via mobileSDK, our iFrame widget or redirect API, with over 99.9% uptime
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blue-box" id="scroll-to">
                    <div className="grid">
                        <div className="blue-box__bg">
                            <div className="blue-box__list">
                                <div className="blue-box__list__item ta-center"><span className="title">24/7</span>
                                    <p className="description">Automated checks</p>
                                </div>
                                <div className="blue-box__list__item ta-center"><span className="title">6500+</span>
                                    <p className="description">Document types from 220+ countries</p>
                                </div>
                                <div className="blue-box__list__item ta-center"><span className="title">300s</span>
                                    <p className="description">Average manual KYC expert re-evaluation time</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Companies />
            </main>
        )
    }
}
