import React, { Component } from 'react'

export default class PrivacyPolicyLt extends Component {
  render() {
    return (
      <main>
        <section id="privacy-policy">
          <div className="grid">
            <div className="row row--hor-center">
              <div className="col lg-8-12 md-10-12">
               <article>
                 <h2 className="ta-center">PRIVATUMO POLITIKA</h2>
                 <p>Šioje privatumo politikoje (toliau - Privatumo politika) pateikiame informaciją apie tai, kokius Jūsų asmens duomenis, kokiu tikslu bei pagrindu tvarkome, kiek laiko saugome, kam galime perduoti informaciją apie Jus, ir kokias teises Jūs, kaip duomenų subjektas, turite.</p>
                 <h3>ASMENS DUOMENŲ VALDYTOJAS</h3>
                 <p>Šioje Privatumo politikoje aprašytų asmens duomenų tvarkymo operacijų valdytojas, nustatantis duomenų tvarkymo tikslus ir priemones, yra UAB Mark ID (toliau - Mes, Bendrovė arba Duomenų valdytojas), juridinio asmens kodas 305098955, adresas Žalgirio g. 90-100, Vilnius, elektroninio pašto adresas info@markid.lt, interneto svetainė <a href="http://www.markid.eu/">www.markid.eu</a>.</p>
                 <p>Atkreipkite dėmesį, kad šioje Privatumo politikoje taip pat rasite informacijos apie mūsų, kaip duomenų tvarkytojo, klientų vardu vykdomą duomenų tvarkymo veiklą, kai Bendrovė nėra Jūsų asmens duomenų valdytojas. Mes tik teikiame paslaugas savo klientams kaip duomenų tvarkytojas, vykdydami jų nurodymus. Šioje Privatumo politikoje rasite pagrindinę informaciją apie mūsų duomenų tvarkymo veiklą Mūsų klientų vardu, įskaitant mūsų tvarkomų asmens duomenų kategorijas. Norime pabrėžti, kad tokiais atvejais mes savarankiškai nenustatome duomenų tvarkymo tikslų ar priemonių ir nekontroliuojame klientų mums pateiktų asmens duomenų. Jei turite klausimų ar nuogąstavimų dėl savo asmens duomenų tvarkymo arba norite pasinaudoti savo duomenų subjekto teisėmis, raginame tiesiogiai kreiptis į atitinkamą duomenų valdytoją.</p>
                 <h3>DUOMENŲ APSAUGOS PAREIGŪNAS</h3>
                 <p>Bendrovėje yra paskirtas duomenų apsaugos pareigūnas.</p>
                 <p>Jeigu norėtumėte pasitikslinti ar sužinoti, kaip Mes tvarkome Jūsų asmens duomenis, jei ketinate pasinaudoti savo, kaip duomenų subjekto, teisėmis, galite susisiekti su Bendrovėje paskirtu duomenų apsaugos pareigūnu, elektroniniu paštu: dap@juridicon.lt.</p>
                 <h3>PRIVATUMO POLITIKOS TIKSLAS IR JOS TAIKYMAS</h3>
                 <p>Šioje Privatumo politikoje Mes pateikiame Jums informaciją apie tai, kaip Bendrovė tvarko Jūsų asmens duomenis. Papildoma informacija gali būti pateikta atskiruose pranešimuose, paslaugų bei kitose sutartyse, kurias Jūs sudarysite(-ėte) su Bendrove.</p>
                 <p>Šioje Privatumo politikoje neaptariama, kaip Bendrovė tvarko asmens duomenis Jums lankantis kitose Bendrovės svetainėse, naudojantis kitose svetainėse Bendrovės viešinamomis ar per jas teikiamomis paslaugomis. Jei Jūs lankotės kitose Bendrovės svetainėse, naudojatės tokiose svetainėse Bendrovės viešinamomis ar per jas teikiamomis paslaugomis, Jūsų asmens duomenys bus tvarkomi pagal tose svetainėse nurodytą informaciją. Tokiu atveju prašome tinkamai susipažinti su kitose Bendrovės svetainėse skelbiama privatumo politika.</p>
                 <p>Asmens duomenų tvarkymą Bendrovėje reglamentuoja Lietuvos Respublikos asmens duomenų teisinės apsaugos įstatymas, Europos Parlamento ir Tarybos 2016 m. balandžio 27 d. reglamentą (ES) 2016/679 dėl fizinių asmenų apsaugos tvarkant asmens duomenis ir dėl laisvo tokių duomenų judėjimo ir kuriuo panaikinama Direktyva 95/46/EB (Bendrasis duomenų apsaugos reglamentas)(toliau &ndash; BDAR arba Reglamentas) ir kiti teisės aktai bei Bendrovės vidiniai dokumentai bei tvarkos.</p>
                 <p>Šioje Privatumo politikoje naudojamos sąvokos (pvz. duomenų valdytojas, duomenų tvarkytojas) suprantamos taip, kaip jos apibrėžtos ir aiškinamos Reglamente.</p>
                 <h3>ASMENS DUOMENŲ ŠALTINIAI</h3>
                 <p>Bendrovės tvarkomi asmens duomenys gali būti gaunami iš šių šaltinių:</p>
                 <ul className="privacy-list-bullet">
                   <li>
                     <p>tiesiogiai iš duomenų subjekto (pvz., naudojatės Mūsų internetine svetaine www.markid.eu);</p>
                   </li>
                   <li>
                     <p>iš trečiųjų šalių (pvz., pateikiate savo asmens duomenis darbo paieškų portalus administruojančioms bendrovėms);</p>
                   </li>
                   <li>
                     <p>netiesiogiai iš jūsų (pvz., iš asmenų, kuriems atstovaujate).</p>
                   </li>
                 </ul>
                 <p>Informuojame, kad Jūs neprivalote pateikti jokių asmens duomenų, tačiau gali būti, kad dėl to negalėsime Jums suteikti paslaugų ir pasiekti kitų nustatytų tikslų.</p>
                 <h3>ASMENS DUOMENŲ TVARKYMO TIKSLAI, KATEGORIJOS, PAGRINDAI, SAUGOJIMO TERMINAI IR GAVĖJAI</h3>

                 <h4>ASMENS DUOMENŲ TVARKYMO OPERACIJOS, KAI BENDROVĖ VEIKIA KAIP DUOMENŲ TVARKYTOJAS</h4>
                 <p>Kaip duomenų tvarkytojas, mes renkame ir tvarkome asmens duomenis savo klientų vardu, kai jiems teikiame nuotolinės asmenų identifikacijos bei patikros politiškai pažeidžiamų asmenų ir finansinių sankcijų duomenų bazėse paslaugas.</p>
                 <p>Jei turite klausimų ar neaiškumų dėl jūsų asmens duomenų tvarkymo arba norite pasinaudoti savo, kaip duomenų subjekto teisėmis, susisiekite tiesiogiai su atitinkamu duomenų valdytoju. Jūsų klausimus ir prašymus dėl savo teisių įgyvendinimo perduosime atitinkamiems duomenų valdytojams, kad būtų atlikti tolesni veiksmai.</p>

                 <h4>Nuotolinės tapatybės identifikacijos paslaugos teikimas</h4>
                 <p>Veikdami kaip duomenų tvarkytojas ir teikdami nuotolinės tapatybės identifikacijos paslaugas, duomenų valdytojo nurodymais tvarkome identifikuojamų asmenų asmens duomenis. Renkamų asmens duomenų rūšis gali skirtis priklausomai nuo klientams teikiamų paslaugų ir iš jų gaunamų nurodymų. Tai gali būti, be kita ko, toliau išvardytos asmens duomenų kategorijos: vardas, pavardė, asmens kodas, gimimo data, veido nuotrauka, asmens tapatybę patvirtinančio dokumento nuotrauka, paso viršelio nuotrauka, asmens dokumento antros pusės nuotrauka, IP adresas, dokumento galiojimo data, dokumento tipas, dokumento numeris, nuotraukos užfiksavimo data, vaizdo įrašas, vartotojo operacinė sistema, IP adresas. Šiuo tikslu tvarkomi ir specialiųjų kategorijų asmens duomenys: Jūsų atvaizdas, veido atpažinimas (biometriniai duomenys).</p>
                 <p>Šių duomenų tvarkymo teisinį pagrindą nustato duomenų valdytojas, t.&nbsp;y. Bendrovės klientas, kurio paslaugomis Jūs naudojatės.</p>
                 <p>Šių duomenų saugojimo terminą nustato duomenų valdytojas. Klientų vardu tvarkomus asmens duomenis saugome tiek laiko, kiek reikia tikslui, dėl kurio jie buvo surinkti, įvykdyti arba kiek to reikalauja duomenų valdytojas ir/ arba teisės aktai.</p>

                 <h4>Asmenų patikros politiškai pažeidžiamų asmenų ir finansinių sankcijų duomenų bazėse paslaugos teikimas</h4>
                 <p>Veikdami kaip duomenų tvarkytojas ir teikdami asmenų patikros politiškai pažeidžiamų asmenų ir finansinių sankcijų duomenų tikrinimą duomenų bazėse, duomenų valdytojo nurodymais tvarkome tikrinamų asmenų asmens duomenis. Renkamų asmens duomenų rūšis gali skirtis priklausomai nuo klientams teikiamų paslaugų ir iš jų gaunamų nurodymų. Tai gali būti, be kita ko, toliau išvardytos asmens duomenų kategorijos: vardas, pavardė, gimimo data, šalis, politinė partija, taikomos sankcijos.</p>
                 <p>Šių duomenų tvarkymo teisinį pagrindą nustato duomenų valdytojas, t.&nbsp;y. Bendrovės klientas, kurio paslaugomis Jūs naudojatės.</p>
                 <p>Šių duomenų saugojimo terminą nustato duomenų valdytojas. Klientų vardu tvarkomus asmens duomenis saugome tiek laiko, kiek reikia tikslui, dėl kurio jie buvo surinkti, įvykdyti arba kiek to reikalauja Duomenų valdytojas ir/ arba teisės aktai.</p>

                 <h4>ASMENS DUOMENŲ TVARKYMO OPERACIJOS, KAI BENDROVĖ VEIKIA KAIP DUOMENŲ VALDYTOJAS</h4>
                 <h4>Potencialių darbuotojų paieška ir atranka</h4>
                 <p>Siekdami vykdyti darbuotojų paiešką į atviras darbo pozicijas Bendrovėje, galime tvarkyti šiuos kandidatų į atviras darbo pozicijas asmens duomenis: vardas, pavardė, gimimo data, telefono numeris, asmeninio el. pašto adresas, gyvenimo aprašymas (CV), duomenys apie kvalifikaciją bei kiti įdarbinimo tikslu reikalinga informacija, testų rezultatai, atrankos pokalbio įvertinimas.</p>
                 <p>Duomenų tvarkymo teisinis pagrindas: tvarkyti duomenis būtina siekiant įvykdyti sutartį arba siekiant imtis veiksmų prieš sudarant sutartį (Reglamento 6 str. 1 d. b p.).</p>
                 <p>Duomenų saugojimo terminas: duomenys ištrinami pasibaigus atrankai į konkrečią darbo poziciją.</p>

                 <h4>Sutarčių sudarymas ir vykdymas</h4>
                 <p>Siekdami sudaryti bei vykdyti paslaugų, pirkimo - pardavimo ar bet kokias kitas sutartis, galime tvarkyti šiuos klientų, kitų sutarties šalių asmens duomenis: vardas, pavardė, telefono numeris, adresas, el. pašto adresas, pareigos, paslaugos gavėjo ir paslaugos teikėjo interesais vykdomas susirašinėjimas, sutarties turinyje esantys duomenys, parašas. Jei sutartis sudaroma su juridiniu asmeniu, papildomai galime tvarkyti įgaliojimo veikti juridinio asmens vardu asmens duomenis.</p>
                 <p>Duomenų tvarkymo teisinis pagrindas: tvarkyti duomenis būtina siekiant įvykdyti sutartį arba siekiant imtis veiksmų prieš sudarant sutartį (jei sutartis sudaroma su fiziniu asmeniu) (Reglamento 6 str. 1 d. b p.), duomenų valdytojo teisėtas interesas tinkamai sudaryti ir vykdyti sutartis (jei sutartis sudaroma su juridiniu asmeniu) (Reglamento 6 str. 1 d. f p.).</p>
                 <p>Duomenų saugojimo terminas: 10 metų po sutarties pasibaigimo ir visiško įsipareigojimų įvykdymo.</p>

                 <h4>Mokėjimų už Bendrovės teikiamas paslaugas administravimas</h4>
                 <p>Siekdami administruoti mokėjimus už Bendrovės teikiamas paslaugas, galime tvarkyti šiuos klientų (fizinių asmenų) bei klientų atstovų (juridinių asmenų) asmens duomenis: vardas, pavardė, adresas, mokėjimo kortelių duomenys (vardas, pavardė, mokėjimo kortelės rūšis, kortelės numeris, CVV kodas, banko sąskaitos numeris), parašas, atstovavimo faktą pagrindžiantys duomenys (tik juridinių asmenų atstovų).</p>
                 <p>Duomenų tvarkymo teisinis pagrindas: tvarkyti duomenis būtina siekiant įvykdyti sutartį arba siekiant imtis veiksmų prieš sudarant sutartį (jei sutartis sudaroma su fiziniu asmeniu) (Reglamento 6 str. 1 d. b p.), duomenų valdytojo teisėtas interesas tinkamai sudaryti ir vykdyti sutartis (jei sutartis sudaroma su juridiniu asmeniu) (Reglamento 6 str. 1 d. f p.).</p>
                 <p>Duomenų saugojimo terminas: 10 metų po sutarties pasibaigimo ir visiško įsipareigojimų įvykdymo.</p>

                 <h4>Buhalterinė apskaita</h4>
                 <p>Siekdami vykdyti buhalterinę apskaitą, galime tvarkyti šiuos klientų, tiekėjų, pirkėjų - pardavėjų asmens duomenis: vardas, pavardė, asmens kodas, gyvenamosios vietos adresas, telefono numeris, el. pašto adresas, atsiskaitomosios sąskaitos numeris.</p>
                 <p>Duomenų tvarkymo teisinis pagrindas: duomenų valdytojo teisėtas interesas vykdyti buhalterinę apskaitą (Reglamento 6 str. 1 d. f p.) ir Duomenų valdytojui tenkanti teisinė prievolė (Reglamento 6 str. 1 d. c p.).</p>
                 <p>Duomenų saugojimo terminas: 10 metų po sutarties pasibaigimo ir visiško įsipareigojimų įvykdymo.</p>

                 <h4>Skolų išieškojimas</h4>
                 <p>Siekdami užtikrinti sutarčių laikymąsi ir išieškoti įsiskolinimus, galime tvarkyti šiuos skolininkų asmens duomenis: vardas, pavardė, kontaktinė informacija (telefono numeris, el. paštas), adresas, skolos informacija (suma, skolos atsiradimo faktą pagrindžiantys duomenys), asmens kodas, turtinė padėtis, informacija apie turimus šeimos narius.</p>
                 <p>Duomenų tvarkymo teisinis pagrindas: Duomenų valdytojo teisėtas interesas užtikrinti sutarčių vykdymą bei išieškoti įsiskolinimus (Reglamento 6 str. 1 d. f p.).</p>
                 <p>Duomenų saugojimo terminas: 10 metų po sutarties pasibaigimo ir visiško įsipareigojimų įvykdymo.</p>
                 <p>Duomenų gavėjai: duomenys gali būti perduoti skolų išieškojimo paslaugas teikiančioms bendrovėms.</p>

                 <h4>Virtualių susitikimų organizavimas ir vykdymas</h4>
                 <p>Siekdami organizuoti ir vykdyti konferencinius skambučius, virtualius susitikimus, vaizdo konferencijas ir (arba) internetinius seminarus, tvarkome šiuos virtualiuose susitikimuose dalyvaujančių asmenų asmens duomenis: informacija apie susitikimo dalyvius (vardas, pavardė, telefono numeris, el. pašto adresas, profilio nuotrauka, skyrius), duomenys apie susitikimą (tema, aprašymas, dalyvių IP adresas, įrenginio kompiuterinės techninės įrangos informacija, vaizdo konferencijos pradžios ir pabaigos laikas), teksto duomenys, balso ir vaizdo įrašai.</p>
                 <p>Duomenų tvarkymo teisinis pagrindas: Duomenų valdytojo teisėti interesai užtikrinti virtualių susitikimų organizavimą ir vykdymą, patogią bei saugią komunikaciją (Reglamento 6 str. 1 d. f p.) ir duomenų subjekto sutikimas (Reglamento 6 str. 1 d. f p.) (dėl virtualaus susitikimo įrašymo).</p>
                 <p>Duomenų saugojimo terminas: duomenys bus tvarkomi tol, kol jų reikės virtualiesiems susitikimams / susirinkimams organizuoti bei vykdyti ir susijusioms paslaugoms teikti.</p>

                 <h4>Informacijos apie Bendrovės akcininkus ir valdybos narius tvarkymas</h4>
                 <p>Siekdami tvarkyti duomenis apie Bendrovės akcininkus ir valdybos narius bei įgyvendinti Bendrovei tenkančias teisines prievoles, galime tvarkyti šiuos akcininkų, valdybos narių asmens duomenis: vardas, pavardė, asmens kodas, gyvenamosios vietos adresas, paskyrimo data, kontaktiniai duomenys, turimų akcijų skaičius.</p>
                 <p>Duomenų tvarkymo teisinis pagrindas: Duomenų valdytojui tenkanti teisinė prievolė (Reglamento 6 str. 1 d. c p.).</p>
                 <p>Duomenų saugojimo terminas: 10 metų nuo laikotarpio, kai duomenų subjektas, kurio duomenys buvo tvarkomi, nebėra Bendrovės akcininku ar valdybos nariu.</p>

                 <h4>Galutinių naudos gavėjų teikimas į registrą JANGIS</h4>
                 <p>Siekdami tvarkyti duomenis apie Bendrovės galutinius naudos gavėjus bei įgyvendinti tenkančias prievoles, galime tvarkyti šiuos galutinių naudos gavėjų asmens duomenis: vardas, pavardė, gimimo data, asmens kodas, valstybė, kuri išdavė asmens tapatybę patvirtinantį dokumentą, gyvenamoji vieta, turimos nuosavybės teisės ir apimtis (akcijų skaičių procentais, balsavimo teisių skaičių procentais) arba kitokios kontrolės teisės (valdybos pirmininkas, valdybos narys, vadovas, vyresnysis vadovas, kitos pareigos, perleistų balsavimo teisių skaičius procentais).</p>
                 <p>Duomenų tvarkymo teisinis pagrindas: Duomenų valdytojui tenkanti teisinė prievolė (Reglamento 6 str. 1 d. c p.).</p>
                 <p>Duomenų saugojimo terminas: 8 metus nuo informacijos apie naudos gavėjus išbraukimo iš Juridinių asmenų dalyvių informacinės sistemos dienos.</p>

                 <h4>Užklausų administravimas</h4>
                 <p>Siekdami administruoti Bendrovei pateiktas užklausas, paklausimus, galime tvarkyti šiuos užklausas pateikusių asmenų asmens duomenis: vardas, pavardė, kontaktinė informacija (telefono numeris, el. paštas), užklausoje pateikta informacija, parašas.</p>
                 <p>Duomenų tvarkymo teisinis pagrindas: Duomenų valdytojo teisėti interesai užtikrinti tinkamą užklausų administravimą (Reglamento 6 str. 1 d. f p.).</p>
                 <p>Duomenų saugojimo terminas: duomenų saugojimo terminas priklauso nuo užklausos rūšies. Konkretus terminas nustatomas vadovaujantis Lietuvos vyriausiojo archyvaro patvirtinta bendrųjų dokumentų terminų saugojimo rodykle.</p>

                 <h4>Tiesioginė rinkodara</h4>
                 <p>Siųsdami tiesioginės rinkodaros pranešimus bei kvietimus dalyvauti apklausoje, tvarkome šiuos asmenų, užsiprenumeravusių tiesioginės rinkodaros pranešimus asmens duomenis: vardas, pavardė, elektroninis paštas, atstovaujama įmonė (juridinių asmenų atstovų), informacija apie perskaitymo statistiką, LinkedIn profilis.</p>
                 <p>Duomenų tvarkymo teisinis pagrindas: duomenų subjekto sutikimas (Reglamento 6 str. 1 d. a p.) ir Duomenų valdytojo teisėti interesai teikti tiesioginės rinkodaros pasiūlymus (Reglamento 6 str. 1 d. f p.).</p>
                 <p>Duomenų saugojimo terminas: 5 metai.</p>

                 <h4>Interneto svetainės tobulinimas, lankytojų statistika, elektroninių paslaugų teikimas (slapukų naudojimas)</h4>
                 <p>Siekdami tobulinti internetinę svetainę, rinkti ir analizuoti interneto svetainės lankytojų statistiką ir užtikrinti tinkamą elektroninių paslaugų teikimą bei svetainės funkcionalumus, Bendrovės valdomose interneto svetainėse naudojami slapukai, įskiepiai ir panašios technologijos. Minėtu tikslu Bendrovė tvarko šiuos asmens duomenis: lankytojų kompiuterių naršyklė ir jos versija, kalbos pasirinkimas, regionas, naršymo laikas, nustatyta laiko zona, prisijungimo prie vartotojo paskyros naudojantis svetainėmis duomenys, kita, slapukais surinkta informacija.</p>
                 <p>Duomenų tvarkymo teisinis pagrindas: duomenų subjekto sutikimas (Reglamento 6 str. 1 d. a p.) (šiuo pagrindu tvarkomi neprivalomais slapukais surinkti asmens duomenys) ir duomenų valdytojo teisėtas interesas užtikrinti tinkamą svetainės veikimą bei funkcionalumų palaikymą, elektroninių paslaugų teikimą (Reglamento 6 str. 1 d. f p.) (šiuo pagrindu tvarkomi privalomais slapukais surinkti asmens duomenys).</p>
                 <p>Duomenų saugojimo terminas: asmens duomenų saugojimo terminas priklauso nuo konkretaus slapuko, kuriuo buvo surinkti asmens duomenys, tačiau, visais atvejais, saugojimo terminas nėra ilgesnis nei 2 metai.</p>

                 <h4>Klientų aptarnavimas svetainėje („Messenger“ live chat)</h4>
                 <p>Siekdami suteikti aktualią informaciją ir padėti atsakyti į klausimus, Bendrovės interneto svetainėje įdiegėme „live chat“ funkcionalumą ir tvarkome šiuos klientų ir potencialių klientų asmens duomenis: vardas, pavardė, šalies, kalbos bei regiono nustatymų pasirinkimas „Facebook“ platformoje, laiko zona.</p>
                 <p>Duomenų tvarkymo teisinis pagrindas: duomenų valdytojo teisėtas interesas aptarnauti klientų bei potencialių klientų užklausas (Reglamento 6 str. 1 d. f p.).</p>
                 <p>Duomenų saugojimo terminas: &lt;nustatyti&gt;.</p>
                 <p>Šiuo tikslu kartu su „Messenger“ valdytoju „Meta platforms“ veikiame kaip bendri duomenų valdytojai. Informaciją, kurią pateiksite Bendrovės valdomuose socialiniuose tinkluose, kontroliuoja socialinio tinklo valdytojas. Todėl rekomenduojame perskaityti socialinio tinklo valdytojo privatumo pranešimus.</p>

                 <h4>Klientų istorijų (atsiliepimų) rinkimas ir skelbimas interneto svetainėje</h4>
                 <p>Siekdami tvarkyti atsiliepimus (interneto svetainėje marksign.lt "Klientų istorijos"), tvarkome šiuos klientų ir klientų atstovų asmens duomenis: vardas, pavardė, darbovietė, pareigos, nuotrauka, atsiliepimo turinys.</p>
                 <p>Duomenų tvarkymo teisinis pagrindas: duomenų subjekto sutikimas (BDAR 6 str. 1 d. a p.).</p>
                 <p>Duomenų saugojimo terminas: nenustatytas.</p>
                 <p>Siekiame užtikrinti sąžiningą ir skaidrų vartotojų atsiliepimų tvarkymą, skelbiame tik tikrus atsiliepimus, kurie gauti iš tikrųjų paslaugų naudotojų. Visi atsiliepimai, tiek teigiami, tiek neigiami, yra skelbiami. Atsiliepimų turiniui neturėjo įtakos sutartiniai santykiai ar parama. </p>
                 <p>Palikdamas komentarą, atsiliepimo autorius patvirtina, kad:</p>
                 <ul className="privacy-list-bullet">
                   <li>
                     <p>teikia objektyvius atsiliepimus dėl konkrečių Interneto svetainėje įsigytų paslaugų;</p>
                   </li>
                   <li>
                     <p>atsiliepimuose nenaudos necenzūrinių žodžių ar vulgaraus, grasinančio, diskriminuojančio, kurstančio neapykantą, įžeidžiančio ar pažeidžiančio teisės aktų reikalavimus turinio;</p>
                   </li>
                   <li>
                     <p>atsiliepimų turinys nepažeidžia trečiųjų asmenų autorių teisių, patentų, prekių ženklų, prekybos paslapčių ar kitų nuosavybės teisių;</p>
                   </li>
                   <li>
                     <p>neskelbia turinio, pažeidžiančio bet kokius teisės aktus, įstatymus, reglamentus ir t. t.;</p>
                   </li>
                   <li>
                     <p>neskelbia reklaminio turinio arba turinio, už kurį jam buvo apmokėta ar kitaip atlyginta;</p>
                   </li>
                   <li>
                     <p>atsiliepimų turinyje nėra bet kokių asmens duomenų ar kitų svetainių duomenų (įskaitant, bet neapsiribojant, vardu, pavarde, asmens kodu, kontaktiniais duomenimis, el. svetainės adresu ir t.t.).</p>
                   </li>
                 </ul>
                 <p><strong>Atsiliepimo autorius yra atsakingas už atsiliepimo turinio atitiktį teisės aktų reikalavimams ir užtikrina, kad jo pateiktas turinys nepažeidžia trečiųjų asmenų teisių</strong></p>

                 <h3>SLAPUKAI</h3>
                 <p>Svetainėje naudojami slapukai (angl. cookies) &ndash; nedidelės tekstinės informacijos dalelės, kurios automatiškai sukuriamos naršant svetainėje ir yra saugomos kompiuteryje ar kitame galiniame įrenginyje. Slapukų surinkta informacija leidžia užtikrinti patogesnį naršymą svetainėje, teikti pasiūlymus bei daugiau sužinoti apie mūsų svetainių naudotojų elgesį, analizuoti tendencijas ir tobulinti svetainę. Jeigu Bendrovės svetainėje yra nuorodos į kitas svetaines, kurios taip pat naudoja slapukus, jie čia neapibūdinti.</p>
                 <p>Prieš rašydami funkcinius, stebėjimo, reklaminius slapukus ir (ar) trečiosios šalies slapukus, mes prašome Jūsų sutikimo. Kai įrašomi būtinieji slapukai, juos naudojame teisėto intereso pagrindu ir Jūsų sutikimo dėl tokių slapukų įrašymo neprašome. Jei Jūsų sutikimą jau gavome, ateityje pakartotinai naudojant tą patį slapuką tuo pačiu tikslu sutikimo dar kartą neprašysime. Tai galioja ir trečiųjų šalių naudojamų slapukų atžvilgiu.</p>
                 <p>Duotą sutikimą dėl nebūtinųjų slapukų naudojimo bet kada galima atšaukti pakeitus interneto naršyklės nustatymus, išjungus visus slapukus arba išjungus (įjungus) juos po vieną. Atkreipkite dėmesį, kad kai kuriais atvejais tai gali sulėtinti naršymo internete spartą, apriboti tam tikrų interneto svetainių funkcijų veikimą arba blokuoti prieigą prie svetainės. Norint nustatyti reikiamas (norimas) slapukų pasirinktis, rekomenduotina naudotis slapukų pranešime arba interneto naršyklėse esančiomis funkcijomis.</p>
                 <p>Interneto svetainėse naudojame šių rūšių slapukus:</p>
                 <p><strong>Būtinieji slapukai</strong>. Būtini tam, kad galėtumėte naudotis svetainės funkcijomis, prisijungti prie vartotojo paskyros. Be šių slapukų negalėtumėte naudotis mūsų teikiamomis elektroninėmis paslaugomis.</p>
                 <p><strong>Veikimą gerinantys (sesijos) slapukai</strong>. Jie skirti pagerinti svetainės veikimą ir renka bendro pobūdžio anoniminę informaciją apie naudojimąsi interneto svetaine.</p>
                 <p><strong>Stebėjimo slapukai</strong> (stebėjimo slapukai iš &bdquo;Google Analytics&ldquo;). Leidžia Bendrovei atpažinti ir suskaičiuoti svetainės lankytojus bei stebėti, kaip lankytojai juda po interneto svetainę ja naudodamiesi. Tai padeda pagerinti svetainės veikimą, pavyzdžiui, užtikrinti, kad vartotojai galėtų lengvai rasti tai, ko ieško.</p>
                 <p><strong>Funkciniai slapukai.</strong> Naudojami svetainės lankytojams atpažinti, kai jie grįžta į interneto svetainę. Tai leidžia Bendrovei pateikti pagal lankytojų poreikius pritaikytą turinį socialiniuose tinkluose, įsiminti lankytojams aktualią informaciją.</p>
                 <p><strong>Reklaminiai slapukai</strong>. Naudojami tam, kad rodytų reklaminius skelbimus, kurie turėtų būti įdomūs būtent jums ir atitikti jūsų interesus. Jie gali būti naudojami ir tam, kad padėtų matuoti reklaminės kampanijos efektyvumą.</p>
                 <h3>Svetainėje naudojami slapukai</h3>
                 <div className="cookies-details">
                   <table className="privacy_cookies_table">
                     <tbody>
                     <tr>
                       <td >
                         <p>Slapuko pavadinimas</p>
                       </td>
                       <td >
                         <p>Slapuko tiekėjas</p>
                       </td>
                       <td >
                         <p>Paskirtis</p>
                       </td>
                       <td >
                         <p>Duomenų saugojimo terminas</p>
                       </td>
                       <td >
                         <p>Slapuko kategorija</p>
                       </td>
                     </tr>
                     <tr>
                       <td >
                         <p>_ga</p>
                       </td>
                       <td >
                         <p>Google</p>
                       </td>
                       <td >
                         <p>Užregistruoja unikalų ID, kuris yra reikalingas sugeneruoti statistinius duomenis kaip Duomenų subjektas naudojasi Svetaine.</p>
                       </td>
                       <td >
                         <p>1 metai 1 mėnuo</p>
                       </td>
                       <td >
                         <p>Statistikos</p>
                       </td>
                     </tr>
                     <tr>
                       <td >
                         <p>_ga_#</p>
                       </td>
                       <td >&nbsp;</td>
                       <td >
                         <p>Naudojama Google Analytics tam, kad būtų registruojama, kiek kartų naudotojas apsilankė svetainėje.</p>
                       </td>
                       <td >
                         <p>1 metai 1 mėnuo</p>
                       </td>
                       <td >
                         <p>Statistikos</p>
                       </td>
                     </tr>
                     <tr>
                       <td >
                         <p>_gid</p>
                       </td>
                       <td >
                         <p>Google</p>
                       </td>
                       <td >
                         <p>Užregistruoja unikalų ID, kuris yra reikalingas sugeneruoti statistinius duomenis kaip Duomenų subjektas naudojasi Svetaine.</p>
                       </td>
                       <td >
                         <p>1 diena</p>
                       </td>
                       <td >
                         <p>Statistikos</p>
                       </td>
                     </tr>
                     <tr>
                       <td >
                         <p>_gat</p>
                       </td>
                       <td>
                         <p>Google</p>
                       </td>
                       <td >
                         <p>Naudojama Google Analytics tam, kad būtų kontroliuojamas įrašomų duomenų kiekis didelio srauto svetainėse.</p>
                       </td>
                       <td>
                         <p>1 diena</p>
                       </td>
                       <td >
                         <p>Statistikos</p>
                       </td>
                     </tr>
                     <tr>
                       <td >
                         <p>_dc_gtm_UA-#</p>
                       </td>
                       <td >
                         <p>Google</p>
                       </td>
                       <td >
                         <p>Šis slapukas susietas su svetainėmis, naudojančiomis Google Tag Manager, kad į puslapį būtų įkelti kiti scenarijai ir kodas. Kai jis naudojamas, jis gali būti laikomas griežtai būtinu, nes be jo kiti scenarijai gali tinkamai neveikti.</p>
                       </td>
                       <td >
                         <p>1 diena</p>
                       </td>
                       <td >
                         <p>Statistikos</p>
                       </td>
                     </tr>
                     <tr>
                       <td >
                         <p>_hjAbsoluteSessionInProgress</p>
                       </td>
                       <td >
                         <p>HotJar</p>
                       </td>
                       <td >
                         <p>Šis slapukas yra naudojamas suskaičiuoti kiek kartų Svetainė buvo aplankyta skirtingų Duomenų subjektų &ndash; tai yra atliekama suteikiant Duomenų subjektui ID, tam, kad jis nebūtų užregistruotas du kartus.</p>
                       </td>
                       <td >
                         <p>1 diena</p>
                       </td>
                       <td >
                         <p>Statistikos</p>
                       </td>
                     </tr>
                     <tr>
                       <td >
                         <p>_hjFirstSeen</p>
                       </td>
                       <td >
                         <p>HotJar</p>
                       </td>
                       <td >
                         <p>Šis slapukas yra naudojamas nustatyti ar Duomenų subjektas lankėsi Svetainėje anksčiau ar tai yra naujas lankytojas.</p>
                       </td>
                       <td >
                         <p>1 diena</p>
                       </td>
                       <td >
                         <p>Statistikos</p>
                       </td>
                     </tr>
                     <tr>
                       <td >
                         <p>_hjIncludedInSessionSample_#</p>
                       </td>
                       <td >
                         <p>HotJar</p>
                       </td>
                       <td >
                         <p>Nustato ar Duomenų subjekto navigacija turėtų būti užregistruota tam tikroje statistinėje vietoje.</p>
                       </td>
                       <td >
                         <p>1 diena</p>
                       </td>
                       <td >
                         <p>Statistikos</p>
                       </td>
                     </tr>
                     <tr>
                       <td >
                         <p>_hjSession_#</p>
                       </td>
                       <td >
                         <p>HotJar</p>
                       </td>
                       <td >
                         <p>Šis slapukas yra naudojamas rinkti statistiką apie lankytojo apsilankymą svetainėje, pavyzdžiui, apsilankymų skaičių, praleistą laiką.</p>
                       </td>
                       <td >
                         <p>1 diena</p>
                       </td>
                       <td >
                         <p>Statistikos</p>
                       </td>
                     </tr>
                     <tr>
                       <td >
                         <p>_hjSessionUser_#</p>
                       </td>
                       <td >
                         <p>HotJar</p>
                       </td>
                       <td >
                         <p>Šis slapukas yra naudojamas rinkti statistiką apie lankytojo apsilankymą svetainėje, pavyzdžiui, apsilankymų skaičių, praleistą laiką.</p>
                       </td>
                       <td >
                         <p>1 metai</p>
                       </td>
                       <td >
                         <p>Statistikos</p>
                       </td>
                     </tr>
                     <tr>
                       <td >
                         <p>_hjCookieTest</p>
                       </td>
                       <td >
                         <p>HotJar</p>
                       </td>
                       <td >
                         <p>Registruoja statistinius duomenis apie Duomenų subjekto elgesį Svetainėje. Šiuos duomenis naudoja Svetainės operatorius vidaus analizei.</p>
                       </td>
                       <td >
                         <p>1 sesija</p>
                       </td>
                       <td >
                         <p>Statistikos</p>
                       </td>
                     </tr>
                     <tr>
                       <td >
                         <p>collect</p>
                       </td>
                       <td >
                         <p>Google</p>
                       </td>
                       <td >
                         <p>Naudojama tam, kad duomenys apie Duomenų subjekto elgesį ir naudojamą įrenginį, būtų išsiunčiami Google Analytics. Taip pat Duomenų subjektas yra sekamas, naudojantis skirtingais įrenginiais, bei reklaminiais kanalais.</p>
                       </td>
                       <td >
                         <p>1 sesija</p>
                       </td>
                       <td >
                         <p>Statistikos</p>
                       </td>
                     </tr>
                     </tbody>
                   </table>
                 </div>

                 <h3>ĮSKIEPIAI</h3>
                 <p>Bendrovės valdomose interneto svetainėse naudojami įskiepiai (angl. &bdquo;social plugins&ldquo;). Įskiepiai įdiegti į interneto svetainę siekiant sudaryti galimybę interneto svetainės vartotojus nukreipti į socialiniuose tinkluose esančias Bendrovės paskyras ar Bendrovės susirašinėjimo langą komunikacijos platformose.</p>
                 <p>Paspaudę ant įskiepių piktogramos, esate nukreipiami į įskiepių valdytojo puslapį ir įskiepio valdytojui pateikiami duomenys, iš kokio puslapio buvo atlikta užklausa, užklausos laikas ir data. Įskiepiai yra atpažįstami iš &bdquo;Facebook&ldquo;, &bdquo;X&ldquo; ir &bdquo;LinkedIn&ldquo; logotipų.</p>
                 <p>Informaciją, kurią asmuo pateikia įskiepio valdytojo puslapyje ar kuri gaunama asmeniui apsilankius Bendrovės internetiniame puslapyje esančių įskiepių nuorodose, kontroliuoja įskiepių valdytojai. Informacija, apie renkamus ir saugomus asmens duomenis, duomenų tvarkymo teisinius pagrindus, duomenų saugojimo terminus, taikomas technines ir organizacines saugumo priemones pateikiama įskiepių valdytojų privatumo pranešimuose.</p>

                 <h3>ASMENSDUOMENŲ TVARKYMAS SOCIALINIUOSE TINKLUOSE</h3>
                 <p>Bendrovės žinomumo skatinimas (socialinių tinklų administravimas)</p>
                 <p>Informaciją, kurią pateiksite Bendrovės valdomuose socialinių tinklų profiliuose (įskaitant pranešimus, laukelių &bdquo;Like&ldquo; ir &bdquo;Follow&ldquo; naudojimą, bei kitą komunikaciją) ar kuri gaunama Jums aplankius Bendrovės paskyrose (įskaitant informaciją gaunamą socialinių tinklų valdytojų naudojamų slapukų pagalba), kontroliuoja socialinio tinklo valdytojas. Todėl rekomenduojame perskaityti socialinio tinklo valdytojo privatumo pranešimus.</p>
                 <p>Kaip socialinių tinklų paskyros administratorius mes parenkame atitinkamus nustatymus, atsižvelgdami į savo tikslinę auditoriją ir į veiklos valdymo ir skatinimo tikslus. Socialinio tinklo valdytojas Bendrovei suteikdamas galimybę sukurti paskyrą socialiniame tinkle ir ją administruoti gali būti apribojęs galimybę keisti tam tikrus esminius nustatymus ir tokiu būdu mes negalime daryti įtakos, kokią informaciją apie Jus rinks socialinio tinklo valdytojas Bendrovei sukūrus paskyrą socialiniame tinkle.</p>
                 <p>Visi tokie nustatymai gali turėti įtakos asmens duomenų tvarkymui Jums naudojantis socialinės žiniasklaidos priemonėmis, apsilankant Bendrovės paskyroje ar skaitant Bendrovės pranešimus socialinės žiniasklaidos tinkle. Net jei tik žiūrite į mūsų pranešimus &bdquo;Facebook&ldquo;, &bdquo;LinkedIn&ldquo;, &bdquo;Instagram&ldquo; ar &bdquo;YouTube&ldquo;, socialinio tinklo valdytojas gali gauti tam tikrą asmeninę informaciją, pvz., kokiu galiniu įrenginiu naudojatės, koks Jūsų IP adresas ir kt.</p>
                 <p>Paprastai socialinio tinklo valdytojas Jūsų asmens duomenis (net ir tuos, kurie surinkti mums pasirinkus papildomus paskyros nustatymus) tvarko socialinio tinklo valdytojo nustatytais tikslais, remdamasis socialinio tinklo valdytojo privatumo politika. Tačiau Jums naudojantis socialiniu tinklu, bendraujant su Bendrove per socialinį tinklą, apsilankant Bendrovės paskyroje socialiniame tinkle ar stebint Bendrovės įrašus jame, Bendrovė gauna informaciją apie Jus. Gaunamų duomenų apimtis priklauso nuo mūsų parinktų paskyros nustatymų, susitarimų su socialinio tinklo valdytoju dėl papildomų paslaugų užsakymo, socialinio tinklo valdytojo nustatytų slapukų.</p>
                 <p>Duomenų tvarkymo teisinis pagrindas: BDAR 6 straipsnio 1 dalies f punktas (tvarkyti duomenis būtina siekiant teisėtų Bendrovės interesų užtikrinti verslo vystymą).</p>

                 <h3>ASMENS DUOMENŲ GAVĖJAI IR DUOMENŲ TVARKYTOJAI</h3>
                 <p>Jūsų asmens duomenis, atsižvelgdami į duomenų teikimo pagrindą ir užtikrinę perduodamų duomenų saugumą, galime teikti šiems asmenims:</p>
                 <ul className="privacy-list-bullet">
                   <li>
                     <p>Bendrovės pasirinktiems asmens duomenų tvarkytojams, su tikslu, kad Bendrovės vardu ir/arba jos nurodymu būtų atlikti teisėti asmens duomenų tvarkymo veiksmai;</p>
                   </li>
                   <li>
                     <p>skolų išieškojimo bendrovėms;</p>
                   </li>
                   <li>
                     <p>kitiems asmenims (advokatams, konsultantams, notarų, antstolių kontoroms, auditoriams ir pan.), kuriuos Bendrovė pasitelkia Bendrovei ir/ar Jums būtinų paslaugų teikimui;</p>
                   </li>
                   <li>
                     <p>verslo partneriams;</p>
                   </li>
                   <li>
                     <p>VĮ Registrų centras;</p>
                   </li>
                   <li>
                     <p>socialinių tinklų valdytojams;</p>
                   </li>
                   <li>
                     <p>valstybės institucijoms, teisėsaugos įstaigoms bei kitiems asmenims Lietuvos Respublikos teisės aktų nustatyta tvarka ar kuomet duomenų teikimas būtinas siekiant pareikšti, vykdyti ar apginti Bendrovės teisinius reikalavimus.</p>
                   </li>
                 </ul>
                 <p>Gavę Jūsų sutikimą asmens duomenis galime teikti ir kitiems asmenims.</p>
                 <p>Jeigu Jūsų asmens duomenis atskleisime kitoms duomenų gavėjų grupėms nei nurodyta šioje Privatumo politikoje apie tai Jus informuosime ne vėliau kaip atskleidžiant duomenis pirmą kartą, išskyrus, jei tokią informaciją jau būsime pateikę Jums anksčiau.</p>
                 <p>Galime pasitelkti duomenų tvarkytojus, kurie pagal mūsų nurodymus ir mūsų nustatyta apimtimi tvarkys asmens duomenis tiek, kiek tai yra būtina duomenų tvarkymo tikslams pasiekti. Pasitelkdami duomenų tvarkytojus, siekiame užtikrinti, kad ir duomenų tvarkytojai būtų įgyvendinę tinkamas organizacines ir technines saugumą užtikrinančias priemones bei išlaikytų asmens duomenų paslaptį.</p>

                 <h3>ASMENS DUOMENŲ PERDAVIMAS UŽ ES / EEE RIBŲ</h3>
                 <p>Jūsų duomenys gali būti perduoti už Europos Sąjungos ribų, su duomenų tvarkytojais pasirašius Europos Sąjungos teisės aktų reikalavimus atitinkančias sutartis.</p>
                 <p>Duomenys gali būti perduoti už Europos Sąjungos ribų, kai perdavimas yra būtinas sutarčių sudarymui ir vykdymui, tinkamam paslaugų suteikimui Bendrovės klientams. Tokiu atveju Bendrovė imasi veiksmų užtikrinti, kad bet koks asmens duomenų perdavimas už ES / EEE ribų būtų tinkamai vykdomas ir duomenų subjektų teisės į privatumą būtų maksimaliai apsaugotos. Bendrovė, perduodama asmens duomenis už ES/EEE ribų, vadovaujasi:</p>
                 <ul className="privacy-list-bullet">
                   <li>
                     <p>Europos Komisijos priimtu sprendimu dėl užsienio šalies tinkamumo;</p>
                   </li>
                   <li>
                     <p>užsienio šalyje patvirtinto sertifikavimo mechanizmo;</p>
                   </li>
                   <li>
                     <p>Europos Komisijos priimtu sprendimu dėl standartinių sutarčių sąlygų.</p>
                   </li>
                 </ul>
                 <p>Trečioji valstybė, nepriklausanti ES / EEE, kurioje yra asmens duomenų gavėjas, Europos Komisijos sprendimu privalo užtikrinti pakankamo lygio asmens duomenų apsaugą.</p>

                 <h3>PROFILIAVIMAS IR AUTOMATIZUOTAS SPRENDIMŲ PRIĖMIMAS</h3>
                 <p>Bendrovė nevykdo profiliavimo ir automatizuoto sprendimų priėmimo, dėl kurio Jums kiltų didelės pasekmės arba kuris darytų didelį poveikį Jums.</p>

                 <h3>DUOMENŲ SUBJEKTŲ TEISĖS</h3>
                 <p>Jūs, kaip duomenų subjektas, turite toliau šiame skyriuje nurodytas teises.</p>

                 <h4>Teisę žinoti (būti informuoti) apie savo asmens duomenų tvarkymą</h4>
                 <p>Kai asmens duomenis gauname tiesiogiai iš Jūsų, apie asmens duomenų tvarkymą Jus informuojame šių asmens duomenų gavimo metu žodžiu ir/arba raštu.</p>
                 <p>Jei asmens duomenis gausime ne tiesiogiai iš Jūsų, apie Jūsų asmens duomenų tvarkymą informuosime Jus per vieną mėnesį nuo duomenų gavimo, o jei Jūsų asmens duomenis naudosime ryšiams su Jumis palaikyti &ndash; ne vėliau kaip pirmą kartą susisiekiant su Jumis.</p>

                 <h4>Teisę susipažinti su savo asmens duomenimis, kuriuos mes tvarkome</h4>
                 <p>Jūs turite teisę pateikti prašymą Bendrovei dėl susipažinimo su Bendrovės tvarkomais asmens duomenimis. Per vieną mėnesį nuo prašymo gavimo, patikrinsime, ar Jūsų asmens duomenys yra tvarkomi Bendrovėje. Nustatę, kad Jūsų asmens duomenis tvarkome, pateiksime Jums informaciją apie tvarkomus asmens duomenis, jų saugojimo laikotarpį, informaciją iš kokių šaltinių ir kokie asmens duomenys surinkti, kokiu tikslu jie tvarkomi, kam teikiami ir pateiksime šių asmens duomenų kopiją.</p>
                 <p>Prireikus, laikotarpį atsakymui pateikti galime pratęsti. Apie tai Jus informuosime. Dėl tokio termino pratęsimo Jūs turite teisę teikti skundą Valstybinei duomenų apsaugos inspekcijai.</p>
                 <p>Vieną kartą per kalendorinius metus Jūsų duomenys gali būti teikiami neatlygintinai, tačiau kitais atvejais už duomenų teikimą Bendrovė gali nustatyti atlygį, neviršijantį duomenų teikimo sąnaudų.</p>
                 <p>Jei Jūsų prašymai yra akivaizdžiai nepagrįsti arba neproporcingi, turime teisę atsisakyti vykdyti Jūsų prašymą.</p>

                 <h4>Teisę reikalauti ištaisyti asmens duomenis</h4>
                 <p>Jūs turite teisę reikalauti ištaisyti netikslius, neteisingus ar neišsamius asmens duomenis.</p>
                 <p>Pranešime Jums, ar Jūsų prašomas asmens duomenų ištaisymas arba sunaikinimas buvo atliktas ar ne. Apie tai informuosime duomenų gavėjus, išskyrus, jei pateikti tokią informaciją būtų neįmanoma arba pernelyg sunku. Jūsų prašymu, pateiksime Jums informaciją apie tokius duomenų gavėjus.</p>

                 <h4>Teisę reikalauti apriboti duomenų tvarkymą</h4>
                 <p>Jūs turite teisę reikalauti apriboti duomenų tvarkymą, jei:</p>
                 <ul className="privacy-list-bullet">
                   <li>
                     <p>Jūs ginčijate duomenų tikslumą. Duomenų tvarkymas apribojamas tokiam laikotarpiui, per kurį Bendrovė gali patikrinti jų tikslumą.</p>
                   </li>
                   <li>
                     <p>Asmens duomenų tvarkymas yra neteisėtas. Nustačius, kad Bendrovė tvarko Jūsų asmens duomenis neteisėtai, turite teisę reikalauti apriboti neteisėtai tvarkomų asmens duomenų tvarkymą.</p>
                   </li>
                   <li>
                     <p>Siekiate apginti teisinius reikalavimus. Kai duomenų saugojimo laikotarpis baigėsi, tačiau šie duomenys yra Jums reikalingi, galite apriboti šių duomenų tvarkymą, siekiant pareikšti, vykdyti arba apginti teisinius reikalavimus.</p>
                   </li>
                   <li>
                     <p>Išreiškėte prieštaravimą dėl duomenų tvarkymo. Duomenų tvarkymas apribojamas tol, kol bus patikrinta, kurie interesai yra viršesni.</p>
                   </li>
                 </ul>
                 <p>Apribotus asmens duomenis galime tvarkyti, išskyrus saugojimą, tik gavus atskirą Jūsų sutikimą arba siekiant apginti Bendrovės teisinius reikalavimus, apsaugoti fizinių asmenų teises arba dėl svarbaus viešojo intereso priežasčių. Prieš panaikindami asmens duomenų tvarkymo apribojimą, Jus atitinkamai informuosime.</p>

                 <h4>Teisę reikalauti sunaikinti asmens duomenis (&bdquo;teisė būti pamirštam&ldquo;)</h4>
                 <p>Bendrojo duomenų apsaugos reglamento 17 straipsnyje nustatytais atvejais turite teisę reikalauti sunaikinti asmens duomenis, jei nustatysite, kad asmens duomenys yra tvarkomi neteisėtai arba nesąžiningai.</p>
                 <p>Bendrovė gali atsisakyti sunaikinti asmens duomenis atvejais, nurodytais Bendrojo duomenų apsaugos reglamento 17 straipsnyje.</p>
                 <p>Pranešime Jums, ar Jūsų prašomas duomenų sunaikinimas buvo atliktas ar ne. Apie tai informuosime duomenų gavėjus, nebent tai būtų neįmanoma ar pareikalautų neproporcingų pastangų. Jūsų prašymu, pateiksime Jums informaciją apie tokius duomenų gavėjus.</p>

                 <h4>Teisę nesutikti, kad būtų tvarkomi asmens duomenys, išskyrus jei asmens duomenis tvarkome dėl Bendrovės ar kito asmens, kuriam teikiami asmens duomenys, teisėto intereso, ir Jūsų interesai nėra svarbesni.</h4>
                 <p>Jums išreiškus prieštaravimą dėl asmens duomenų tvarkymo, toliau Jūsų asmens duomenis tvarkysime, jeigu motyvuotai nuspręsime, kad priežastys, dėl kurių atliekamas asmens duomenų tvarkymas, yra viršesnės už Jūsų interesus, teises ir laisves, arba jeigu Jūsų asmens duomenys yra reikalingi pareikšti, vykdyti ar apginti teisinius reikalavimus.</p>

                 <h4>Teisę atšaukti duotus sutikimus dėl duomenų tvarkymo</h4>
                 <p>Turite teisę bet kada atšaukti visus savo sutikimus dėl asmens duomenų tvarkymo, kai Jūsų asmens duomenų tvarkymo pagrindas &ndash; Jūsų sutikimas.</p>

                 <h4>Teisę į duomenų perkeliamumą</h4>
                 <p>Turite teisę reikalauti, kad Jūsų pateikti asmens duomenys, jeigu jie tvarkomi sutikimo ar sutarties pagrindu ir jeigu jie tvarkomi automatizuotomis priemonėmis, būtų persiųsti kitam duomenų valdytojui ar perduoti Jums susistemintu, įprastai naudojamu ir kompiuterio skaitomu formatu, jeigu tai yra techniškai įmanoma. Kreipdamiesi dėl šios teisės įgyvendinimo, turite nurodyti, ar pageidaujate, kad asmens duomenys būtų perduoti Jums ar tiesiogiai kitam duomenų valdytojui.</p>
                 <p>Teisė į duomenų perkeliamumą negali būti įgyvendinta dėl asmens duomenų, kurie tvarkomi neautomatiniu būdu susistemintose rinkmenose, pavyzdžiui, popierinėse bylose.</p>

                 <h4>Teisę dėl asmens duomenų tvarkymo pateikti skundą Valstybinei duomenų apsaugos inspekcijai ar kompetentingam teismui</h4>
                 <p>Bendrovės veiksmus ar neveikimą, susijusį su duomenų subjekto teisių įgyvendinimu, galite apskųsti pats arba per tinkamai įgaliotą atstovą ar ne pelno įstaigą, organizaciją ar asociaciją, atitinkančią Reglamento 80 straipsnio reikalavimus, Valstybinei duomenų apsaugos inspekcijai, adresu L. Sapiegos g. 17, Vilnius, el. pašto adresas ada@ada.lt, interneto svetainė https://vdai.lrv.lt taip pat kompetentingam Lietuvos Respublikos teismui.</p>

                 <h4>Teisę į kompensaciją už žalą, patirtą dėl duomenų subjekto teisių pažeidimo</h4>
                 <p>Dėl duomenų subjekto teisių pažeidimo patyrę žalą, turite teisę į kompensaciją, dėl kurios priteisimo turite kreiptis į kompetentingą Lietuvos Respublikos teismą.</p>
                 <h3>DUOMENŲ SUBJEKTO TEISIŲ ĮGYVENDINIMO TVARKA</h3>
                 <p>Kreiptis į Bendrovę dėl duomenų subjekto teisių įgyvendinimo galite žodžiu arba raštu, pateikdami prašymą asmeniškai, paštu ar elektroninėmis priemonėmis šioje Privatumo politikoje nurodytais kontaktais.</p>
                 <p>Jeigu dėl duomenų subjekto teisių įgyvendinimo nusprendėte kreiptis žodžiu, turite patvirtinti savo tapatybę pateikdamas asmens tapatybę patvirtinantį dokumentą. To nepadarius, negalėsime priimti Jūsų prašymų ir duomenų subjekto teisės nebus įgyvendinamos. Ši nuostata netaikoma, jeigu kreipiatės dėl informavimo apie asmens duomenų tvarkymą pagal Bendrojo duomenų apsaugos Reglamento 13 ir 14 straipsnius.</p>
                 <p>Jeigu dėl duomenų subjekto teisių įgyvendinimo nusprendėte kreiptis raštu (paštu), kartu su prašymu turite teisės aktuose nustatyta tvarka patvirtintą asmens tapatybę patvirtinančio dokumento kopiją.</p>
                 <p>Jeigu nusprendėte teikiate prašymą elektroninio ryšio priemonėmis, prašymas turi būti pasirašytas kvalifikuotu elektroniniu parašu arba jis turi būti suformuotas elektroninėmis priemonėmis, kurios leidžia užtikrinti teksto vientisumą ir nepakeičiamumą. Ši nuostata netaikoma, jeigu kreipiatės dėl informavimo apie asmens duomenų tvarkymą pagal Bendrojo duomenų apsaugos reglamento 13 ir 14 straipsnius.</p>
                 <p>Prašymas įgyvendinti duomenų subjekto teises turi būti įskaitomas, pasirašytas, jame turi būti nurodyti Jūsų vardas, pavardė, adresas ir (ar) kiti kontaktiniai duomenys ryšiui palaikyti ar kuriais pageidaujama gauti atsakymą.</p>
                 <p>Jei nusprendėte savo teises įgyvendinti per atstovą, Jūsų atstovas turi nurodyti savo vardą, pavardę, adresą ir (ar) kitus kontaktinius duomenis ryšiui palaikyti, kuriais Jūsų atstovas pageidauja gauti atsakymą, taip pat Jūsų vardą, pavardę ir nurodyti kitus duomenis, kurie reikalingi duomenų subjekto tinkamam identifikavimui bei pateikti atstovavimą patvirtinantį dokumentą ar jo patvirtintą kopiją.</p>
                 <p>Jei mums kiltų abejonių dėl Jūsų tapatybės, duomenų, turime teisę prašyti papildomos informacijos, reikalingos ja įsitikinti.</p>
                 <p>Visais klausimais, susijusiais su asmens duomenų tvarkymu ir naudojimusi savo teisėmis, galite kreiptis į Bendrovės duomenų apsaugos pareigūną. Rekomenduojame nurodyti, kad korespondencija skirta Bendrovės duomenų apsaugos pareigūnui.</p>
                 <h3>BAIGIAMOSIOS NUOSTATOS</h3>
                 <p>Privatumo politikos nuostatų vykdymui ir aiškinimui yra taikoma Lietuvos Respublikos teisė.</p>
                 <p>Jei kuri nors Privatumo politikos nuostata tampa ar pripažįstama negaliojančia, likusios nuostatos lieka galioti.</p>
                 <p>Ši Privatumo politika galioja nuo jos paskelbimo svetainėje dienos. Ši Privatumo politika nėra laikoma Bendrovės ir Jūsų susitarimu dėl asmens duomenų tvarkymo. Šia Privatumo politika Bendrovė Jus informuoja apie Jūsų asmens duomenų tvarkymo principus Bendrovėje. Mes galime bet kada pakeisti Privatumo politiką. Visi Privatumo politikos pakeitimai yra skelbiami svetainėje. Privatumo politikos pakeitimai ir (ar) papildymai įsigalioja po jų paskelbimo svetainėje momento. Rekomenduojame reguliariai peržiūrėti mūsų Privatumo politiką.</p>
                 <small>Paskutinį kartą atnaujinta 2024-06-01</small>
               </article>
              </div>
            </div>
          </div>
        </section>
      </main>
    )
  }
}