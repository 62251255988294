import React from 'react'
import ReactImageFallback from 'react-image-fallback'
import BnpFinanceWebp from '~/img/companies/bnp_finance_webp.webp'
import BnpFinance from '~/img/illustration/bnp_finance.png'
import NeoFinanceWebp from '~/img/companies/neo_finance_webp.webp'
import NeoFinance from '~/img/illustration/neo_finance.png'
import ESkolos from '~/img/illustration/eskolos.png'
import ESkolosWebp from '~/img/companies/eskolos_webp.webp'
import LKU from '~/img/companies/lku.png'
import LKUWebp from '~/img/companies/lku_webp.webp'
import Payswix from '~/img/companies/payswix.jpg'
import Enefit from '~/img/companies/enefit.png'
import EnefitWebp from '~/img/companies/enefit_webp.webp'
import Lendstream from '~/img/companies/lendstream.png'
import LendstreamWebp from '~/img/companies/lendstream_webp.webp'

export default class Companies extends React.Component {
  render() {
    return (
      <section className="companies">
        <div className="grid">
          <div className="row row--hor-center">
            <div className="col">
              <h3 className="ta-center">Trusted by: </h3>
            </div>
            <div className="col lg-10-12">
              <div className="companies__list">
                <ReactImageFallback
                  src={NeoFinanceWebp}
                  fallbackImage={NeoFinance}
                  alt="Neo Finance Logo"
                />
                <ReactImageFallback
                  src={BnpFinanceWebp}
                  fallbackImage={BnpFinance}
                  alt="BnP Finance Logo"
                  className="bnp__logo"
                />
                <ReactImageFallback
                  src={ESkolosWebp}
                  fallbackImage={ESkolos}
                  alt="Eskolos Logo"
                />
                <ReactImageFallback
                  src={LKUWebp}
                  fallbackImage={LKU}
                  alt="LKU Logo"
                  className="lku__logo"
                />
                <ReactImageFallback
                  src={Payswix}
                  fallbackImage={Payswix}
                  alt="Payswix Logo"
                />
                <ReactImageFallback
                  src={EnefitWebp}
                  fallbackImage={Enefit}
                  alt="Enefit Logo"
                  className="enefit__logo"
                />
                <ReactImageFallback
                  src={LendstreamWebp}
                  fallbackImage={Lendstream}
                  alt="Lendstream Logo"
                  className="lendstream__logo"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}