import React, { Component } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import apiClient from '~/js/utils/apiClient'

export default class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formSentSuccess: false,
            formSubmitMessage: ''
        }
    }

    componentDidMount() {
        const checkboxLabel = document.querySelector('label[for="accept"]')
        if (checkboxLabel != null) {
            checkboxLabel.addEventListener('click', () => {
                checkboxLabel.classList.toggle('active')
            })
        }
    }

    onSubmit(values) {
        let formData = new FormData
        formData.append('name', values.name)
        formData.append('phone', values.phone)
        formData.append('email', values.email)
        formData.append('company', values.company)
        formData.append('message', values.message)
        formData.append('accept', values.accept)

        return apiClient
            .post(`/send-mail`, formData )
            .then((response) => {
                this.setState({
                    formSentSuccess: true,
                    formSubmitMessage: response.data.message
                })
            })
    }

    initialValues = {
        name: '',
        phone: '' ,
        email: '',
        company: '',
        messages: '',
        accept: false
    }

    validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Please enter your full name'),
        phone: Yup.string()
            .test(
                'eitherOneIsRequired',
                'Either email or phone has to be provided',
                function (phone) {
                    const { email } = this.parent
                    if (!email && !phone) {
                        return false
                    } else {
                        return true
                    }
                }
            )
            .when(['email'], {
                is: email => !email,
                then: Yup.string().required('Please enter your email address'),
            }),
        email: Yup.string().email()
            .test(
                'eitherOneIsRequired',
                'Either email or phone has to be provided',
                function (email) {
                    const { phone } = this.parent
                    if (!email && !phone) {
                        return false
                    } else {
                        return true
                    }
                }
            )
            .when(['phone'], {
                is: phone => !phone,
                then: Yup.string().required('Please enter your phone number'),
            }),
        message: Yup.string()
            .required('Please provide your message'),
        accept: Yup.boolean()
            .oneOf([true], 'Please accept our data processing terms'),
    }, [['email', 'phone']]
    )

    render() {
        let linkText

        if (this.props.career) {
            linkText = 'Link to your profile (Linkedin/Github etc.) URL'
        } else {
            linkText = 'Company'
        }

        return (
            <div className="form form--main">
                <h2 className="ta-center">{ this.props.messageOne }</h2>
                <p className="ta-center">{ this.props.messageTwo }</p>
                <Formik
                    initialValues={ this.initialValues }
                    validationSchema={ this.validationSchema }
                    onSubmit={(values) => this.onSubmit(values)}
                >
                    {props => (
                        <Form className="form__content">
                            <label htmlFor="name">Full name</label>
                            <ErrorMessage className="contact_form__error" name="name" component="span" />
                            <Field className="form-group__input" type="text" name="name" id="name" placeholder="Full name" value={props.values.name} onChange={ props.handleChange }/>

                            <label htmlFor="phone">Phone</label>
                            <ErrorMessage className="contact_form__error" name="phone" component="span" />
                            <Field className="form-group__input" type="text" name="phone" id="phone" placeholder="Phone" value={props.values.phone} onChange={ props.handleChange }/>

                            <label htmlFor="email">Email</label>
                            <ErrorMessage className="contact_form__error" name="email" component="span" />
                            <Field className="form-group__input" type="email" name="email" id="email" placeholder="Email" value={props.values.email} onChange={ props.handleChange }/>

                            <label htmlFor="company">{ linkText }</label>
                            <ErrorMessage className="contact_form__error" name="company" component="span" />
                            <Field className="form-group__input" type="text" name="company" id="company" placeholder={ linkText } value={props.values.company} onChange={ props.handleChange }/>

                            <label htmlFor="message">Message</label>
                            <ErrorMessage className="contact_form__error" name="message" component="span" />
                            <Field className="form-group__input" component="textarea" name="message" id="message" value={props.values.message} onChange={ props.handleChange }/>

                            <ErrorMessage className="contact_form__error" name="accept" component="span" />
                            <label htmlFor="accept">I agree to allow to store and process my personal data.</label>
                            <Field className="form-group__input" type="checkbox" name="accept" id="accept" value="1" onChange={ props.handleChange }/>

                            {this.state.formSentSuccess &&
                                <div className="form_sent__success">
                                    <h6>{this.state.formSubmitMessage}</h6>
                                </div>
                            }

                            <div className="ta-center">
                                <button type="submit" className="btn btn--primary btn--primary--bigger">Submit</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        )
    }
}
