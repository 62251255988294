// @ts-check

// eslint-disable-next-line no-undef
const env = process.env

export const isProduction = () => env.NODE_ENV === 'production'
export const isDev = () => env.NODE_ENV === 'development'

export default {
  /**
   * @param {string} key
   * @returns {boolean}
   */
  has: function(key) {
    return typeof env[key] !== 'undefined'
  },
  /**
   * @param {string} key
   * @returns {string|undefined}
   */
  get: function(key) {
    return this.has(key) ? env[key] : undefined
  },
}