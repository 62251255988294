import React, { Component } from 'react'
import GraphicElementWhite from '~/img/hero/graphic-element-white.svg'
import PeopleImage from '~/img/illustration/people.svg'
import Passport from '~/img/illustration/passport.svg'
import Map from '~/img/illustration/contacts-map.svg'
import BusinessIdentification from '~/img/illustration/business-identification.svg'
import Companies from '~/js/components/Web/LandingComponents/Companies'
import { Link } from 'react-scroll'

export default class Fintech extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <main>
                <section className="intro--color intro--color--red" id="intro">
                    <div className="intro__el">
                        <img src={ GraphicElementWhite } alt="" />
                    </div>
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col lg-8-12 md-10-12">
                                <div className="intro__content ta-center">
                                    <h3 className="section-title section-title--sm">Fintech</h3>
                                    <h1 className="ta-center">Fintech Onboarding & compliance tools you</h1>
                                    <p className="ta-center">
                                        When you use Mark ID, onboarding for individuals, AML,
                                        crypto compliance, and more — the checks run automatically,
                                        with reports driven right to your dashboard
                                    </p>
                                </div>
                            </div>
                            <div className="col lg-10-12">
                                <div className="list list--check list--check--circle list--check--circle--white">
                                    <ul>
                                        <li><p>Generate more revenue reducing operational costs up to 60%</p></li>
                                        <li><p>Convert your existing policy into an automated digital process</p></li>
                                        <li><p>Onboard your customers in under 1 min. with AI, and re-check manually, if and when required</p></li>
                                    </ul>
                                </div>
                                <div className="ta-center">
                                    <Link className="btn btn--primary" to="about" smooth={true}>Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="text-block text-block--lg" id="about">
                    <div className="grid">
                        <div className="row">
                            <div className="col">
                                <div className="text-block__list">
                                    <div className="text-block__list__item">
                                        <img src={ PeopleImage } alt="People" />
                                        <div className="text">
                                            <h2>AML screening</h2>
                                            <p className="list-title">
                                                Empower your compliance team with the best data. Automated,
                                                ongoing risk-monitoring using authorized sources and fuzzy matching.
                                            </p>
                                            <div className="list list--icon">
                                                <ul>
                                                    <li>
                                                        Sanctions and watchlists (UN, OFAC, HMT, EU) and regional sanctions data
                                                    </li>
                                                    <li>
                                                        Structured PEPs profiles categorized for flexible workflows
                                                    </li>
                                                    <li>
                                                        Adverse media to distinguish any potential false-positives
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-block__list__item">
                                        <img src={ BusinessIdentification } alt="Computer and Mobile" />
                                        <div className="text">
                                            <h2>Reporting</h2>
                                            <p className="list-title">All reports, always on your dashboard</p>
                                            <div className="list list--square list--square--blue">
                                                <ul>
                                                    <li>
                                                        View the status of existing and new identifications
                                                    </li>
                                                    <li>
                                                        Monitor how the process against workflows
                                                    </li>
                                                    <li>
                                                        Re-evaluate and re-check manually when necessary
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-block__list__item">
                                        <img src={ Passport } alt="Passport" />
                                        <div className="text">
                                            <h2>IDs</h2>
                                            <p>
                                                Mark ID supports over 6500 types of documents from over 220  countries and territories
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-block__list__item">
                                        <img src={ Map } alt="Map" />
                                        <div className="text">
                                            <h2>Flexible Integration</h2>
                                            <p>
                                                Integrate via mobile SDK, our iFrame widget or redirect API, with over 99.9% uptime
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blue-box">
                    <div className="grid">
                        <div className="blue-box__bg">
                            <div className="blue-box__list">
                                <div className="blue-box__list__item ta-center"><span className="title">24/7</span>
                                    <p className="description">Automated checks</p>
                                </div>
                                <div className="blue-box__list__item ta-center"><span className="title">6500+</span>
                                    <p className="description">Document types from 220+ countries</p>
                                </div>
                                <div className="blue-box__list__item ta-center"><span className="title">300s</span>
                                    <p className="description">Average manual KYC expert re-evaluation time</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Companies />
            </main>
        )
    }
}
