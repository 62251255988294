import React, { Component } from 'react'
import GreenGraphicElement from '~/img/hero/graphic-element-green.svg'
import NumberOne from '~/img/numbers/1.svg'
import NumberTwo from '~/img/numbers/2.svg'
import NumberThree from '~/img/numbers/3.svg'
import People from '~/img/illustration/people.svg'
import BusinessIdentification from '~/img/illustration/business-identification.svg'
import Map from '~/img/illustration/map.svg'
import Companies from '~/js/components/Web/LandingComponents/Companies'
import { Link } from 'react-scroll'

export default class AmlScreening extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const showMoreLinks = document.querySelectorAll('.show-more')
        showMoreLinks.forEach(link => {
            link.addEventListener('click', () => {
                const itemColumnsDescription = link.previousElementSibling
                if (itemColumnsDescription.style.maxHeight) {
                    itemColumnsDescription.style.maxHeight = null
                    link.innerHTML = 'Read more'
                } else {
                    itemColumnsDescription.style.maxHeight = itemColumnsDescription.scrollHeight + "px"
                    link.innerHTML = 'Read less'
                }
            })
        })
        const itemColumnsDescriptions = document.querySelectorAll('.item__columns__description')
        itemColumnsDescriptions.forEach(description => {
            if (description.clientHeight < 120) {
                const showMoreLink = description.nextElementSibling
                showMoreLink.style.display = 'none'
            }
        })
    }

    render() {
        return (
            <main>
                <section className="intro--white" id="intro">
                    <div className="intro__el">
                        <img src={ GreenGraphicElement } alt="" />
                    </div>
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col lg-8-12 md-10-12 text__center">
                                <div className="intro__content platforms__text">
                                    <h1>AML Screening</h1>
                                    <p className="ta-center">
                                        Although record fines are issued annually, an estimated $2 trillion is still laundered globally each year.
                                        Financial institutions and similar organizations must implement processes that can effectively identify
                                        PEPs and ensure that money is not derived from criminal activity. Compliance with AML requirements is a
                                        vital requirement for management, to ensure that legal requirements are met at both a local and global level.
                                        Do you want to fight illegal money-related activities?
                                    </p>
                                    <img src={ People } alt="AML screening" />
                                </div>
                                <Link className="btn btn--primary" to="about" smooth={true}>Learn More</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="text-block" id="about">
                    <div className="grid">
                        <h3 className="ta-center">Our AML screening includes 3 solutions:</h3>
                        <div className="text-block__list">
                            <div className="text-block__list__item">
                                <img src={ People } alt="People" />
                                <div className="text">
                                    <h2>Politically Exposed Persons (PEPs)
                                        <img className="number" src={ NumberOne } alt="Number One" />
                                    </h2>
                                    <p className="list-title">
                                        Enhanced PEP profiles categorized for flexible searching
                                    </p>
                                    <div className="list list--square list--square--green">
                                        <ul>
                                            <li>100% PEP profiles checked for updates daily</li>
                                            <li>Global coverage</li>
                                            <li>Consolidated profiles with Sanction and Adverse media data</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item__columns">
                                    <ul>
                                        <li>
                                            <h5>Tailored Screening</h5>
                                            <p className="item__columns__description">
                                                Tailored Screening – every change to an entity is clearly categorized
                                                by 4 risk stages (High Risk to Low Risk), age and type to suit specific requirements
                                            </p>
                                            <a className="show-more">Read More</a>
                                        </li>
                                        <li>
                                            <h5>Updated Daily</h5>
                                            <p className="item__columns__description">
                                                Updated Daily – our AI-based approach enables us to monitor more
                                                data sources and identify risk signals more quickly and comprehensively
                                                than traditional methods that rely on human analysts
                                            </p>
                                            <a className="show-more">Read More</a>
                                        </li>
                                        <li>
                                            <h5>RCA Coverage</h5>
                                            <p className="item__columns__description">
                                                RCA Coverage – our PEP profiles are built with a major focus on
                                                Relatives and Close Associates (RCAs), including spouses, partners,
                                                and children, to increase accuracy and eliminate risks
                                            </p>
                                            <a className="show-more">Read More</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="text-block__list__item">
                                <img src={ Map } alt="Map" />
                                <div className="text">
                                    <h2>Global Sanctions & Watchlists
                                        <img className="number" src={ NumberTwo } alt="Number Two" />
                                    </h2>
                                    <p className="list-title">Comprehensive sanction list monitored in real-time</p>
                                    <div className="list list--square list--square--green">
                                        <ul>
                                            <li>1000s government, regulatory and law enforcement watchlists</li>
                                            <li>Sanction updates in 15 minutes</li>
                                            <li>Updates 7 hours ahead of the official source.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item__columns">
                                    <ul>
                                        <li>
                                            <h5>Instant Updates</h5>
                                            <p className="item__columns__description">
                                                Instant Updates – we use the latest technologies in information
                                                retrieval and machine learning, providing confident and reliable
                                                screening against the cleanest Sanctions and watchlists, including OFAC,
                                                UN, HMT, EU, DFAT, and others from across the world
                                            </p><a className="show-more">Read More</a>
                                        </li>
                                        <li>
                                            <h5>Fuzzy Matching</h5>
                                            <p className="item__columns__description">
                                                Fuzzy Matching – advanced sanction screening with powerful,
                                                flexible “fuzzy matching” search capability that optimizes searches
                                                reducing false positives following a solid risk-based approach
                                            </p><a className="show-more">Read More</a>
                                        </li>
                                        <li>
                                            <h5>Structured Profiles</h5>
                                            <p className="item__columns__description">
                                                Structured Profiles – have the context to make the right AML risk
                                                decisions with comprehensive profiles enhanced with the most up to
                                                date Adverse Media and information on Politically Exposed Persons (PEPs)
                                            </p><a className="show-more">Read More</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="text-block__list__item">
                                <img src={ BusinessIdentification } alt="Adverse" />
                                <div className="text">
                                    <h2>Adverse/Negative Media
                                        <img className="number" src={ NumberThree } alt="Number Three" />
                                    </h2>
                                    <p className="list-title">Richer news insight combined into each entity profile</p>
                                    <div className="list list--square list--square--green">
                                        <ul>
                                            <li>150m articles read per month</li>
                                            <li>30,000 profiles updated everyday</li>
                                            <li>200+ countries and territories covered</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item__columns">
                                    <ul>
                                        <li>
                                            <h5>Automatic Updates</h5>
                                            <p className="item__columns__description">
                                                Automatic Updates – we use machine learning algorithms actively
                                                developed by world-leading engineering teams, and curated by teams
                                                of subject matter experts, to monitor tens of thousands of risk events
                                                from several million media articles, each day
                                            </p><a className="show-more">Read More</a>
                                        </li>
                                        <li>
                                            <h5>Higher Quality Data</h5>
                                            <p className="item__columns__description">
                                                Higher Quality Data – our algorithms are capable of processing 6.5 million
                                                articles per day more accurately and consistently than human counterparts.
                                                By contrast, a team of 50 researchers working  8-hours/ day without breaks can
                                                only process up to 24,000 articles.
                                            </p><a className="show-more">Read More</a>
                                        </li>
                                        <li>
                                            <h5>Classification Accuracy</h5>
                                            <p className="item__columns__description">
                                                Classification Accuracy – all profiles in our data have been accurately
                                                labeled with easy-to-justify categories to enhance your understanding.
                                                False positives are then significantly decreased by screening only against
                                                business relevant categories
                                            </p><a className="show-more">Read More</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="list list--check list--check--circle">
                    <div className="grid">
                        <div className="row">
                            <div className="col">
                                <h3 className="ta-center">With our AML screening you can:</h3>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <p>
                                    affordably manage legal, reputational and compliance risk by screening against PEPs,
                                    sanctions, and adverse media lists.
                                </p>
                            </li>
                            <li>
                                <p>
                                    help prevent financial crime and money laundering with automated one-time or ongoing monitoring
                                </p>
                            </li>
                            <li>
                                <p>
                                    automate AML screening by using AI and advanced machine learning algorithms without the need for manual review
                                </p>
                            </li>
                            <li>
                                <p>customize and tailor the AML process to your unique risk rules, thus reducing the number of false positives</p>
                            </li>
                            <li>
                                <p>
                                    combine face and document verification and AML screening into a single dashboard, thus
                                    making customer onboarding and KYC compliance in your company easy and simple
                                </p>
                            </li>
                            <li>
                                <p>
                                    cover customers globally – check profiles of entities on global and national sanctions
                                    lists, including OFAC, HMT, UN, and thousands of other watchlists
                                </p>
                            </li>
                            <li>
                                <p>
                                    protect your business reputation by using our top-level AML screening services,
                                    which are trusted by Bank of America, Santander, VISA, and other global  corporate leaders
                                </p>
                            </li>
                            <li>
                                <p>
                                    gain richer insights into your customers
                                </p>
                            </li>
                        </ul>
                    </div>
                </section>
                <Companies />
            </main>
        )
    }
}
