import React, { Component } from 'react'
import ComputerMobile from '~/img/illustration/computer-mobile.svg'
import MapImage from '~/img/illustration/map.svg'
import Documents from '~/img/illustration/documents.svg'
import Passport from '~/img/illustration/passport.svg'
import Companies from '~/js/components/Web/LandingComponents/Companies'
import { Link } from 'react-scroll'

export default class MoneyInstitutions extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <main>
                <section className="intro--color intro--color--turquoise" id="intro">
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col lg-8-12 md-10-12">
                                <div className="intro__content ta-center">
                                    <h3 className="section-title section-title--sm">Money institutions</h3>
                                    <h1 className="ta-center">Drive down cost of KYC by using Simplified Identification</h1>
                                    <p className="ta-center">
                                        When onboarding new customers, use Simplified Identification first to speed up
                                        the process and reduce the cost of screening new customers. We helped our customers
                                        cut compliance costs up to 5x. Discover how it will work for you!
                                    </p>
                                </div>
                            </div>
                            <div className="col lg-10-12">
                                <div className="ta-center">
                                    <Link className="btn btn--primary btn--primary--white" to="about" smooth={true}>Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="text-block text-block--lg" id="about">
                    <div className="grid">
                        <div className="row">
                            <div className="col">
                                <div className="text-block__list">
                                    <div className="text-block__list__item">
                                        <img src={ MapImage } alt="Map" />
                                        <div className="text">
                                            <h2>Automate the process</h2>
                                            <p>
                                                Convert your existing AML policy into an automated digital workflow and re-check
                                                manually when needed. Mark ID will alert you when the risk level of your customer changes,
                                                so you can take the required actions and quickly make informed key decisions.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-block__list__item">
                                        <img src={ Documents } alt="Documents" />
                                        <div className="text">
                                            <h2>Biometric verification</h2>
                                            <p>
                                                The more you grow, the higher is the potential for cheating.
                                                For premium products, high-risk users, or strictly regulated jurisdictions,
                                                a new level of protection is often required. Video selfie system will keep you safe.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-block__list__item">
                                        <img src={ Passport } alt="Passport" />
                                        <div className="text">
                                            <h2>IDs</h2>
                                            <p>
                                                Mark ID supports 6500+ types of documents from 220+ countries and territories
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-block__list__item">
                                        <img src={ ComputerMobile } alt="Computer and Mobile" />
                                        <div className="text">
                                            <h2>Flexible Integration</h2>
                                            <p>
                                                Integrate via mobileSDK, our iFrame widget or redirect API, with over 99.9% uptime
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blue-box" id="scroll-to">
                    <div className="grid">
                        <div className="blue-box__bg">
                            <div className="blue-box__list">
                                <div className="blue-box__list__item ta-center"><span className="title">24/7</span>
                                    <p className="description">Automated checks</p>
                                </div>
                                <div className="blue-box__list__item ta-center"><span className="title">6500+</span>
                                    <p className="description">Document types from 220+ countries</p>
                                </div>
                                <div className="blue-box__list__item ta-center"><span className="title">300s</span>
                                    <p className="description">Average manual KYC expert re-evaluation time</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Companies />
            </main>
        )
    }
}
