import React, { Component } from 'react'
import ReactImageFallback from 'react-image-fallback'
import GraphicElementGreen from '~/img/hero/graphic-element-green.svg'
import IdIcon from '~/img/icons/id.svg'
import SanctionIcon from '~/img/icons/sanctions.svg'
import SignatureIcon from '~/img/icons/signature.svg'
import PersonSearchIcon from '~/img/icons/person-search.svg'
import SponsorWebp from '~/img/companies/sponsor_webp.webp'
import Sponsor from '~/img/companies/sponsor.jpg'
import AngelaiWebp from '~/img/companies/angelai_webp.webp'
import Angelai from '~/img/companies/angelai.jpg'
import CrosspringWebp from '~/img/companies/crosspring_webp.webp'
import Crosspring from '~/img/companies/crosspring.png'
import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {A11y, Navigation, Pagination, Scrollbar} from 'swiper'

export default class About extends Component {
    constructor(props) {
        super(props);
    }

    scrollTo() {
        const mobileMenu = document.querySelector('.text-block')
        window.scrollTo(0, mobileMenu.offsetTop)
    }

    render() {
        SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])
        return (
            <main>
                <section className="intro--dark about--hero" id="intro">
                    <div className="intro__el">
                        <img src={ GraphicElementGreen } alt="" />
                    </div>
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col lg-8-12 md-10-12">
                                <div className="intro__content ta-center">
                                    <h3 className="section-title section-title--sm">About us</h3>
                                    <h1 className="ta-center about-us-heading">Be Open. Be Digital</h1>
                                    <p className="ta-center">
                                        More than a half of new gaming sign-ups are young players.
                                        So, if you trust reference agencies, you are missing out!
                                        Credit records of youth are often short or non-existant.
                                        Onboard 40% more customers with accurate document verification.
                                    </p>
                                    <a className="btn btn--primary" href="#" onClick={ this.scrollTo }>Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="cards text-block">
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col">
                                <h2 className="section-title section-title--md ta-center">Our products</h2>
                            </div>
                            <div className="col lg-5-12">
                                <a className="cards__item" href="/electronic-signature">
                                    <img src={ SignatureIcon } alt="ID Icon" />
                                    <div className="cards__item__text">
                                        <h5>Document eSignature</h5>
                                    </div>
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M2.20718 11.5L0.792969 10.0858L5.08586 5.79289L0.792968 1.5L2.20718 0.0857868L7.91429 5.79289L2.20718 11.5Z"
                                              fill="#57586E"/>
                                    </svg>
                                </a>
                            </div>
                            <div className="col lg-5-12">
                                <a className="cards__item" href="/aml-screening">
                                    <img src={ SanctionIcon } alt="Sanctions Icon" />
                                    <div className="cards__item__text">
                                        <h5>AML screening</h5>
                                    </div>
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M2.20718 11.5L0.792969 10.0858L5.08586 5.79289L0.792968 1.5L2.20718 0.0857868L7.91429 5.79289L2.20718 11.5Z"
                                              fill="#57586E"/>
                                    </svg>
                                </a>
                            </div>
                            <div className="col lg-5-12">
                                <a className="cards__item" href="/identity-verification">
                                    <img src={ PersonSearchIcon } alt="Key Icon" />
                                    <div className="cards__item__text">
                                        <h5>Identity Verification</h5>
                                    </div>
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M2.20718 11.5L0.792969 10.0858L5.08586 5.79289L0.792968 1.5L2.20718 0.0857868L7.91429 5.79289L2.20718 11.5Z"
                                              fill="#57586E"/>
                                    </svg>
                                </a>
                            </div>
                            <div className="col lg-5-12">
                                <a className="cards__item" href="/document-verification">
                                    <img src={ IdIcon } alt="Shield Icon" />
                                    <div className="cards__item__text">
                                        <h5>Document verification</h5>
                                    </div>
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M2.20718 11.5L0.792969 10.0858L5.08586 5.79289L0.792968 1.5L2.20718 0.0857868L7.91429 5.79289L2.20718 11.5Z"
                                              fill="#57586E"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<section className="people">*/}
                {/*    <div className="grid">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col">*/}
                {/*                <h3>Meet the team</h3>*/}
                {/*            </div>*/}
                {/*            <div className="col">*/}
                {/*                <div className="swiper-container">*/}
                {/*                    <div className="swiper-wrapper">*/}
                {/*                        <Swiper*/}
                {/*                            spaceBetween={18}*/}
                {/*                            navigation={{*/}
                {/*                                nextEl: '.arrow-next',*/}
                {/*                                prevEl: '.arrow-prev',*/}
                {/*                            }}*/}
                {/*                            pagination={{ el: '.swiper-pagination', clickable: true }}*/}
                {/*                            breakpoints={{*/}
                {/*                                320: {*/}
                {/*                                    slidesPerView: 1,*/}
                {/*                                    spaceBetween: 20*/}
                {/*                                },*/}
                {/*                                670: {*/}
                {/*                                    slidesPerView: 2,*/}
                {/*                                    spaceBetween: 20*/}
                {/*                                },*/}
                {/*                                1024: {*/}
                {/*                                    slidesPerView: 3,*/}
                {/*                                    spaceBetween: 20*/}
                {/*                                }*/}
                {/*                            }}*/}
                {/*                        >*/}
                {/*                            <SwiperSlide>*/}
                {/*                                <div className="people__card">*/}
                {/*                                    <div className="people__card__img" />*/}
                {/*                                    <div className="people__card__text">*/}
                {/*                                        <h6>Abram Baptista</h6>*/}
                {/*                                        <p>Co-founder &amp; CEO</p>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            </SwiperSlide>*/}
                {/*                            <SwiperSlide>*/}
                {/*                                <div className="people__card">*/}
                {/*                                    <div className="people__card__img" />*/}
                {/*                                    <div className="people__card__text">*/}
                {/*                                        <h6>Abram Baptista</h6>*/}
                {/*                                        <p>Co-founder &amp; CEO</p>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            </SwiperSlide>*/}
                {/*                            <SwiperSlide>*/}
                {/*                                <div className="people__card">*/}
                {/*                                    <div className="people__card__img" />*/}
                {/*                                    <div className="people__card__text">*/}
                {/*                                        <h6>Abram Baptista</h6>*/}
                {/*                                        <p>Co-founder &amp; CEO</p>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            </SwiperSlide>*/}
                {/*                            <SwiperSlide>*/}
                {/*                                <div className="people__card">*/}
                {/*                                    <div className="people__card__img" />*/}
                {/*                                    <div className="people__card__text">*/}
                {/*                                        <h6>Abram Baptista</h6>*/}
                {/*                                        <p>Co-founder &amp; CEO</p>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            </SwiperSlide>*/}
                {/*                            <SwiperSlide>*/}
                {/*                                <div className="people__card">*/}
                {/*                                    <div className="people__card__img" />*/}
                {/*                                    <div className="people__card__text">*/}
                {/*                                        <h6>Abram Baptista</h6>*/}
                {/*                                        <p>Co-founder &amp; CEO</p>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            </SwiperSlide>*/}
                {/*                        </Swiper>*/}
                {/*                    </div>*/}
                {/*                    <div className="arrows">*/}
                {/*                        <div className="arrow-prev">*/}
                {/*                            <svg width="24" height="20" viewBox="0 0 24 20" fill="none"*/}
                {/*                                 xmlns="http://www.w3.org/2000/svg">*/}
                {/*                                <path fillRule="evenodd" clipRule="evenodd"*/}
                {/*                                      d="M0.222657 11.4999L18.5818 11.4999L13.3627 17.2409L15.5826 19.2589L23.9998 9.99994L15.5826 0.740356L13.3627 2.7583L18.582 8.49991L0.222657 8.49991L0.222657 11.4999Z"*/}
                {/*                                      fill="#3EB4DC"/>*/}
                {/*                            </svg>*/}
                {/*                        </div>*/}
                {/*                        <div className="arrow-next">*/}
                {/*                            <svg width="24" height="20" viewBox="0 0 24 20" fill="none"*/}
                {/*                                 xmlns="http://www.w3.org/2000/svg">*/}
                {/*                                <path fillRule="evenodd" clipRule="evenodd"*/}
                {/*                                      d="M0.222657 11.4999L18.5818 11.4999L13.3627 17.2409L15.5826 19.2589L23.9998 9.99994L15.5826 0.740356L13.3627 2.7583L18.582 8.49991L0.222657 8.49991L0.222657 11.4999Z"*/}
                {/*                                      fill="#3EB4DC"/>*/}
                {/*                            </svg>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                {/*<section className="people">*/}
                {/*    <div className="grid">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col">*/}
                {/*                <h3>Our investors</h3>*/}
                {/*            </div>*/}
                {/*            <div className="col lg-4-12 sm-6-12">*/}
                {/*                <div className="people__card">*/}
                {/*                    <div className="people__card__img" />*/}
                {/*                    <div className="people__card__text">*/}
                {/*                        <h6>Abram Baptista</h6>*/}
                {/*                        <p>Co-founder &amp; CEO</p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="col lg-4-12 sm-6-12">*/}
                {/*                <div className="people__card">*/}
                {/*                    <div className="people__card__img" />*/}
                {/*                    <div className="people__card__text">*/}
                {/*                        <h6>Abram Baptista</h6>*/}
                {/*                        <p>Co-founder &amp; CEO</p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="col lg-4-12 sm-6-12">*/}
                {/*                <div className="people__card">*/}
                {/*                    <div className="people__card__img" />*/}
                {/*                    <div className="people__card__text">*/}
                {/*                        <h6>Abram Baptista</h6>*/}
                {/*                        <p>Co-founder &amp; CEO</p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="col lg-4-12 sm-6-12">*/}
                {/*                <div className="people__card">*/}
                {/*                    <div className="people__card__img" />*/}
                {/*                    <div className="people__card__text">*/}
                {/*                        <h6>Abram Baptista</h6>*/}
                {/*                        <p>Co-founder &amp; CEO</p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="col lg-4-12 sm-6-12">*/}
                {/*                <div className="people__card">*/}
                {/*                    <div className="people__card__img" />*/}
                {/*                    <div className="people__card__text">*/}
                {/*                        <h6>Abram Baptista</h6>*/}
                {/*                        <p>Co-founder &amp; CEO</p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="col lg-4-12 sm-6-12">*/}
                {/*                <div className="people__card">*/}
                {/*                    <div className="people__card__img" />*/}
                {/*                    <div className="people__card__text">*/}
                {/*                        <h6>Abram Baptista</h6>*/}
                {/*                        <p>Co-founder &amp; CEO</p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                <section className="companies companies--testimonial">
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col">
                                <h3 className="ta-center">Our investors</h3>
                                <p className="ta-center subtitle">Unlock the full potential of your user experience with
                                    tailor-made solution</p>
                            </div>
                            <div className="col lg-10-12">
                                <div className="companies__list">
                                    <ReactImageFallback
                                        src={ SponsorWebp }
                                        fallbackImage={ Sponsor }
                                        alt="Europos Sąjungos fondų investicijų veiksmų programos logo"
                                        className="investor__images"
                                    />
                                    <ReactImageFallback
                                        src={ AngelaiWebp }
                                        fallbackImage={ Angelai }
                                        alt="Verslo Angelų Fondas II logo"
                                        className="investor__images"
                                    />
                                    <ReactImageFallback
                                        src={ CrosspringWebp }
                                        fallbackImage={ Crosspring }
                                        alt="Crossspring logo"
                                        className="investor__images"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="roadmap">
                    <div className="grid">
                        <div className="roadmap__title">
                            <h2 className="section-title section-title--xs">Roadmap</h2>
                            <h3>Nothing is unachievable</h3>
                        </div>
                        <svg className="roadmap__desktop" xmlns="http://www.w3.org/2000/svg" width="1154" height="897"
                             viewBox="0 0 1154 897">
                            <g id="Group_16" data-name="Group 16" transform="translate(-191 -1433.999)">
                                <path id="Path_33" data-name="Path 33"
                                      d="M586,.015s-.5,256-.5,302c0,82.5-35.5,141.5-136.5,141.5H171c-67,0-133.5,53.6-133.5,136s66.5,142.5,133.5,142.5H972c103,0,144.5,81.5,144.5,145.5"
                                      transform="translate(191 1433.985)" fill="none" stroke="#b8f0c6"
                                      strokeWidth="1"/>
                                <circle id="Ellipse_29" data-name="Ellipse 29" cx="12" cy="12" r="12"
                                        transform="translate(720 1839)" fill="#4dd970"/>
                                <circle id="Ellipse_30" data-name="Ellipse 30" cx="36" cy="36" r="36"
                                        transform="translate(696 1815)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_31" data-name="Ellipse 31" cx="27" cy="27" r="27"
                                        transform="translate(705 1824)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_32" data-name="Ellipse 32" cx="18" cy="18" r="18"
                                        transform="translate(714 1833)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_33" data-name="Ellipse 33" cx="12" cy="12" r="12"
                                        transform="translate(412 2143.999)" fill="#4dd970"/>
                                <circle id="Ellipse_34" data-name="Ellipse 34" cx="36" cy="36" r="36"
                                        transform="translate(388 2119.999)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_35" data-name="Ellipse 35" cx="27" cy="27" r="27"
                                        transform="translate(397 2128.999)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_36" data-name="Ellipse 36" cx="18" cy="18" r="18"
                                        transform="translate(406 2137.999)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_37" data-name="Ellipse 37" cx="12" cy="12" r="12"
                                        transform="translate(801 2144)" fill="#4dd970"/>
                                <circle id="Ellipse_38" data-name="Ellipse 38" cx="36" cy="36" r="36"
                                        transform="translate(777 2120)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_39" data-name="Ellipse 39" cx="27" cy="27" r="27"
                                        transform="translate(786 2129)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_40" data-name="Ellipse 40" cx="18" cy="18" r="18"
                                        transform="translate(795 2138)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_41" data-name="Ellipse 41" cx="12" cy="12" r="12"
                                        transform="translate(1296 2281.999)" fill="#4dd970"/>
                                <circle id="Ellipse_42" data-name="Ellipse 42" cx="36" cy="36" r="36"
                                        transform="translate(1272 2257.999)" fill="rgba(77,217,112,0.4)"
                                        stroke="#4dd970" strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_43" data-name="Ellipse 43" cx="27" cy="27" r="27"
                                        transform="translate(1281 2266.999)" fill="rgba(77,217,112,0.4)"
                                        stroke="#4dd970" strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_44" data-name="Ellipse 44" cx="18" cy="18" r="18"
                                        transform="translate(1290 2275.999)" fill="rgba(77,217,112,0.4)"
                                        stroke="#4dd970" strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_45" data-name="Ellipse 45" cx="12" cy="12" r="12"
                                        transform="translate(216 2008.999)" fill="#4dd970"/>
                                <circle id="Ellipse_46" data-name="Ellipse 46" cx="36" cy="36" r="36"
                                        transform="translate(192 1984.999)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_47" data-name="Ellipse 47" cx="27" cy="27" r="27"
                                        transform="translate(201 1993.999)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_48" data-name="Ellipse 48" cx="18" cy="18" r="18"
                                        transform="translate(210 2002.999)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                            </g>
                        </svg>
                        <svg className="roadmap__tablet" xmlns="http://www.w3.org/2000/svg" width="752.501"
                             height="1052.001" viewBox="0 0 752.501 1052.001">
                            <g id="Group_12" data-name="Group 12" transform="translate(0 0.001)">
                                <path id="Path_32" data-name="Path 32"
                                      d="M607.5,0s-.5,256-.5,302c0,82.5-67,141.5-168,141.5H169c-67,0-133.5,53.6-133.5,136S102,722,169,722H607.5c103,0,144.5,81.5,144.5,145.5-.667,49.333-30.5,148-144.5,148h-84"
                                      fill="none" stroke="#b8f0c6" strokeWidth="1"/>
                                <circle id="Ellipse_9" data-name="Ellipse 9" cx="12" cy="12" r="12"
                                        transform="translate(595 263)" fill="#4dd970"/>
                                <circle id="Ellipse_10" data-name="Ellipse 10" cx="36" cy="36" r="36"
                                        transform="translate(571 239)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_11" data-name="Ellipse 11" cx="27" cy="27" r="27"
                                        transform="translate(580 248)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_12" data-name="Ellipse 12" cx="18" cy="18" r="18"
                                        transform="translate(589 257)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_13" data-name="Ellipse 13" cx="12" cy="12" r="12"
                                        transform="translate(25 562)" fill="#4dd970"/>
                                <circle id="Ellipse_14" data-name="Ellipse 14" cx="36" cy="36" r="36"
                                        transform="translate(1 538)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_15" data-name="Ellipse 15" cx="27" cy="27" r="27"
                                        transform="translate(10 547)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_16" data-name="Ellipse 16" cx="18" cy="18" r="18"
                                        transform="translate(19 556)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_17" data-name="Ellipse 17" cx="12" cy="12" r="12"
                                        transform="translate(160 711)" fill="#4dd970"/>
                                <circle id="Ellipse_18" data-name="Ellipse 18" cx="36" cy="36" r="36"
                                        transform="translate(136 687)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_19" data-name="Ellipse 19" cx="27" cy="27" r="27"
                                        transform="translate(145 696)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_20" data-name="Ellipse 20" cx="18" cy="18" r="18"
                                        transform="translate(154 705)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_21" data-name="Ellipse 21" cx="12" cy="12" r="12"
                                        transform="translate(513 1003)" fill="#4dd970"/>
                                <circle id="Ellipse_22" data-name="Ellipse 22" cx="36" cy="36" r="36"
                                        transform="translate(489 979)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_23" data-name="Ellipse 23" cx="27" cy="27" r="27"
                                        transform="translate(498 988)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_24" data-name="Ellipse 24" cx="18" cy="18" r="18"
                                        transform="translate(507 997)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_25" data-name="Ellipse 25" cx="12" cy="12" r="12"
                                        transform="translate(488 710)" fill="#4dd970"/>
                                <circle id="Ellipse_26" data-name="Ellipse 26" cx="36" cy="36" r="36"
                                        transform="translate(464 686)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_27" data-name="Ellipse 27" cx="27" cy="27" r="27"
                                        transform="translate(473 695)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_28" data-name="Ellipse 28" cx="18" cy="18" r="18"
                                        transform="translate(482 704)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                            </g>
                        </svg>
                        <svg className="roadmap__mobile" xmlns="http://www.w3.org/2000/svg" width="346"
                             height="1221.004" viewBox="0 0 346 1221.004">
                            <g id="Group_17" data-name="Group 17" transform="translate(0 -0.996)">
                                <path id="Path_83" data-name="Path 83"
                                      d="M308,1s2,216.5,2,262.5c0,95-89.5,116-112,116H153.5C86.5,379.5,37,433.5,37,491S78,610.5,153.5,610.5H198c66,0,112,56,112,114.5S263,839.5,197.5,839.5h-44c-45,0-116.5,30.2-116.5,115S110,1070,153.5,1070h44c39,0,112.5,28.9,112.5,114.5"
                                      fill="none" stroke="#b8f0c6" strokeWidth="1"/>
                                <circle id="Ellipse_109" data-name="Ellipse 109" cx="12" cy="12" r="12"
                                        transform="translate(297 252)" fill="#4dd970"/>
                                <circle id="Ellipse_110" data-name="Ellipse 110" cx="36" cy="36" r="36"
                                        transform="translate(273 228)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_111" data-name="Ellipse 111" cx="27" cy="27" r="27"
                                        transform="translate(282 237)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_112" data-name="Ellipse 112" cx="18" cy="18" r="18"
                                        transform="translate(291 246)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_113" data-name="Ellipse 113" cx="12" cy="12" r="12"
                                        transform="translate(25 478)" fill="#4dd970"/>
                                <circle id="Ellipse_114" data-name="Ellipse 114" cx="36" cy="36" r="36"
                                        transform="translate(1 454)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_115" data-name="Ellipse 115" cx="27" cy="27" r="27"
                                        transform="translate(10 463)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_116" data-name="Ellipse 116" cx="18" cy="18" r="18"
                                        transform="translate(19 472)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_117" data-name="Ellipse 117" cx="12" cy="12" r="12"
                                        transform="translate(25 943)" fill="#4dd970"/>
                                <circle id="Ellipse_118" data-name="Ellipse 118" cx="36" cy="36" r="36"
                                        transform="translate(1 919)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_119" data-name="Ellipse 119" cx="27" cy="27" r="27"
                                        transform="translate(10 928)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_120" data-name="Ellipse 120" cx="18" cy="18" r="18"
                                        transform="translate(19 937)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_121" data-name="Ellipse 121" cx="12" cy="12" r="12"
                                        transform="translate(297 713)" fill="#4dd970"/>
                                <circle id="Ellipse_122" data-name="Ellipse 122" cx="36" cy="36" r="36"
                                        transform="translate(273 689)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_123" data-name="Ellipse 123" cx="27" cy="27" r="27"
                                        transform="translate(282 698)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_124" data-name="Ellipse 124" cx="18" cy="18" r="18"
                                        transform="translate(291 707)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_125" data-name="Ellipse 125" cx="12" cy="12" r="12"
                                        transform="translate(297 1173)" fill="#4dd970"/>
                                <circle id="Ellipse_126" data-name="Ellipse 126" cx="36" cy="36" r="36"
                                        transform="translate(273 1149)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_127" data-name="Ellipse 127" cx="27" cy="27" r="27"
                                        transform="translate(282 1158)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                                <circle id="Ellipse_128" data-name="Ellipse 128" cx="18" cy="18" r="18"
                                        transform="translate(291 1167)" fill="rgba(77,217,112,0.4)" stroke="#4dd970"
                                        strokeWidth="2" opacity="0.08"/>
                            </g>
                        </svg>
                        <div className="roadmap__text" id="one">
                            <div className="roadmap__text__year">2018 December</div>
                            <div className="roadmap__text__title">First identification</div>
                            <div className="roadmap__text__description">Our solution made first face recognition identification</div>
                        </div>
                        <div className="roadmap__text" id="two">
                            <div className="roadmap__text__year">2019 March</div>
                            <div className="roadmap__text__title">Company launched</div>
                            <div className="roadmap__text__description">We became an independent company with its own entity</div>
                        </div>
                        <div className="roadmap__text" id="three">
                            <div className="roadmap__text__year">2019 November</div>
                            <div className="roadmap__text__title">Investment raised</div>
                            <div className="roadmap__text__description">We raised angel investment from Crosspring and VAF</div>
                        </div>
                        <div className="roadmap__text" id="four">
                            <div className="roadmap__text__year">2019 December</div>
                            <div className="roadmap__text__title">eSignatures solution</div>
                            <div className="roadmap__text__description">We integrated Smart-ID and Mobile ID as our first eSignature solutions</div>
                        </div>
                        <div className="roadmap__text" id="five">
                            <div className="roadmap__text__year">2020 October </div>
                            <div className="roadmap__text__title">eSignature platform</div>
                            <div className="roadmap__text__description">We launched eSignature platform as our service
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}
