import React, { Component } from 'react'
import HomePage from '~/js/components/Web/Pages/HomePage'
import { ROUTE_PATHS, ROUTE_NAMES } from '~/js/routes/config'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Contacts from '~/js/components/Web/Pages/Contacts'
import Navigation from '~/js/components/Web/LandingComponents/Navigation'
import { handleTopHeaderStyles } from '~/js/utils/IndexFunctions'
import MobileNav from '~/js/components/Web/LandingComponents/MobileNav'
import Footer from '~/js/components/Web/LandingComponents/Footer'
import { CookieConsentsProvider } from '@enzsft/react-cookie-consents'
import { CookieBanner } from '~/js/components/Web/LandingComponents/CookieConsent/CookieConsent'
import PrivacyPolicy from '~/js/components/Web/Pages/PrivacyPolicy'
import About from '~/js/components/Web/Pages/About'
import Pricing from '~/js/components/Web/Pages/Pricing'
import CareerAchieve from '~/js/components/Web/Pages/CareerAchieve'
import CareerSingle from '~/js/components/Web/Pages/CareerSingle'
import AmlScreening from '~/js/components/Web/Pages/AmlScreening'
import ElectronicSignature from '~/js/components/Web/Pages/ElectronicSignature'
import IdentityVerification from '~/js/components/Web/Pages/IdentityVerification'
import DocumentVerification from '~/js/components/Web/Pages/DocumentVerification'
import Fintech from '~/js/components/Web/Pages/Fintech'
import Gaming from '~/js/components/Web/Pages/Gaming/Gaming'
import Trading from '~/js/components/Web/Pages/Trading'
import MoneyInstitutions from '~/js/components/Web/Pages/MoneyInsitutions'
import ServiceProviders from '~/js/components/Web/Pages/ServiceProviders'
import Support from '~/js/components/Web/Pages/Support'
import NotFound from '../../../components/NotFound'
import PrivacyPolicyLt from '../../../components/Web/Pages/PrivacyPolicyLt'
export default class Landing extends Component {
  constructor(props) {
    super(props)

    this.header = React.createRef()
  }

  componentDidMount() {
    this.handleTopHeaderStyles()
  }

  handleTopHeaderStyles() {
    handleTopHeaderStyles(this.header.current)
  }

  render() {
    return (
      <div id="page-container">
        <BrowserRouter>
          <MobileNav />
          <Switch>
            <Route exact path={ROUTE_PATHS.get(ROUTE_NAMES.HOME_PAGE)}>
              <header className="light" ref={this.header}>
                <Navigation />
              </header>
              <HomePage />
            </Route>
            <Route exact path={ROUTE_PATHS.get(ROUTE_NAMES.CONTACTS)}>
              <header className="dark" ref={this.header}>
                <Navigation />
              </header>
              <Contacts />
            </Route>
            <Route path={ROUTE_PATHS.get(ROUTE_NAMES.AML_SCREENING)}>
              <header className="light" ref={this.header}>
                <Navigation />
              </header>
              <AmlScreening />
            </Route>
            <Route exact path={ROUTE_PATHS.get(ROUTE_NAMES.ELECTRONIC_SIGNATURE)}>
              <header className="light" ref={this.header}>
                <Navigation />
              </header>
              <ElectronicSignature />
            </Route>
            <Route exact path={ROUTE_PATHS.get(ROUTE_NAMES.IDENTITY_VERIFICATION)}>
              <header className="light" ref={this.header}>
                <Navigation />
              </header>
              <IdentityVerification />
            </Route>
            <Route exact path={ROUTE_PATHS.get(ROUTE_NAMES.DOCUMENT_VERIFICATION)}>
              <header className="light" ref={this.header}>
                <Navigation />
              </header>
              <DocumentVerification />
            </Route>
            <Route exact path={ROUTE_PATHS.get(ROUTE_NAMES.PRIVACY_POLICY)}>
              <header className="dark" ref={this.header}>
                <Navigation />
              </header>
              <PrivacyPolicy />
            </Route>
            <Route exact path={ROUTE_PATHS.get(ROUTE_NAMES.PRIVACY_POLICY_LT)}>
              <header className="dark" ref={this.header}>
                <Navigation />
              </header>
              <PrivacyPolicyLt />
            </Route>
            <Route exact path={ROUTE_PATHS.get(ROUTE_NAMES.ABOUT)}>
              <header className="light" ref={this.header}>
                <Navigation />
              </header>
              <About />
            </Route>
            <Route exact path={ROUTE_PATHS.get(ROUTE_NAMES.FINTECH)}>
              <header className="light" ref={this.header}>
                <Navigation />
              </header>
              <Fintech />
            </Route>
            <Route exact path={ROUTE_PATHS.get(ROUTE_NAMES.GAMING)}>
              <header className="light" ref={this.header}>
                <Navigation />
              </header>
              <Gaming />
            </Route>
            <Route exact path={ROUTE_PATHS.get(ROUTE_NAMES.TRADING)}>
              <header className="light" ref={this.header}>
                <Navigation />
              </header>
              <Trading />
            </Route>
            <Route exact path={ROUTE_PATHS.get(ROUTE_NAMES.MONEY_INSTITUTIONS)}>
              <header className="light" ref={this.header}>
                <Navigation />
              </header>
              <MoneyInstitutions />
            </Route>
            <Route exact path={ROUTE_PATHS.get(ROUTE_NAMES.SERVICE_PROVIDERS)}>
              <header className="light" ref={this.header}>
                <Navigation />
              </header>
              <ServiceProviders />
            </Route>
            <Route exact path={ROUTE_PATHS.get(ROUTE_NAMES.PRICING)}>
              <header className="dark" ref={this.header}>
                <Navigation />
              </header>
              <Pricing />
            </Route>
            <Route exact path={ROUTE_PATHS.get(ROUTE_NAMES.CAREER_ACHIEVE)}>
              <header className="dark" ref={this.header}>
                <Navigation />
              </header>
              <CareerAchieve />
            </Route>
            <Route exact path={ROUTE_PATHS.get(ROUTE_NAMES.SUPPORT)}>
              <header className="light" ref={this.header}>
                <Navigation />
              </header>
              <Support />
            </Route>
            <Route
              exact path="/career/:career" render={(props) => {
                return <CareerSingle career={props.match.params.career} />
              }}
            />
            <Route path="*" component={NotFound} />
          </Switch>
          <Footer />
          <CookieConsentsProvider cookieName="accept-cookies" expiryInDays={365}>
            <CookieBanner />
          </CookieConsentsProvider>
        </BrowserRouter>
      </div>
    )}
}
