// @flow

import type { Node } from 'react'
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler'

type Props = {
  active?: boolean,
  message?: string | number,
  title?: string,
  className?: string,
  improveMessage?: string,
  deleteContinue?: boolean,
  onCloseClick: () => void,
  closable?: boolean,
  size?: string,
  children?: any,
}

Modal.defaultProps = {
  closable: true,
  onCloseClick: () => {},
}

export default function Modal(props: Props): Node {
  const { message, deleteContinue, title, improveMessage, className, onCloseClick, closable, size, active, children } = props

  if (!active) {
    return null
  }

  return (
    <div className={'popup' + (className ? ' ' + className : '' ) + ' js-active'}>
      <div className="popup__overlay" />
      <OutsideClickHandler
        onOutsideClick={closable ? onCloseClick : () => {}} >
        <div className={'popup__content' + (size === 'small' ? ' popup__content--small' : (size === 'big' ? ' popup__content--big' : ''))}>
          {closable && (
            <a className="popup__close js-popup__close" onClick={e => {e.preventDefault(); onCloseClick()}} />
          )}
          {(title || message) && !deleteContinue
            ? <div>
              {title && <h3 className="standard-title ta-center">{title}</h3>}
              {message && <p className="ta-justify">{message}</p>}
            </div>
            : improveMessage && <h3 className="standard-title ta-center">{improveMessage}</h3>
          }
          {children}
        </div>
      </OutsideClickHandler>
    </div>
  )
}