import React, { Component } from 'react'
import GreenGraphicElement from '~/img/hero/graphic-element-green.svg'
import ComputerMobile from '~/img/illustration/computer-mobile.svg'
import Documents from '~/img/illustration/documents.svg'
import NumberOne from '~/img/numbers/1.svg'
import Companies from '~/js/components/Web/LandingComponents/Companies'
import { Link } from 'react-scroll'

export default class ElectronicSignature extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const showMoreLinks = document.querySelectorAll('.show-more')
        showMoreLinks.forEach(link => {
            link.addEventListener('click', () => {
                const itemColumnsDescription = link.previousElementSibling
                if (itemColumnsDescription.style.maxHeight) {
                    itemColumnsDescription.style.maxHeight = null
                    link.innerHTML = 'Read more'
                } else {
                    itemColumnsDescription.style.maxHeight = itemColumnsDescription.scrollHeight + "px"
                    link.innerHTML = 'Read less'
                }
            })
        })
        const itemColumnsDescriptions = document.querySelectorAll('.item__columns__description');
        itemColumnsDescriptions.forEach(description => {
            if (description.clientHeight < 120) {
                const showMoreLink = description.nextElementSibling
                showMoreLink.style.display = 'none'
            }
        })
    }

    render() {
        return (
            <main>
                <section className="intro--white" id="intro">
                    <div className="intro__el">
                        <img src={ GreenGraphicElement } alt="" />
                    </div>
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col lg-8-12 md-10-12 text__center">
                                <div className="intro__content platforms__text">
                                    <h1>Qualified Electronic Signatures</h1>
                                    <p className="ta-center">
                                        Convenient, verified and secure electronic signatures for the Baltic States and beyond.
                                        Currently, we support Smart-ID, Mobile-ID, and eParaksts eSignatures, others will be added soon.
                                    </p>
                                    <img src={ Documents } alt="Computer and Mobile" />
                                </div>
                                <Link className="btn btn--primary" to="about" smooth={true}>Learn More</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="text-block" id="about">
                    <div className="grid">
                        <h3 className="ta-center">Replace old-fashioned paper processes with a user-friendly e-signature gateway!</h3>
                        <div className="text-block__list">
                            <div className="text-block__list__item">
                                <img src={ ComputerMobile} alt="Documents" />
                                <div className="text">
                                    <h2>Electronic signature is equivalent to a handwritten one:
                                        <img className="number" src={ NumberOne } alt="Number One" />
                                    </h2><br/>
                                    <div className="list list--square list--square--green">
                                        <ul>
                                            <li>Sign documents by using qualified eSignature via your Smart-ID, Mobile-ID.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="item__columns">
                                    <ul>
                                        <li>
                                            <h5>Legally binding signature</h5>
                                            <p className="item__columns__description">
                                                an eSignature is just as legally binding as a physical signature
                                            </p>
                                            <a className="show-more">Read More</a>
                                        </li>
                                        <li>
                                            <h5>Storage of documents</h5>
                                            <p className="item__columns__description">
                                                all documents are stored securely in the client’s information systems or servers
                                            </p>
                                            <a className="show-more">Read More</a>
                                        </li>
                                        <li>
                                            <h5>User-friendly approach</h5>
                                            <p className="item__columns__description">
                                                provide your customers with a convenient and safe way to sign documents anytime and anywhere
                                            </p>
                                            <a className="show-more">Read More</a>
                                        </li>
                                    </ul><br/>
                                    <ul>
                                        <li>
                                            <h5>Quick setup</h5>
                                            <p className="item__columns__description">
                                                no need to install any software or additional hardware
                                            </p>
                                            <a className="show-more">Read More</a>
                                        </li>
                                        <li>
                                            <h5>Time-saving process</h5>
                                            <p className="item__columns__description">
                                                sign documents quickly
                                            </p>
                                            <a className="show-more">Read More</a>
                                        </li>
                                        <li>
                                            <h5>Reduction of standard administrative procedures</h5>
                                            <p className="item__columns__description">
                                                reduce manual work: no more printing or scanning
                                            </p>
                                            <a className="show-more">Read More</a>
                                        </li>
                                    </ul><br/>
                                    <ul>
                                        <li>
                                            <h5>Increase conversion rates</h5>
                                            <p className="item__columns__description">
                                                provide customers with an effective way to sign documents results
                                                in an ability to serve more customers simultaneously
                                            </p>
                                            <a className="show-more">Read More</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Companies />
            </main>
        )
    }
}
