import React, { Component } from 'react'

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <main>
        <section id="privacy-policy">
          <div className="grid">
            <div className="row row--hor-center">
              <div className="col lg-8-12 md-10-12">
                <article>
                  <h2 className="ta-center">PRIVACY POLICY</h2>
                  <p>This Privacy Policy (hereinafter referred to as the Privacy Policy) provides information about what your personal data, for what purpose and on what basis we process, how long we store it, to whom we can transfer information about you, and what rights you have as a data subject.</p>
                  <h3>PERSONAL DATA CONTROLLER</h3>
                  <p>The controller of the personal data processing operations described in this Privacy Policy, which determines the purposes and means of data processing, is UAB Mark ID (further in this document referred to as we, the Company or Data Controller), legal entity number 305098955, address Žalgirio g. 90-100, Vilnius, e-mail address info@markid.lt, website <u><a href="http://www.markid.eu/">www.markid.eu</a></u>.</p>
                  <p>Please note that this Privacy Policy also provides information about the data processing activities carried out by us as a data processor on behalf of our customers, when the Company is not the data controller of your personal data. We only provide services to our customers as a data processor in accordance with their instructions. This Privacy Policy provides the basic information about our data processing activities on behalf of our customers, including the categories of personal data we process. We would like to emphasise that in such cases we do not independently determine the purposes or means of data processing and do not control the personal data provided to us by our customers. If you have any questions or concerns about the processing of your personal data or if you wish to exercise your rights as a data subject, we encourage you to contact the relevant data controller directly.</p>
                  <h3>DATA PROTECTION OFFICER</h3>
                  <p>A data protection officer has been appointed in the Company.</p>
                  <p>If you would like to check or find out how we process your personal data, or if you intend to exercise your rights as a data subject, you can contact the data protection officer appointed by the Company by e-mail: dap@juridicon.lt.</p>
                  <h3>PURPOSE AND APPLICATION OF PRIVACY POLICY</h3>
                  <p>this Privacy Policy provides you with information about how the Company processes your personal data. Additional information may be provided in separate notices, service and other contracts that you will enter into with the Company.</p>
                  <p>This Privacy Policy does not discuss how the Company processes personal data when you visit other websites of the Company, use the services published by the Company on other websites or provided through them. If you visit other websites of the Company, use the services the Company publishes on or provides through such websites, your personal data will be processed based on the information specified on those websites. In this case, please properly read the privacy policy published on the other websites of the Company.</p>
                  <p>The processing of personal data in the Company is regulated by the Law on Legal Protection of Personal Data of the Republic of Lithuania, Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation)(hereinafter referred to as the GDPR or the Regulation) and other legal acts as well as internal documents, policies and procedures of the Company.</p>
                  <p>The terms used in this Privacy Policy (e.g., the data controller, data processor) must be understood as defined and interpreted in the Regulation.</p>
                  <h3>SOURCES OF PERSONAL DATA</h3>
                  <p>Personal data processed by the Company may be obtained from the following sources:</p>
                  <ul className="privacy-list-bullet">
                    <li>
                      <p>directly from a data subject (e.g., when you use our website www.markid.eu);</p>
                    </li>
                    <li>
                      <p>from third parties (e.g., you have provided your personal data to the companies operating job search sites);</p>
                    </li>
                    <li>
                      <p>indirectly from you (e.g., from the persons you represent).</p>
                    </li>
                  </ul>
                  <p>Please be informed that you are not obligated to provide any personal data, but it is possible that as a result we will not be able to provide you with services and achieve other set goals.</p>
                  <h3>PURPOSES AND BASES OF PERSONAL DATA PROCESSING, PERSONAL DATA CATEGORIES, RETENTION PERIODS AND RECIPIENTS</h3>

                  <h4>PERSONAL DATA PROCESSING OPERATIONS WHEN THE COMPANY ACTS AS A DATA PROCESSOR</h4>
                  <p>As a data processor, we collect and process personal data on behalf of our clients when we provide them with services of remote identification and screening of persons against the databases of politically exposed persons and financial sanctions.</p>
                  <p>If you have any questions or uncertainties about the processing of your personal data or if you wish to exercise your rights as a data subject, we encourage you to contact the relevant data controller directly. We will forward your questions and requests for exercising of your rights to the relevant data controllers for further action.</p>

                  <h4>Provision of remote identification service</h4>
                  <p>Acting as a data processor and providing remote identification services, we process personal data of the persons being identified on the instructions of the data controller. The type of personal data collected may vary depending on the services provided to customers and the instructions received from them. These can be, among other things, the following categories of personal data: the name, surname, personal identification number, date of birth, face photo, photo of the identity document, passport cover photo, photo of the overleaf of the identity document, IP address, expiration date of the document, document type, document number, date when the photo was taken, video, user's operating system, IP address. Special categories of personal data are also processed for this purpose: your image (biometric data).</p>
                  <p>The legal basis for the processing of these data is determined by the data controller, i.e., The Company&rsquo;s customer whose services you use.</p>
                  <p>The retention period of this data is determined by the data controller. We store personal data processed on behalf of clients for as long as necessary for the purpose for which they were collected, fulfilled or as required by the data controller and / or legal acts.</p>

                  <h4>Provision of a service for the screening of individuals against databases of politically exposed persons and financial sanctions</h4>
                  <p>Acting as a data processor and providing the service of screening of individuals against databases of politically exposed persons and financial sanctions, we process the personal data of the persons being checked on the instructions of the data controller. The type of personal data collected may vary depending on the services provided to customers and the instructions received from them. These may include, among other things, the following categories of personal data: the name, surname, date of birth, country, political party, sanctions imposed.</p>
                  <p>The legal basis for the processing of these data is determined by the data controller, i.e., The Company&rsquo;s customer whose services you use.</p>
                  <p>The retention period of this data is determined by the data controller. We store personal data processed on behalf of clients for as long as necessary for the purpose for which they were collected, fulfilled or as required by the data controller and / or legal acts.</p>

                  <h4>PERSONAL DATA PROCESSING OPERATIONS WHEN THE COMPANY ACTS AS A DATA CONTROLLER</h4>
                  <h4>Search and selection of potential employees</h4>
                  <p>In order to carry out the search for employees for job openings in the Company, we may process the following personal data of applicants for job openings: the name, surname, date of birth, telephone number, personal e-mail address, curriculum vitae (CV), qualification data and other information necessary for the purpose of recruitment, test results, and evaluation of the selection interview.</p>
                  <p>The legal basis for data processing: the processing is necessary for the performance of a contract or or in order to take steps prior to entering into a contract (Art. 6(1)(b) of the Regulation). 1 d. b p.).</p>
                  <p>The data retention period: data is deleted after the end of the selection for a specific job position.</p>

                  <h4>Entering into and performance of contracts</h4>
                  <p>In order to conclude and execute services, purchase and sale or any other contracts, we may process the following personal data of customers, other parties to the contract: the name, surname, telephone number, address, e-mail address, job title, correspondence conducted in the interests of the service provider and customer, the data contained in the content of the contract, and the signature. If the contract is concluded with a legal entity, we may additionally process the personal data of the person authorised to act on behalf of the legal entity.</p>
                  <p>The legal basis for data processing: the processing is necessary for the performance of a contract or or in order to take steps prior to entering into a contract (if the contract is made with a legal entity) (Art. 6(1)(b) of the Regulation). the legitimate interests pursued by the data controller in the proper conclusion and performance of contracts (if the contract is made with a legal entity) (Art. 6(1)(f) of the Regulation). 1 d. f p.).</p>
                  <p>The data retention period: 10 years after expiry of the contract and performance of obligations in full.</p>

                  <h4>Administration of payments for services provided by the Company</h4>
                  <p>To manage payments for the services provided by the Company, we may process the following personal data of customers (natural persons) and representatives of customers (legal entities): the name, surname, address, payment card details (name, surname, type of payment card, card number, CVV code, bank account number), signature, data justifying the fact of representation (only concerning the representatives of legal entities).</p>
                  <p>The legal basis for data processing: the processing is necessary for the performance of a contract or or in order to take steps prior to entering into a contract (if the contract is made with a legal entity) (Art. 6(1)(b) of the Regulation). the legitimate interests pursued by the data controller in the proper conclusion and performance of contracts (if the contract is made with a legal entity) (Art. 6(1)(f) of the Regulation). 1 d. f p.).</p>
                  <p>The data retention period: 10 years after expiry of the contract and performance of obligations in full.</p>

                  <h4>Financial accounting</h4>
                  <p>In order to do bookkeeping, we may process the following personal data of customers, suppliers, buyers and sellers: the name, surname, personal identification number, home address, phone number, e-mail address, settlement account number.</p>
                  <p>The legal basis for data processing: the legitimate interest of the data controller in conducting of financial accounting (Art. 6(1)(f) of the Regulation), and the legal obligation of the Data Controller (Art. 6(1)(c) of the Regulation). 1 d. c p.).</p>
                  <p>The data retention period: 10 years after expiry of the contract and performance of obligations in full.</p>

                  <h4>Debt collection</h4>
                  <p>In order to ensure compliance with contracts and recover debts, we may process the following personal data of debtors: the name, surname, contact information (phone number, e-mail), address, debt information (amount, data substantiating the fact of debt), personal identification number, property situation, information about family members.</p>
                  <p>The legal basis for data processing: The Data Controller&rsquo;s legitimate interest in ensuring performance of contracts and recovery of arrears (Art. 6(1)(f) of the Regulation). 1 d. f p.).</p>
                  <p>The data retention period: 10 years after expiry of the contract and performance of obligations in full.</p>
                  <p>Recipients of data: the data can be transferred to companies providing debt collection services.</p>

                  <h4>Organising and holding of online meetings</h4>
                  <p>In order to organise and conduct conference calls, online meetings, video conferences and / or webinars, we may process the following personal data of the persons participating in online meetings: the information about the attendees (the name, surname, phone number, e-mail address, profile picture, department), data about the meeting (the subject, description, IP address of attendees, computer hardware information, the start and end time of the video conference), text data, voice and video recordings.</p>
                  <p>The legal basis for data processing: The Data Controller's legitimate interests to ensure organising and holding of remote meetings, convenient and secure communication (Art. 6(1)(f) of the Regulation), and consent of the data subject (Art. 6(1)(f) of the Regulation) (to recording of an online meeting).</p>
                  <p>The data retention period: the data will be processed for as long as it is necessary for organising and holding of online meetings, and provision of related services.</p>

                  <h4>Processing of information about the Company&rsquo;s shareholders and board members</h4>
                  <p>In order to process data about the Company's shareholders and board members and to comply with the legal obligations imposed on the Company, we may process the following personal data of shareholders and board members: the name, surname, personal identification number, home address, date of appointment, contact details, and the number of shares held.</p>
                  <p>The legal basis for data processing: the legal obligation of the Data Controller (Art. 6(1)(c) of the Regulation). 1 d. c p.).</p>
                  <p>The data retention period: 10 years from the period when the data subject whose data was processed ceased to be a shareholder or a board member of the Company.</p>

                  <h4>Submission of data on ultimate beneficial owners to sub-system JANGIS of the Information System of Participants of Legal Entities (JADIS)</h4>
                  <p>In order to process data about the Company's ultimate beneficial owners and to comply with the obligations imposed on the Data Controller, we may process the following personal data of the ultimate beneficial owners: the name, surname, date of birth, personal identification number, state that issued the identity document, place of residence, the stake held and its scope (the number of shares as percentage, voting rights as percentage) or other control rights (the chair of the board, member of the board, head / CEO, senior executive, other positions, the percentage of voting rights assigned).</p>
                  <p>The legal basis for data processing: the legal obligation of the Data Controller (Art. 6(1)(c) of the Regulation). 1 d. c p.).</p>
                  <p>The data retention period: 8 years from the date of deletion of information about beneficial owners from the Information System of Participants of Legal Entities (JADIS).</p>

                  <h4>Handling of inquiries</h4>
                  <p>In order to handle the requests and inquiries submitted to the Company, we may process the personal data of the following persons who have submitted requests: the name, surname, contact information (phone number, e-mail), information provided in the request / inquiry, signature.</p>
                  <p>The legal basis for data processing: legitimate interests of the Data Controller to ensure proper handling of inquiries / requests (Art. 6(1)(f) of the Regulation). 1 d. f p.).</p>
                  <p>The data retention period: the data retention period depends on the type of inquiry / request. The specific period is determined in accordance with the index for the general time limits of document storage approved by the Chief Archivist of Lithuania.</p>

                  <h4>Direct marketing</h4>
                  <p>When sending direct marketing messages and invitations to participate in the survey, we process the following personal data of persons who have subscribed to direct marketing messages: the name, surname, e-mail, represented company (concerning representatives of legal entities), information about reading statistics, LinkedIn profile.</p>
                  <p>The legal basis for data processing: consent of the data subject (Art. 6(1)(a) of the Regulation), and the legitimate interests of the Data Controller to provide direct marketing offers (Art. 6(1)(f) of the Regulation). 1 d. f p.).</p>
                  <p>The data retention period: 5 years.</p>

                  <h4>Website improvement, visitor statistics, provision of electronic services (use of cookies)</h4>
                  <p>In order to improve our website, collect and analyse the statistics of website visitors and ensure proper provision of electronic services as well as functionalities of the website, we use cookies, plug-ins and similar technologies on the websites operated by the Company. For the above-mentioned purpose, the Company processes the following personal data: the browser of the visitors' computers and its version, the choice of language, the region, the browsing time, the established time zone, the data of logging into the user's account when using the websites, other information collected by cookies.</p>
                  <p>The legal basis for data processing: consent of the data subject (Art. 6(1)(a) of the Regulation) (personal data collected by optional cookies are processed on this basis) and the Data Controller's legitimate interest in ensuring proper functioning of the website and maintaining of its functionalities as well as provision of electronic services (Art. 6(1)(F) of the GDPR) (personal data collected by mandatory cookies are processed on this basis).</p>
                  <p>The data retention period: the retention period of personal data depends on the specific cookie by means of which the personal data was collected, but in all cases the data retention period is not more than 2&nbsp;years.</p>

                  <h3>COOKIES</h3>
                  <p>Cookies used on the website are small parts of text information that are automatically generated when browsing the website, and are stored on a computer or other terminal device. The information collected by cookies allows us to ensure more convenient browsing of the website, to make offers or suggestions and to learn more about the behaviour of our website users, analyse trends and improve the website. If the Company's website contains links to other websites that also use cookies, they are not described here.</p>
                  <p>We ask for your consent before we store functional, tracking, advertising cookies and /or third-party cookies. When the strictly necessary cookies are stored, we use them on the basis of a legitimate interest and do not ask for your consent to the storage of such cookies. If we have already received your consent, when reusing the same cookie for the same purpose in the future, we will not ask for consent again. The same applies to the cookies used by third parties.</p>
                  <p>The given consent to the use of non-essential cookies can be withdrawn at any time by changing the settings of the web browser, and disabling all cookies or disabling (enabling) them one by one. Please note that in some cases this may slow down the speed of browsing the Internet, limit the functionality of certain websites or block access to the site. To set the strictly necessary (desired) cookie options, it is recommended to use the functions available in the cookie message or in web browsers.</p>
                  <p>We use the following types of cookies on websites:</p>
                  <p><strong>Strictly necessary cookies</strong>. Necessary in order to be able to use the functions of the website, log in to the user account. You would not be able to use our electronic services without these cookies.</p>
                  <p><strong>Performance (session) cookies</strong>. They are designed to improve functioning of the website and collect general anonymous information about the use of the website.</p>
                  <p><strong>Tracking cookies </strong>(tracking cookies from Google Analytics). Allows the Company to recognise and count website visitors and monitor how visitors move around the website when using it. This helps to improve the performance of the site, for example, to ensure that users can easily find what they are looking for.</p>
                  <p><strong>Functional cookies.</strong> Used to recognise site visitors when they return to the website. This allows the Company to provide content tailored to the needs of visitors on social media, to remember information relevant to visitors.</p>
                  <p><strong>Advertising cookies</strong>. Used to display advertisements that should be of interest specifically to you and be in your interests. They can also be used to help measure effectiveness of an advertising campaign.</p>
                  <h3>Cookies used on the website</h3>
                  <div className="cookies-details" style={{ overflowX: "auto" }}>
                    <table className="privacy_cookies_table">
                      <tbody>
                      <tr>
                        <td>
                          <p><strong>Name</strong></p>
                        </td>
                        <td>
                          <p><strong>Supplier</strong></p>
                        </td>
                        <td>
                          <p><strong>Purpose</strong></p>
                        </td>
                        <td>
                          <p><strong>Expiration</strong></p>
                        </td>
                        <td>
                          <p><strong>Group</strong></p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>_ga</p>
                        </td>
                        <td>
                          <p>Google</p>
                        </td>
                        <td>
                          <p>Google Analytics is used to monitor the speed of requests.</p>
                        </td>
                        <td>
                          <p>1 year 1&nbsp;month</p>
                        </td>
                        <td>
                          <p>Statistics</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>_ga_#</p>
                        </td>
                        <td>
                          <p>Google</p>
                        </td>
                        <td>
                          <p>Used by Google Analytics to collect data on the number of times a user has visited the website as well as dates for the first and most recent visit.</p>
                        </td>
                        <td>
                          <p>1 year 1&nbsp;month</p>
                        </td>
                        <td>
                          <p>Statistics</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>_gid</p>
                        </td>
                        <td>
                          <p>Google</p>
                        </td>
                        <td>
                          <p>Registers a unique ID, which is needed to generate statistics on how the Data Subject uses the Website.</p>
                        </td>
                        <td>
                          <p>1 day</p>
                        </td>
                        <td>
                          <p>Statistics</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>_gat</p>
                        </td>
                        <td>
                          <p>Google</p>
                        </td>
                        <td>
                          <p>_gat cookie is used to limit the amount of data recorded by Google on high traffic volume websites.</p>
                        </td>
                        <td>
                          <p>1 day</p>
                        </td>
                        <td>
                          <p>Statistics</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>_dc_gtm_UA-#</p>
                        </td>
                        <td>
                          <p>Google</p>
                        </td>
                        <td>
                          <p>Used by Google Tag Manager to control the loading of a Google Analytics script tag.</p>
                        </td>
                        <td>
                          <p>1 day</p>
                        </td>
                        <td>
                          <p>Statistics</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>_hjAbsoluteSessionInProgress</p>
                        </td>
                        <td>
                          <p>HotJar</p>
                        </td>
                        <td>
                          <p><br /> This cookie is used to count the number of times the Website has been visited by different Data Subjects &ndash; this is done by providing the Data Subject with an ID so that he/she is not registered twice.</p>
                        </td>
                        <td>
                          <p>1 day</p>
                        </td>
                        <td>
                          <p>Statistics</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>_hjFirstSeen</p>
                        </td>
                        <td>
                          <p>HotJar</p>
                        </td>
                        <td>
                          <p>This cookie is used to determine whether the Data Subject has visited the Website before or if they are a new visitor.</p>
                        </td>
                        <td>
                          <p>1 day</p>
                        </td>
                        <td>
                          <p>Statistics</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="td-overflow">_hjIncludedInSessionSample_#</p>
                        </td>
                        <td>
                          <p>HotJar</p>
                        </td>
                        <td>
                          <p>Determines whether the Data Subject&rsquo;s browsing should be recorded in a particular statistical location.</p>
                        </td>
                        <td>
                          <p>1 day</p>
                        </td>
                        <td>
                          <p>Statistics</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>_hjSession_#</p>
                        </td>
                        <td>
                          <p>HotJar</p>
                        </td>
                        <td>
                          <p>Collects statistics on the visitor's visits to the website, such as the number of visits, average time spent on the website and what pages have been read.</p>
                        </td>
                        <td>
                          <p>1 day</p>
                        </td>
                        <td>
                          <p>Statistics</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>_hjSessionUser_#</p>
                        </td>
                        <td>
                          <p>HotJar</p>
                        </td>
                        <td>
                          <p>Collects statistics on the visitor's visits to the website, such as the number of visits, average time spent on the website and what pages have been read.</p>
                        </td>
                        <td>
                          <p>1 year</p>
                        </td>
                        <td>
                          <p>Statistics</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>_hjCookieTest</p>
                        </td>
                        <td>
                          <p>HotJar</p>
                        </td>
                        <td>
                          <p>Records statistical data about the Data Subject&rsquo;s activity on the Website. This data is used by the Website&rsquo;s operator for internal analysis.</p>
                        </td>
                        <td>
                          <p>1 session</p>
                        </td>
                        <td>
                          <p>Statistics</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>collect</p>
                        </td>
                        <td>
                          <p>Google</p>
                        </td>
                        <td>
                          <p>Used to send data about the Data Subject&rsquo;s activity and device used to Google Analytics. The Data Subject is also tracked using different devices and advertising channels.</p>
                        </td>
                        <td>
                          <p>1 session</p>
                        </td>
                        <td>
                          <p>Statistics</p>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>

                  <h3>SOCIAL PLUGINS</h3>
                  <p>The websites operated by the Company use plug-ins (social plugins). The social plugins are installed on the website to allow its users to be redirected to the Company's accounts on social media or the Company's correspondence window on communication platforms.</p>
                  <p>By clicking on the plugin icon, you are redirected to the page of the plugin operator and the plugin operator is provided with data which page the query was made from, the time and date of the query. Plugins are recognisable from the Facebook, X and LinkedIn logos.</p>
                  <p>The information that a person provides on the page of the plugin operator or that is obtained when a person visits the links of the plugins on the Company's website is controlled by the plug-in operators. Information about the personal data collected and stored, the legal bases for data processing, the data retention periods, the technical and organisational security measures applied is contained in the privacy notices of the plugin operators.</p>

                  <h3>PERSONAL DATA PROCESSING ON SOCIAL MEDIA</h3>
                  <p>Promoting the Company's awareness (administration of social media)</p>
                  <p>The information that you will provide in the social media profiles managed by the Company (including notifications, the use of the "Like" and "Follow" buttons, and other communication) or that is obtained when you visit the Company's accounts (including information received by means of cookies used by social media operators) is controlled by the social media operator (controller). That is why, we recommend that you read the privacy notices of the social media operator.</p>
                  <p>As a social media account administrator, we select the appropriate settings based on our target audience as well as the objectives of performance management and promotion. By giving the Company the opportunity to create an account on the social media and administer it, the social media operator may limit possibilities to change certain essential settings, and thus we cannot influence what information about you will be collected by the social media operator after the Company creates an account on the social media.</p>
                  <p>All such settings may affect the processing of personal data when you use social media, visit the Company's account or read the Company's posts on the social media network. Even if you just view our messages on Facebook, LinkedIn, Instagram or YouTube, the social media operator may receive certain personal information, such as the type of the terminal device you use, your IP address, etc.</p>
                  <p>As a rule, your personal data (even those collected by us when we choose additional account settings) is processed by the social media operator for the purposes set by the social media operator in accordance with the privacy policy of the social media operator. None the less, when you use the social media, communicate with the Company through the social media, visit the Company's account on the social media or view the Company's posts on it, the Company receives information about you. The volume of incoming data depends on the account settings selected by us, agreements with the social media operator on ordering additional services, and cookies set by the social media operator.</p>
                  <p>The legal basis for data processing: Article 6(1)(f) of the GDPR (the processing of data is necessary for the legitimate interests of the Company to ensure its business development).</p>

                  <h3>PERSONAL DATA RECIPIENTS AND PROCESSORS</h3>
                  <p>We may provide your personal data to the following persons, taking into account the basis for providing the data and ensuring security of the data being transferred:</p>
                  <ul className="privacy-list-bullet">
                    <li>
                      <p>to the personal data processors chosen by the Company for the purpose of lawful personal data processing actions on behalf of the Company and / or on its instructions;</p>
                    </li>
                    <li>
                      <p>debt collection agencies;</p>
                    </li>
                    <li>
                      <p>other persons (lawyers, consultants, offices of notaries, judicial officers, auditors, etc.) whom the Company uses for the provision of the services necessary for the Company and/or you;</p>
                    </li>
                    <li>
                      <p>business partners;</p>
                    </li>
                    <li>
                      <p>State enterprise Centre of Registers (<em>VĮ Registrų centras</em>);</p>
                    </li>
                    <li>
                      <p>social media operators;</p>
                    </li>
                    <li>
                      <p>state institutions, law enforcement agencies and other persons in the manner defined by the legal acts of the Republic of Lithuania or when the provision of data is necessary for the establishment, exercise or defence of the Company's legal claims.</p>
                    </li>
                  </ul>
                  <p>With your consent, we may also provide personal data to other persons.</p>
                  <p>If we disclose your personal data to groups of data recipients other than those specified in this Privacy Policy, we will inform you about it no later than when disclosing the data for the first time, except if we have already provided such information to you earlier.</p>
                  <p>We may engage data processors who, in accordance with our instructions and to the extent determined by us, will process personal data to the extent necessary to achieve the purposes of the data processing. When using data processors, we strive to ensure that the data processors have also implemented appropriate organizational and technical measures ensuring security, and that they maintain the secrecy of personal data.</p>

                  <h3>TRANSFERS OUTSIDE THE EU/EEA</h3>
                  <p>Your data may be transferred outside the European Union by signing agreements with data processors that comply with the requirements of European Union legislation.</p>
                  <p>Data may be transferred outside the European Union when the transfer is necessary for the conclusion and execution of contracts, proper provision of services to the Company's customers. In such case, the Company takes steps to ensure that any transfer of personal data outside the EU / EEA is properly carried out and the data subjects&rsquo; rights to privacy are protected to the maximum. When transferring personal data outside the EU/EEA, the Company is guided by:</p>
                  <ul className="privacy-list-bullet">
                    <li>
                      <p>the adequacy decision taken by the European Commission regarding the relevant foreign country;</p>
                    </li>
                    <li>
                      <p>the certification mechanism approved in a foreign country;</p>
                    </li>
                    <li>
                      <p>the decision of the European Commission on standard contractual clauses.</p>
                    </li>
                  </ul>
                  <p>A third country outside the EU/EEA in which the recipient of the personal data is located must, by decision of the European Commission, ensure an adequate (sufficient) level of protection of personal data.</p>

                  <h3>PROFILING AND AUTOMATED DECISION-MAKING</h3>
                  <p>The Company does not carry out profiling and automated decision-making that would have significant consequences for you or would have a significant impact on you.</p>

                  <h3>RIGHTS OF DATA SUBJECTS</h3>
                  <p>As a data subject, you have the following rights indicated in this section:</p>

                  <h4>Right to know (be informed) about processing of your personal data</h4>
                  <p>When we receive personal data directly from you, we inform you about the processing of personal data at the time of receipt of this personal data orally and / or in writing.</p>
                  <p>If we do not receive personal data directly from you, we will inform you about the processing of your personal data within one month from the receipt of the data, and if we use your personal data for communication with you, we will inform you about it no later than the first time we contact you.</p>

                  <h4>Right to access your personal data processed by us</h4>
                  <p>You have the right to submit a request to the Company for access to personal data processed by the Company. Within one month from the receipt of the request, we will check whether your personal data is processed by the Company. Having determined that we process your personal data, we will provide you with information about the personal data processed, their retention period, information from what sources and what personal data have been collected, for what purpose they are processed, to whom they are transferred, and we will provide you with a copy of such personal data.</p>
                  <p>We may extend the time limit for replying if necessary. We will inform you about it. You have the right to file a complaint with the State Data Protection Inspectorate concerning the extension of such time limit.</p>
                  <p>Your data may be provided free of charge once a calendar year, but in other cases the Company may charge for the provision of data up to the costs of data provision.</p>
                  <p>If your requests are manifestly unfounded or excessive, we have the right to refuse to comply with your request.</p>

                  <h4>Right to rectification of personal data</h4>
                  <p>You have the right to request the rectification of inaccurate, incorrect or incomplete personal data.</p>
                  <p>We will notify you whether the rectification or destruction of personal data requested by you has been performed or not. We will inform your data recipients about it, unless it is impossible or too difficult to provide such information. At your request, we will provide you with information about such data recipients.</p>

                  <h4>Right to restriction of data processing</h4>
                  <p>You have the right to request restriction of data processing if:</p>
                  <ul className="privacy-list-bullet">
                    <li>
                      <p>You are contesting accuracy of the data. The processing of data is restricted for the period during which the Company is able to verify their accuracy.</p>
                    </li>
                    <li>
                      <p>The processing of personal data is unlawful. If it is established that the Company is processing your personal data unlawfully, you have the right to request restriction of processing of the personal data processed unlawfully.</p>
                    </li>
                    <li>
                      <p>You seek to defend legal claims. When the data retention period has expired, but this data is necessary for you, you can restrict processing of this data in order to establish, exercise or defend legal claims.</p>
                    </li>
                    <li>
                      <p>You have expressed your objection to the data processing. The processing will be restricted until verification of which interests override.</p>
                    </li>
                  </ul>
                  <p>We may continue processing of restricted personal data, except for their storage, only with your separate consent or in order to defend the Company's legal claims, protect the rights of natural persons or for reasons of substantial public interest. Before removing the restriction on the processing of personal data, we will inform you accordingly.</p>
                  <h4>Right to destruction / erasure of personal data ("right to be forgotten")</h4>
                  <p>In the cases set out in Article 17 of the General Data Protection Regulation, you have the right to request destruction of personal data if you find that personal data are being processed unlawfully or fraudulently.</p>
                  <p>The Company may refuse to destroy personal data in the cases specified in Article 17 of the General Data Protection Regulation.</p>
                  <p>We will notify you whether the destruction of personal data requested by you has been performed or not. We will inform your data recipients about it, unless this is impossible or requires a disproportionate effort. At your request, we will provide you with information about such data recipients.</p>
                  <h4>Right to object to processing of personal data, except if we process personal data for the legitimate interest of the Company or another person to whom personal data is provided, and your interests are not overriding.</h4>
                  <p>If you object to the processing of personal data, we will continue to process your personal data if we make a well-grounded decision that the reasons for the processing of personal data override your interests, rights and freedoms, or if your personal data is necessary to establish, exercise or defend legal claims.</p>
                  <h4>Right to withdraw consents to data processing</h4>
                  <p>You have the right to withdraw all your consents to the processing of personal data at any time when the basis for the processing of your personal data is your consent.</p>
                  <h4>Right to data portability</h4>
                  <p>You have the right to request that the personal data provided by you (if it is processed on the basis of consent or contract, and if it is processed by automated means) be transferred to another data controller or transferred to you in a structured, commonly used and machine-readable format, if this is technically possible. When applying to exercise this right, you must indicate whether you want the personal data to be transferred to you or directly to another data controller.</p>
                  <p>The right to data portability cannot be exercised concerning the personal data that are processed in files compiled manually, such as paper files.</p>
                  <h4>Right to lodge a complaint regarding the processing of personal data to the State Data Protection Inspectorate or the competent court</h4>
                  <p>You can appeal against the Company's actions or omissions related to exercising of the data subject rights yourself or through a duly authorised representative or a non-profit institution, organisation or association that meets the requirements of Article 80 of the Regulation by lodging a complaint with the State Data Protection Inspectorate, at L. Sapiegos g. 17, Vilnius, e-mail address ada@ada.lt, website https://vdai.lrv.lt as well as appealing to the competent court of the Republic of Lithuania.</p>
                  <h4>Right to compensation for damage suffered as a result of a breach of the rights of the data subject</h4>
                  <p>Having sustained damage as a result of a breach of the rights of the data subject, you have the right to compensation, for the award of which you must apply to the competent court of the Republic of Lithuania.</p>
                  <h3>PROCEDURE FOR EXERCISING DATA SUBJECT RIGHTS</h3>
                  <p>You can contact to the Company in order to exercise your rights as the data subject orally or in writing by submitting a request in person, by mail or by electronic means using the contacts specified in this Privacy Policy.</p>
                  <p>If you decide to apply orally for the exercise of the rights of the data subject, you must prove your identity by providing an identity document. If this is not done, we will not be able to accept your requests, and the rights of the data subject will not be exercised. This provision does not apply if you contact us for information on the processing of personal data in accordance with Articles 13 and 14 of the General Data Protection Regulation.</p>
                  <p>If you decide to apply in writing (by mail) for the implementation of the rights of the data subject, you must enclose a copy of the identity document certified in accordance with the procedure established by legal acts with the request.</p>
                  <p>If you decide to submit a request by electronic means, the request must be signed with a qualified electronic signature or it must be formed by electronic means which allow ensuring the text integrity and that it cannot be modified. This provision does not apply if you contact us for information on the processing of personal data in accordance with Articles 13 and 14 of the General Data Protection Regulation.</p>
                  <p>The request to exercise rights of the data subject must be legible, signed, and must contain your name, surname, address and (or) other contact details for communication or by which you wish to receive a response.</p>
                  <p>If you have decided to exercise your rights through a representative, your representative must indicate his/her name, surname, address and (or) other contact details for communication, by which your representative wishes to receive a response, as well as indicate your name and surname and other data that are necessary for the proper identification of the data subject and provide a document justifying the representation or a certified copy of such document.</p>
                  <p>If we have doubts about your identity and data, we have the right to request additional information necessary to verify it.</p>
                  <p>For all questions related to the processing of personal data and the exercise of your rights, you can contact the Data Protection Officer of the Company. We recommend that you indicate that the correspondence is addressed to the Company's Data Protection Officer.</p>
                  <h3>FINAL PROVISIONS</h3>
                  <p>The law of the Republic of Lithuania applies to implementation and interpretation of provisions of this Privacy Policy.</p>
                  <p>If any provision of the Privacy Policy becomes or is invalidated, the remaining provisions shall remain in effect.</p>
                  <p>This Privacy Policy is valid from the date of its publication on the website. This Privacy Policy is not considered to be an agreement between the Company and you regarding the processing of personal data. With this Privacy Policy, the Company informs you about the principles of processing your personal data in the Company. We may change the Privacy Policy at any time. All changes to the Privacy Policy are published on the website. Amendments and (or) supplements to the Privacy Policy shall take effect after the moment of their publication on the website. We recommend that you review our Privacy Policy regularly.</p>
                  <small>Last updated on 1 September 2023.</small>
                </article>
              </div>
            </div>
          </div>
        </section>
      </main>
    )
  }
}
