import React, { Component } from 'react'
import PeopleImage from '~/img/illustration/people.svg'
import ComputerMobile from '~/img/illustration/computer-mobile.svg'
import Companies from '~/js/components/Web/LandingComponents/Companies'
import { Link } from 'react-scroll'

export default class Gaming extends Component {
    render() {
        return (
            <main>
                <section className="intro--color intro--color--green" id="intro">
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col lg-8-12 md-10-12">
                                <div className="intro__content ta-center">
                                    <h3 className="section-title section-title--sm">Gaming</h3>
                                    <h1 className="ta-center">Reach more young customers around the world</h1>
                                    <p className="ta-center">
                                        More than a half of new gaming sign-ups are young players.
                                        So, if you trust reference agencies, you are missing out!
                                        Credit records of youth are often short or non-existant.
                                        Onboard 40% more customers with accurate document verification.
                                    </p>
                                </div>
                            </div>
                            <div className="col lg-10-12">
                                <div className="ta-center">
                                    <Link className="btn btn--primary btn--primary--white" to="about" smooth={true}>Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="text-block" id="about">
                    <div className="grid">
                        <div className="row row--hor-center">
                            <div className="col">
                                <h2 className="section-title section-title--sm ta-center">Prevent losses and Comply with global regulations</h2>
                            </div>
                            <div className="col lg-10-12">
                                <p className="ta-center">
                                    Translate your existing AML policy into a digital process.
                                    Get all the checks you need, from age verification to KYC and AML monitoring.
                                    Never breach a sanction, whether it is related to UN, OFAC, HMT, EU, and
                                    regional sanctions lists, age restrictions, or AML5D requirements.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="text-block text-block--lg" id="scroll-to">
                    <div className="grid">
                        <div className="row">
                            <div className="col">
                                <div className="text-block__list">
                                    <div className="text-block__list__item">
                                        <img src={ PeopleImage } alt="People" />
                                        <div className="text">
                                            <h2>Scale fast when it matters the most</h2>
                                            <p className="list-title">
                                                Use AI to scale instantly when the big event kicks off
                                            </p>
                                            <div className="list list--icon">
                                                <ul>
                                                    <li>
                                                        Automated checks 24/7
                                                    </li>
                                                    <li>
                                                        Coverage: 6500+ document types from 220+ countries
                                                    </li>
                                                    <li>
                                                        Average manual KYC expert re-evaluation time is 300s
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-block__list__item">
                                        <img src={ ComputerMobile } alt="Computer and Mobile" />
                                        <div className="text">
                                            <h2>Flexible Integration</h2>
                                            <p>
                                                Integrate via mobile SDK, our iFrame widget or redirect API, with over 99.9% uptime
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blue-box">
                    <div className="grid">
                        <div className="blue-box__bg">
                            <div className="blue-box__list">
                                <div className="blue-box__list__item ta-center"><span className="title">24/7</span>
                                    <p className="description">Automated checks</p>
                                </div>
                                <div className="blue-box__list__item ta-center"><span className="title">6500+</span>
                                    <p className="description">Document types from 220+ countries</p>
                                </div>
                                <div className="blue-box__list__item ta-center"><span className="title">300s</span>
                                    <p className="description">Average manual KYC expert re-evaluation time</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Companies />
            </main>
        )
    }
}
